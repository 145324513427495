<!-- Inner Banner -->
<div class="inner-banner inner-bg7" xmlns="http://www.w3.org/1999/html">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Missions & Compétences</h3>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li>Missions & Compétences</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-45">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Missions & Compétences</h3>
            <h6 class="mt-45">
                <i class="pi pi-chevron-right"></i>
                Les instances décisionnelles
            </h6>
            <div>
                Toute procédure d'appel public à l'épargne doit être autorisée par l’AMF-UMOA au moyen de
                l'octroi d'un visa. Les émissions obligataires initiées par les Etats de l’Union ne sont pas assujetties
                à l’exigence de visa préalable de l’AMF-UMOA. Cet organe peut, en outre, formuler un veto sur
                l'émission et le placement par appel public à l'épargne de nouveaux produits financiers susceptibles
                d'être négociés en bourse, ainsi que sur la création de marchés financiers nouveaux.
            </div>
        </div>

        <div class="single-content text-justify">
            <h6>
                <i class="pi pi-chevron-right"></i>
                Pouvoirs d'habilitation
            </h6>
            <div>L’AMF-UMOA est seul compétent pour :</div>
            <ul class="mt-20">
                <li>habiliter les structures de gestion du marché et agréer les intervenants commerciaux,</li>
                <li>homologuer les tarifs des intervenants commerciaux notamment les SGI et SGP,</li>
                <li>délivrer des cartes professionnelles aux personnes dont l’activité sur le marché le requiert.</li>
            </ul>
            &nbsp;
            <h6>
                <i class="pi pi-chevron-right"></i>
                Réglementation du fonctionnement du marché
            </h6>
            <div>
                L’AMF-UMOA réglemente le fonctionnement du marché, notamment par les dispositions suivantes :
            </div>
            <ul class="mt-20">
                <li>
                    l'édiction d'une réglementation spécifique au marché boursier régional, déterminant notamment les
                    conditions d'accès au marché, en particulier les règles de publicité et d'information du public,
                </li>
                <li>l'interprétation, par des instructions générales, de la portée de son Règlement Général,</li>
                <li>
                    l'instruction des plaintes de toute personne intéressée, relatives aux fautes, omissions ou
                    manœuvres préjudiciables aux droits des épargnants et au fonctionnement régulier du marché,
                </li>
                <li>
                    la définition des règles pour l'application de mesures individuelles, de mesures disciplinaires
                    devant sanctionner les comportements et actes qui entravent le bon fonctionnement du marché
                    financier et qui sont contraires aux intérêts des épargnants,
                </li>
                <li>
                    la conciliation et l'arbitrage des différends pouvant survenir à l'occasion des relations
                    professionnelles entre les intermédiaires financiers, ou entre ces derniers et les structures de
                    gestion du marché.
                </li>
            </ul>
            &nbsp;
            <h6>
                <i class="pi pi-chevron-right"></i>
                Pouvoirs de Contrôle
            </h6>
            <div>
                L’AMF-UMOA contrôle l'activité de tous les intervenants, notamment les structures de gestion
                du marché et les intervenants commerciaux agréés. Il veille au respect, par les émetteurs de titres, des
                obligations auxquelles ils sont soumis en matière d'appel public à l'épargne. Le cas échéant, il peut
                diligenter des enquêtes auprès des actionnaires, des sociétés-mères et des filiales, de toute personne
                morale ou physique ayant avec les intervenants un lien d'intérêt direct ou indirect.
            </div>
            &nbsp;
            <h6>
                <i class="pi pi-chevron-right"></i>
                Pouvoirs de sanction
            </h6>
            <div>
                Toute action, omission ou manœuvre qui s'avérerait contraire à l'intérêt général du marché financier et
                à son bon fonctionnement, et/ou préjudiciable aux droits des épargnants sera punie de sanctions
                pécuniaires, administratives et disciplinaires, selon les cas, sans préjudice des sanctions judiciaires
                qui pourront être prononcées à l'encontre de leurs auteurs sur la base d'une action en réparation
                intentée à titre individuel par les personnes lésées du fait de ces agissements.
            </div>
            &nbsp;
            <h6>
                <i class="pi pi-chevron-right"></i>
                Ressources
            </h6>
            <div>
                Les ressources de l’AMF-UMOA sont constituées par les commissions, redevances, frais ou toutes autres
                recettes qu'il perçoit au titre de ses activités, notamment lors de la délivrance des visas et des
                cartes professionnelles, de l'agrément des structures du marché ainsi que des commissions sur les
                transactions boursières. Le budget annuel de l’AMF-UMOA est soumis au Conseil des Ministres de l’UMOA
                pour approbation. La Cour des Comptes de l’UEMOA assure le contrôle de l'ensemble des comptes
                de l’AMF-UMOA.
                L’Organe peut également recevoir toute subvention, donation ou legs de toutes personnes morales ou
                physiques, des Etats, des Gouvernements et organismes étrangers, à condition que l'utilisation de ces
                ressources n'affecte pas son autonomie et son indépendance.
            </div>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
