import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-chart-stats',
    templateUrl: './chart-stats.component.html',
    styleUrls: ['./chart-stats.component.scss']
})
export class ChartStatsComponent implements OnInit {
    @Input() data: Array<any> = [];
    @Input() type;
    @Input() chartCols: Array<any>;

    chartData: any;
    chartOptions: any;

    constructor() {
        this.chartOptions = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };
    }

    ngOnInit(): void {
        const labels = this.data.map(item => item.annee);

        this.chartData = {
            labels: labels,
            datasets: this.buildDatasets(labels)
        };
    }

    buildDatasets(labels: Array<number>): Array<any> {
        const datasets = [];
        datasets.push(this.buildTotalData(labels));

        this.chartCols.forEach(col => {
            datasets.push({
                label: col.header,
                data: this.data.map(item => item[col.field]),
                borderColor: col.borderColor,
                fill: false,
                tension: .4
            });
        });

        return datasets;
    }

    buildTotalData(labels: Array<number>): any {
        const group = _.groupBy(this.data, 'annee');
        const total = [];

        labels.forEach(year => {
            const row = group[year][0];
            let val = 0;
            this.chartCols.forEach(col => {
                val += row[col.field];
            });
            total.push(val);
        });

        return {
            label: 'TOTAL',
            data: total,
            borderColor: '#3366CC',
            fill: false,
            tension: .4
        };
    }

}
