

<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-20">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Glossaire / Lexique</h3>
        </div>

        <div class="single-content">
            <table role="table" class="d-table table table-hover">
                <thead>
                <tr>
                    <th style="width:20%">Expressions</th>
                    <th style="width:80%">Définitions</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>ACTIF SOUS-JACENT</td>
                    <td> Le sous-jacent peut être une action, un indice, une devise, un panier d'actions… Un actif sous-jacent est un produit ou un titre financier sur lequel porte une option, un bon de souscription, un contrat à terme (future) et plus largement un produit dérivé quel qu'il soit</td>
                </tr>
                <tr>
                    <td>ACTION </td>
                    <td>Une action est une part de l'entreprise qui confère un droit de propriété à son détenteur. Chaque actionnaire est donc propriétaire, à hauteur du nombre d’actions qu’il possède, d'une partie de l’entreprise. L'action donne droit au versement d'un dividende annuel et confère un droit de vote à son détenteur. L'ensemble des actions compose le capital d'une société anonyme (SA).</td>
                </tr>
                <tr>
                    <td>ACTIONNAIRE </td>
                    <td> C'est la personne qui détient des actions. Elle bénéficie d’un droit de vote si elle les détient en direct ou par l’intermédiaire de la société de gestion lorsqu’il est investi en OPC.</td>
                </tr>
                <tr>
                    <td>AGENCE DE NOTATION </td>
                    <td> Ces agences donnent des notes aux entreprises et aux États qui correspondent au niveau de risque auquel peut être exposé l’investisseur. </td>
                </tr>
                <tr>
                    <td>BOURSE </td>
                    <td> Marché public organisé qui met en relation les émetteurs de titres (entreprises, administrations, Etat…), actions ou obligations, et les investisseurs (particuliers, institutionnels, entreprises…) qui peuvent acheter ou vendre ces titres.</td>
                </tr>
                <tr>
                    <td>CAPITALISATION FLOTTANTE </td>
                    <td> La capitalisation flottante ou “flottant” est la part du capital d’une société détenue par le public.</td>
                </tr>
                <tr>
                    <td>COMPTE TITRES </td>
                    <td> C'est un compte ouvert dans une société de gestion et d’intermédiation agréée par l'AMF-UMOA. Comme un compte chèque sur lequel sont inscrites les entrées et les sorties d'argent, le compte titres sert à répertorier les titres (actions, obligations, parts ou actions d’OPC, etc.) détenus en portefeuille. Pour en ouvrir un, il suffit d'en faire la demande à son banquier. </td>
                </tr>
                <tr>
                    <td>CONSERVATEUR </td>
                    <td> Le conservateur garde et administre des titres et d'autres instruments financiers pour le compte de tiers.</td>
                </tr>
                <tr>
                    <td>COUPON COURU </td>
                    <td> Cette expression est employée pour faire la distinction entre les actions ou les obligations dont le coupon a été détaché et celles auxquelles il est encore attaché. Les obligations "coupon couru" prennent en compte le futur coupon.</td>
                </tr>
                <tr>
                    <td>COUPON </td>
                    <td> On emploie ce terme pour désigner l'intérêt versé à une obligation. L'actionnaire touche un dividende, et l'investisseur en obligations touche un coupon. Le porteur de parts de distribution d’un OPC perçoit un dividende lorsqu’il s’agit d’une SICAV et un coupon lorsqu’il s’agit d’un FCP.</td>
                </tr>
                <tr>
                    <td>COURS D'OUVERTURE </td>
                    <td> Il correspond au premier prix inscrit par une valeur lors de l'ouverture de la séance. On distingue le cours d'ouverture du cours de clôture, prix définitif de l'action en fin de journée. Ce cours pourra servir à la valorisation des OPC lorsqu’il est prévu de procéder ainsi.</td>
                </tr>
                <tr>
                    <td>COURS DE BOURSE </td>
                    <td> C'est le prix auquel s’échange un instrument financier sur un marché (bourse). Il résulte de la confrontation des ordres d'achat et des ordres de vente.</td>
                </tr>
                <tr>
                    <td>COURS DE CLÔTURE </td>
                    <td> C'est le dernier prix de l'action à la fermeture du marché. Ce cours pourra servir à la valorisation des OPC lorsqu’il est prévu de procéder ainsi.</td>
                </tr>
                <tr>
                    <td>DATE DE JOUISSANCE </td>
                    <td> C'est la date à partir de laquelle une action donne droit au dividende</td>
                </tr>
                <tr>
                    <td>DIVIDENDE </td>
                    <td> Revenu d'une action représentant la part des bénéfices distribuée à ses actionnaires par une société. Le montant du dividende est proposé par le conseil d'administration aux actionnaires lors de l'assemblée générale.</td>
                </tr>
                <tr>
                    <td>DROIT DE VOTE </td>
                    <td> Tous les actionnaires (sauf exception) ont le droit de voter en assemblée générale. Ils peuvent ainsi donner leur avis sur la gestion de leur société. Certaines sociétés n'autorisent l'entrée à leur assemblée qu'aux actionnaires qui disposent d'un minimum d'actions.</td>
                </tr>
                <tr>
                    <td>DURATION </td>
                    <td> C'est une mesure de calcul d'actualisation des coupons. Elle permet de calculer les variations d'une obligation par rapport aux mouvements des taux d'intérêt.</td>
                </tr>
                <tr>
                    <td>FCP (Fonds commun de placement) </td>
                    <td> C’est une forme d’OPC. Il s’agit d’un portefeuille de valeurs mobilières en copropriété, géré par une société de gestion pour le compte des porteurs de parts.</td>
                </tr>
                <tr>
                    <td>FONDS COMMUNS DE PLACEMENT </td>
                    <td>Fonds constitués de sommes mises en commun par des épargnants en vue d’un placement collectif. La gestion de tels fonds est assurée par des tiers, qui doivent, sur demande, racheter les parts à leur valeur liquidative (ou valeur de rachat).</td>
                </tr>
                <tr>
                    <td>GESTION COLLECTIVE </td>
                    <td> Ensemble des gestionnaires d’OPC qui collectent des fonds auprès d'une multitude d'investisseurs particuliers ou entreprises, qui vont être gérés dans quelques fonds (SICAV, fonds communs de placement, fonds d’investissement alternatifs) avec une politique de gestion unifiée.</td>
                </tr>
                <tr>
                    <td>LIQUIDITÉ </td>
                    <td> C'est la possibilité d'acheter ou de vendre un titre facilement. Plus l’action fait l’objet d’échanges, plus elle est liquide. En revanche, un titre est dit peu liquide est coté de manière épisodique. Pour un investisseur, conserver des liquidités revient à garder de l'argent disponible de côté.</td>
                </tr>
                <tr>
                    <td>MARCHÉ AU COMPTANT </td>
                    <td> Les achats et vente de titre donnent lieu à un règlement et une livraison immédiate.</td>
                </tr>
                <tr>
                    <td>MARCHÉ MONÉTAIRE </td>
                    <td> Marché sur lequel les agents économiques négocient entre eux leurs besoins et ressources en capitaux à court et moyen terme.</td>
                </tr>
                <tr>
                    <td>MARCHÉ </td>
                    <td> C'est l'endroit « virtuel » où se rencontrent les investisseurs et les émetteurs.
                        Aujourd'hui, avec l'informatisation de la Bourse, la rencontre se fait au travers d'écrans informatiques.</td>
                </tr>
                <tr>
                    <td>Obligation à bon de souscription d'action (OBSA) </td>
                    <td> Les OBSA sont des obligations auxquelles sont attachés des bons de souscription.
                        Ces bons, cotés indépendamment, permettent d'acquérir une action à un prix fixé d'avance.</td>
                </tr>
                <tr>
                    <td>Obligation à taux fixe </td>
                    <td> C'est une obligation classique dont le taux coupon est fixé au moment de la souscription. En fonction de l'évolution du marché obligataire, le cours de ces obligations s'ajuste. Toutefois, le détenteur de ces obligations bénéficie d'un rendement et d'un prix de remboursement défini à l'avance.</td>
                </tr>
                <tr>
                    <td>Obligation à zéro coupon </td>
                    <td> Le détenteur de l'obligation ne touche aucun intérêt. Seul le capital est remboursé à échéance.</td>
                </tr>
                <tr>
                    <td>Obligation convertible </td>
                    <td> Obligation qui permet à son détenteur de devenir actionnaire de la société émettrice à tout moment, en contrepartie d'une rémunération inférieure à celle de l'obligation classique. Son prix dépendra de l’évolution des taux et de l’évolution du prix de l’action sousjacente, et ce dans des proportions variables selon le niveau de sensibilité à l’un ou l’autre de ces éléments.</td>
                </tr>
                <tr>
                    <td>OBLIGATION </td>
                    <td> Une obligation est un prêt consenti à moyen ou long terme à une entreprise ou une institution publique (collectivité ou Etat).
                        En échange de ce prêt, le porteur d'une obligation touche une somme versée régulièrement, le coupon, selon des conditions fixées dans le contrat d'émission.</td>
                </tr>
                <tr>
                    <td>OBLIGATIONS </td>
                    <td> Certificats de reconnaissance de dette en vertu desquels l’émetteur promet de payer au porteur un certain montant d’intérêt pendant une période déterminée et de rembourser le prêt à l’échéance. Des biens sont généralement donnés en garantie de l’emprunt, sauf en ce qui a trait aux obligations des gouvernements et des entreprises. Ce terme est souvent utilisé pour désigner tout titre d’emprunt.</td>
                </tr>
                <tr>
                    <td>ORDRE DE BOURSE </td>
                    <td> C'est un ordre donné par le client à un intermédiaire financier pour l'achat ou la vente de titres en Bourse.</td>
                </tr>
                <tr>
                    <td>RENDEMENT </td>
                    <td> C'est la comparaison entre le montant du dividende et le cours de bourse d'une action.
                        On parle de rendement net quand on compare le dividende au cours de l'action, sans tenir compte de l'avoir fiscal.
                        Le rendement global prend en compte l'avoir fiscal. Pour un dividende de 4 FCFA et une action de 100 FCFA,
                        le rendement net est de 4%. L'avoir fiscal étant de 2 FCFA, le dividende global est de 6 FCFA,
                        soit un rendement global de 6%.</td>
                </tr>
                <tr>
                    <td>RENTABILITÉ </td>
                    <td> La rentabilité mesure la capacité d´un capital investi dans différents actifs à procurer des revenus.</td>
                </tr>
                <tr>
                    <td>SICAV </td>
                    <td> Une Société d'Investissement à Capital Variable (SICAV) est une société qui a pour objectif de gérer un portefeuille de valeurs mobilières. Le détenteur d'une action achète ainsi un morceau d'un portefeuille. En étant détenteur d’actions de Sicav, vous êtes indirectement actionnaire des entreprises en portefeuilles si la Sicav est investie en actions. La société de gestion peut voter lors des Assemblées. D'autres Sicav sont investies sur les autres classes d’actifs (marchés monétaires, obligations…). Les actionnaires peuvent acheter ou vendre tous les jours de Bourse des actions de la SICAV, sur la base de la valeur réelle de l'actif qui détermine la valeur de l'action (valeur liquidative).</td>
                </tr>
                <tr>
                    <td>SOUSCRIPTION </td>
                    <td> C'est l'achat d'actions ou d'obligations lors d'une émission.</td>
                </tr>
                <tr>
                    <td>TAUX D'INTÉRÊT </td>
                    <td> Rémunération annuelle exprimée en pourcentage.</td>
                </tr>
                <tr>
                    <td>TITRE </td>
                    <td> On appelle titre la représentation des actions, parts, obligations et autres titres de créance.</td>
                </tr>
                <tr>
                    <td>TITRISATION </td>
                    <td> Mécanisme par lequel des actifs financiers, comme des prêts hypothécaires, sont transformés en titres adossés, puis cédés à une fiducie.</td>
                </tr>
                <tr>
                    <td>TRANSACTION </td>
                    <td> Terme utilisé pour parler d'achat ou de vente d'actions. Effectuer une transaction revient à réaliser une opération d'achat ou de vente.</td>
                </tr>
                <tr>
                    <td>VALEUR BOURSIÈRE </td>
                    <td> C'est la multiplication du cours de bourse par le nombre d'actions existantes. On l'appelle aussi capitalisation boursière.</td>
                </tr>
                <tr>
                    <td>VALEUR LIQUIDATIVE </td>
                    <td> Pour les Sicav et les FCP, cette valeur correspond à la valeur des actifs détenus en portefeuille et rapportés à chaque part. Elle est obtenue en divisant l'actif net d'un OPC par le nombre de parts (FCP) ou d'actions (SICAV) en circulation.</td>
                </tr>
                <tr>
                    <td>VALEUR MOBILIÈRE </td>
                    <td> Ce sont tous les titres émis par une entreprise et négociables sur un marché. Il s'agit des actions, des obligations, des titres de créances…</td>
                </tr>
                <tr>
                    <td>VALEUR NOMINALE </td>
                    <td> Prix d'une action lors de la constitution de la société. Si le capital est de 250 000 FCFA, réparti en 1 000 actions, la valeur nominale d'un titre est de 250 FCFA.</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
