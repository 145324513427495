import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterModule, Routes } from '@angular/router';
import { ActualityDetailsComponent } from './components/pages/actuality-details/actuality-details.component';
import { ActualitesComponent } from './components/pages/actualites/actualites.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { HistoriqueComponent } from './components/pages/amf-umoa/historique/historique.component';
import { OrganisationComponent } from './components/pages/amf-umoa/organisation/organisation.component';
import { MissionComponent } from './components/pages/amf-umoa/mission/mission.component';
import { RelationInstitutComponent } from './components/pages/amf-umoa/relation-institut/relation-institut.component';
import {
    RegleOrganisationComponent
} from './components/pages/reglementation/regle-organisation/regle-organisation.component';
import {
    RegleConventionComponent
} from './components/pages/reglementation/regle-convention/regle-convention.component';
import { RegleGeneraleComponent } from './components/pages/reglementation/regle-generale/regle-generale.component';
import { RegleAutreComponent } from './components/pages/reglementation/regle-autre/regle-autre.component';
import { InstructionComponent } from './components/pages/reglementation/regle-instruction/instruction.component';
import { RegleDecisionComponent } from './components/pages/reglementation/regle-decision/regle-decision.component';
import {
    RegleCirculaireComponent
} from './components/pages/reglementation/regle-circulaire/regle-circulaire.component';
import {
    PubRapportAnnuelComponent
} from './components/pages/publication/pub-rapport-annuel/pub-rapport-annuel.component';
import {
    StatsMarchePrimaireComponent
} from './components/pages/publication/stats-marche-primaire/stats-marche-primaire.component';
import {
    StatsNombreActeurComponent
} from './components/pages/publication/stats-nombre-acteur/stats-nombre-acteur.component';
import {
    StatsMarcheSecondaireComponent
} from './components/pages/publication/stats-marche-secondaire/stats-marche-secondaire.component';
import { ETypeOffre, ETypeProcedure, ETypeVerification } from './components/common/utils/utils';
import { AppelOffreComponent } from './components/pages/appel-offre/appel-offre.component';
import { ProceduresComponent } from './components/pages/procedures/procedures.component';
import { VerificationsComponent } from './components/pages/verifications/verifications.component';
import { EpargnantsComponent } from './components/pages/epargnants/epargnants.component';
import { EmetteursComponent } from './components/pages/emetteurs/emetteurs.component';
import { IntervenantsComponent } from './components/pages/intervenants/intervenants.component';
import { map, Observable } from 'rxjs';
import { Actualite } from './store/entity/entity.model';
import { JupiterPortailService } from './store/services/jupiter-portail.service';
import { MotPresidentComponent } from './components/pages/mot-president/mot-president.component';
import { ValeursComponent } from './components/pages/amf-umoa/valeurs/valeurs.component';
import {
    RegleTextAbrogeComponent
} from './components/pages/reglementation/regle-text-abroge/regle-text-abroge.component';
import { PubEtudeAnalyseComponent } from './components/pages/publication/pub-etude-analyse/pub-etude-analyse.component';
import { PubCommuniqueComponent } from './components/pages/publication/pub-communique/pub-communique.component';

@Injectable({providedIn: 'root'})
export class ActualiteResolve implements Resolve<Actualite> {
    constructor(private jupiterPortailService: JupiterPortailService) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<Actualite> {
        const actualiteId = route.queryParams.actualiteId;
        if (actualiteId) {
            return this.jupiterPortailService.fetchActualities({id: actualiteId}).pipe(
                map(response => {
                    return response.body[0];
                })
            );
        }
        return null;
    }
}

const routes: Routes = [
    {path: '', component: HomePageComponent},
    {path: 'home', component: HomePageComponent},
    {path: 'actualities', component: ActualitesComponent},
    {
        path: 'actuality-details', component: ActualityDetailsComponent,
        resolve: {actualite: ActualiteResolve}
    },
    {path: 'contact', component: ContactComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'mot-president', component: MotPresidentComponent},
    {path: 'historique', component: HistoriqueComponent},
    {path: 'organisation', component: OrganisationComponent},
    {path: 'mission', component: MissionComponent},
    {path: 'valeurs', component: ValeursComponent},
    {path: 'relation', component: RelationInstitutComponent},
    {path: 'reglementation/organisation', component: RegleOrganisationComponent},
    {path: 'reglementation/convention', component: RegleConventionComponent},
    {path: 'reglementation/general', component: RegleGeneraleComponent},
    {path: 'reglementation/autre-reglement', component: RegleAutreComponent},
    {path: 'reglementation/instruction', component: InstructionComponent},
    {path: 'reglementation/decision', component: RegleDecisionComponent},
    {path: 'reglementation/circulaire', component: RegleCirculaireComponent},
    {path: 'reglementation/texte-abroge', component: RegleTextAbrogeComponent},
    {path: 'publication/rapport', component: PubRapportAnnuelComponent},
    {path: 'publication/etude', component: PubEtudeAnalyseComponent},
    {path: 'publication/communique', component: PubCommuniqueComponent},
    {path: 'publication/stats/primaire', component: StatsMarchePrimaireComponent},
    {path: 'publication/stats/acteur', component: StatsNombreActeurComponent},
    {path: 'publication/stats/secondaire', component: StatsMarcheSecondaireComponent},
    {
        path: 'pratique/appel-offre/emplois',
        component: AppelOffreComponent,
        data: {
            title: 'Shortcuts.appelOffre.emplois.title',
            type: ETypeOffre.EMPLOIS
        }
    },
    {
        path: 'pratique/appel-offre/marche',
        component: AppelOffreComponent,
        data: {
            title: 'Shortcuts.appelOffre.marche.title',
            type: ETypeOffre.MARCHE
        }
    },
    {
        path: 'pratique/procedure/plainte',
        component: ProceduresComponent,
        data: {
            title: 'Shortcuts.procedure.plainte.title',
            headerTitle: 'Shortcuts.procedure.plainte.headerTitle',
            type: ETypeProcedure.PLAINTE
        }
    },
    {
        path: 'pratique/procedure/requete',
        component: ProceduresComponent,
        data: {
            title: 'Shortcuts.procedure.requete.title',
            headerTitle: 'Shortcuts.procedure.requete.headerTitle',
            type: ETypeProcedure.REQUETE
        }
    },
    {
        path: 'pratique/verification/agrement',
        component: VerificationsComponent,
        data: {
            title: 'Shortcuts.verification.agrement.title',
            type: ETypeVerification.AGREMENT,
            subTitle: 'Shortcuts.verification.agrement.subTitle',
            searchTitle: 'Shortcuts.verification.agrement.searchTitle',
            noDataTitle: 'Shortcuts.verification.agrement.noDataTitle'
        }
    },
    {
        path: 'pratique/verification/cartepro',
        component: VerificationsComponent,
        data: {
            title: 'Shortcuts.verification.cartepro.title',
            type: ETypeVerification.CARTEPRO,
            subTitle: 'Shortcuts.verification.cartepro.subTitle',
            searchTitle: 'Shortcuts.verification.cartepro.searchTitle',
            noDataTitle: 'Shortcuts.verification.cartepro.noDataTitle'
        }
    },
    {
        path: 'accueil/epargnant',
        component: EpargnantsComponent,
        data: {}
    },
    {
        path: 'accueil/emetteur',
        component: EmetteursComponent,
        data: {}
    },
    {
        path: 'accueil/intervenant',
        component: IntervenantsComponent,
        data: {}
    },
    {path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
