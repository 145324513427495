import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-navbar-item',
    templateUrl: './navbar-item.component.html',
    styleUrls: ['./navbar-item.component.scss']
})
export class NavbarItemComponent {
    @Output() classApplied = new EventEmitter<boolean>();

    toggleClass() {
        this.classApplied.emit(true);
    }
}
