<!-- Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Textes de base</h3>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li><a>Textes de base</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-45 mb-20">
    <div class="container">
        <p-accordion>
            <p-accordionTab header="Traité" [selected]="true">
                <div class="single-content text-justify">
                    <h3>Traité</h3>
                    <p>
                        Dans la hiérarchie des textes sur le marché financier régional, le Traité est le texte suprême.
                        <br>
                        Pour mémoire, le Traité instituant l’UMOA du 14 novembre 1973 avait déjà prévu  la création d’un Marché Financier
                        Régional couvrant les pays membres de l’Union.
                    </p>
                </div>

                <div class="single-content text-justify"></div>
            </p-accordionTab>
            <p-accordionTab header="Convention & Annexe">
                <div class="single-content text-justify">
                    <h3>Convention & Annexe</h3>
                    <p class="mb-2">
                        L’Annexe à la Convention constitue le premier dispositif normatif de base du Marché Financier Régional.
                    </p>
                    <p>
                        Elle est composée de deux titres dont:
                    </p>
                    <ul class="mt-20">
                        <li>
                            le premier qui est composé de trois (03) sections, porte sur la composition,
                            l’organisation et le fonctionnement de l’AMF-UMOA;
                        </li>
                        <li>
                            le deuxième qui est composé de six (06) sections a trait à l’organisation et au contrôle de
                            l’appel
                            public à l’Epargne, à l’habilitation des structures de gestion du marché, aux différents
                            intervenants commerciaux, à la règlementation du marché par la prise de textes
                            d’application, aux
                            pouvoirs de contrôle, à la coopération internationale et aux pouvoirs de sanctions de l’AMF-UMOA.
                        </li>
                    </ul>
                </div>

                <div class="single-content text-justify">

                    <table role="table" class="d-table table table-hover">
                        <tbody>
                        <tr role="row">
                            <td role="cell" [style.width]="'5%'">
                                <a href="../../../../../assets/docs/convention/CONVENTION.pdf" target="_blank">
                                    <img src="../../../../../assets/img/download-pdf.png" alt="">
                                </a>
                            </td>
                            <td role="cell" [style.width]="'95%'">
                                Convention portant création de l’Autorité des Marchés Financiers de l’Union Monétaire
                                Ouest Africaine (AMF-UMOA)de l’Autorité des Marchés Financiers de l’Union
                                Monétaire Ouest Africaine (AMF-UMOA)
                            </td>
                        </tr>
                        <tr role="row">
                            <td role="cell" [style.width]="'5%'">
                                <a href="../../../../../assets/docs/convention/ANNEXE.pdf" target="_blank">
                                    <img src="../../../../../assets/img/download-pdf.png" alt="">
                                </a>
                            </td>
                            <td role="cell" [style.width]="'95%'">
                                Annexe portant Composition, Organisation, Fonctionnement et attributions de l’Autorité
                                des Marchés Financiers de l’Union Monétaire Ouest Africaine (AMF-UMOA)
                            </td>
                        </tr>
                        <tr role="row">
                            <td role="cell" [style.width]="'5%'">
                                <a href="../../../../../assets/docs/convention/AVENANT.pdf" target="_blank">
                                    <img src="../../../../../assets/img/download-pdf.png" alt="">
                                </a>
                            </td>
                            <td role="cell" [style.width]="'95%'">
                                Avenant à la Convention portant création de l’Autorité des Marchés Financiers de
                                l’Union Monétaire Ouest Africaine (AMF-UMOA)
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Règlement Général">
                <app-regle-general></app-regle-general>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
<!-- Terms & Conditions Area End -->
