<li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"
                        (click)="toggleClass()">{{'MenuBar.accueil' | translate}}</a>
</li>

<li class="nav-item">
    <a href="javascript:void(0)" class="nav-link dropdown-toggle">AMF-UMOA<i
            class="bx bx-chevron-down"></i></a>
    <ul class="dropdown-menu">
        <li class="nav-item"><a routerLink="/historique" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}"
                                (click)="toggleClass()">{{'MenuBar.AmfUmuo.historique' | translate}}</a>
        </li>
        <li class="nav-item"><a routerLink="/organisation" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}"
                                (click)="toggleClass()">{{'MenuBar.AmfUmuo.organisation' | translate}}</a>
        </li>
        <li class="nav-item"><a routerLink="/mission" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}"
                                (click)="toggleClass()">{{'MenuBar.AmfUmuo.missionCompet' | translate}}</a>
        </li>
        <li class="nav-item"><a routerLink="/valeurs" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}"
                                (click)="toggleClass()">{{'MenuBar.AmfUmuo.nosValeurs' | translate}}</a>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-item"><a routerLink="/relation" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}"
                                (click)="toggleClass()">{{'MenuBar.AmfUmuo.relationInstitut' | translate}}</a>
        </li>
        <div class="dropdown-divider"></div>
    </ul>
</li>

<li class="nav-item">
    <a href="javascript:void(0)" class="nav-link dropdown-toggle">
        {{'MenuBar.reglementation.title' | translate}} <i class="bx bx-chevron-down"></i>
    </a>
    <ul class="dropdown-menu">
        <li class="nav-item">
            <a routerLink="/reglementation/organisation" class="nav-link"
               routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
               (click)="toggleClass()">
                {{'MenuBar.reglementation.cadreLegal' | translate}}
            </a>
        </li>
        <li class="nav-item">
            <a routerLink="/reglementation/convention" class="nav-link"
               routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
               (click)="toggleClass()">
                {{'MenuBar.reglementation.textBase' | translate}}
            </a>
        </li>
        <li class="nav-item">
            <a routerLink="/reglementation/autre-reglement" class="nav-link" routerLinkActive="active"
               [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                {{'MenuBar.reglementation.autreActe' | translate}}
            </a>
        </li>
        <div class="dropdown-divider"></div>

        <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link dropdown-toggle">
                {{'MenuBar.reglementation.textApplication.title' | translate}}
                <i class="bx bx-chevron-down"></i>
                <div class="nav-link-border"></div>
            </a>

            <ul class="dropdown-menu">
                <li class="nav-item">
                    <a routerLink="/reglementation/decision" class="nav-link" routerLinkActive="active"
                       [routerLinkActiveOptions]="{exact: true}">
                        {{'MenuBar.reglementation.textApplication.decision' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a routerLink="/reglementation/instruction" class="nav-link" routerLinkActive="active"
                       [routerLinkActiveOptions]="{exact: true}">
                        {{'MenuBar.reglementation.textApplication.instructions' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a routerLink="/reglementation/circulaire" class="nav-link" routerLinkActive="active"
                       [routerLinkActiveOptions]="{exact: true}">
                        {{'MenuBar.reglementation.textApplication.circulaire' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a routerLink="/reglementation/texte-abroge" class="nav-link" routerLinkActive="active"
                       [routerLinkActiveOptions]="{exact: true}">
                        {{'MenuBar.reglementation.textApplication.textAbroges' | translate}}
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</li>

<li class="nav-item">
    <a href="javascript:void(0)" class="nav-link dropdown-toggle">
        {{'MenuBar.Publication.title' | translate}} <i class="bx bx-chevron-down"></i>
    </a>
    <ul class="dropdown-menu">
        <li class="nav-item">
            <a routerLink="/publication/rapport" class="nav-link" routerLinkActive="active"
               [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                {{'MenuBar.Publication.rapportAnnuel' | translate}}
            </a>
        </li>
        <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link dropdown-toggle">
                {{'MenuBar.Publication.StatsMarche.title' | translate}} <i class="bx bx-chevron-down"></i>
            </a>
            <ul class="dropdown-menu">
                <li class="nav-item">
                    <a routerLink="/publication/stats/primaires" class="nav-link" routerLinkActive="active"
                       [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                        {{'MenuBar.Publication.StatsMarche.marchePrimaire.title' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a routerLink="/publication/stats/acteurs" class="nav-link" routerLinkActive="active"
                       [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                        {{'MenuBar.Publication.StatsMarche.nombreActeur.title' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a routerLink="/publication/stats/secondaires" class="nav-link" routerLinkActive="active"
                       [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                        {{'MenuBar.Publication.StatsMarche.marcheSecondaire.title' | translate}}
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</li>
<li class="nav-item">
    <a routerLink="/actualities" class="nav-link" routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
        {{'MenuBar.Actuality.title' | translate}}
    </a>
</li>
<li class="nav-item">
    <a routerLink="/contact" class="nav-link" routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
        {{'MenuBar.Contacts.title' | translate}}
    </a>
</li>
