import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { createRequestOption, ETableName } from '../../components/common/utils/utils';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
    Acteur,
    ActeurFormulaire,
    Actualite,
    ActualiteByCategorie,
    AppelOffre,
    DepotDemande,
    DetenteurCarte,
    Emetteur,
    EmpruntObligataire,
    EpargneCollective,
    Fcp,
    FormeJuridique,
    IndiceBoursier,
    NatureOperation,
    NombreActeur,
    Operation,
    OperationBoursiere,
    OperationTitre,
    Pays,
    SecteurActivite,
    SocieteCote,
    TypeActeur,
    TypeFcp,
    TypeInstruction
} from '../entity/entity.model';
import {
    ActeurCriteria,
    ActualiteCriteria,
    AppelOffreCriteria,
    CacCreteria,
    DetCarteCriteria,
    EmetteurCriteria,
    FcpCreteria,
    OperationCriteria
} from './request-params';

@Injectable({
    providedIn: 'root'
})
export class JupiterPortailService {
    SERVICE_PREFIX = `/service/api/elastic`;
    private sharedEvent = new Subject<any>();

    constructor(private httpClient: HttpClient,
                private translate: TranslateService) {
    }

    setNewValue<T>(newValue: T) {
        this.sharedEvent.next(newValue);
    }

    getNewValue() {
        return this.sharedEvent.asObservable();
    }

    fetchActualities(criteria: ActualiteCriteria): Observable<HttpResponse<Array<Actualite>>> {
        const params = createRequestOption({...criteria, langue: this.getLangKey()});
        return this.httpClient.get<Array<Actualite>>(`${this.SERVICE_PREFIX}/actualite`,
            {params, observe: 'response'});
    }

    fetchAppelOffres(criteria: AppelOffreCriteria): Observable<HttpResponse<Array<AppelOffre>>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<AppelOffre>>(`${this.SERVICE_PREFIX}/appel-offre`,
            {params, observe: 'response'});
    }

    createProcedure(depotDemande: DepotDemande): Observable<DepotDemande> {
        return this.httpClient.post<DepotDemande>(`${this.SERVICE_PREFIX}/depot`, depotDemande);
    }

    fetchActeurs(criteria: ActeurCriteria): Observable<HttpResponse<Array<Acteur>>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<Acteur>>(`${this.SERVICE_PREFIX}/acteur`,
            {params, observe: 'response'});
    }

    fetchDetenteurs(criteria: DetCarteCriteria): Observable<Array<DetenteurCarte>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<DetenteurCarte>>(`${this.SERVICE_PREFIX}/detenteur-carte`, {params});
    }

    fetchFormeJuridiques(): Observable<Array<FormeJuridique>> {
        return this.httpClient.get<Array<FormeJuridique>>(`${this.SERVICE_PREFIX}/forme-juridique`);
    }

    fetchSecteurActivites(): Observable<Array<SecteurActivite>> {
        return this.httpClient.get<Array<SecteurActivite>>(`${this.SERVICE_PREFIX}/secteur-activite`);
    }

    fetchEmetteurs(criteria: EmetteurCriteria): Observable<HttpResponse<Array<Emetteur>>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<Emetteur>>(`${this.SERVICE_PREFIX}/emetteur`,
            {params, observe: 'response'});
    }

    fetchNatureOperations(): Observable<Array<NatureOperation>> {
        return this.httpClient.get<Array<NatureOperation>>(`${this.SERVICE_PREFIX}/nature-operation`);
    }

    fetchOperation(criteria: OperationCriteria): Observable<HttpResponse<Array<Operation>>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<Operation>>(`${this.SERVICE_PREFIX}/operation`,
            {params, observe: 'response'});
    }

    fetchTypeActeur(): Observable<Array<TypeActeur>> {
        return this.httpClient.get<Array<TypeActeur>>(`${this.SERVICE_PREFIX}/type-acteur`);
    }

    fetchPays(): Observable<Array<Pays>> {
        return this.httpClient.get<Array<Pays>>(`${this.SERVICE_PREFIX}/pays`);
    }

    fetchTypeFcp(): Observable<Array<TypeFcp>> {
        return this.httpClient.get<Array<TypeFcp>>(`${this.SERVICE_PREFIX}/type-fcp`);
    }

    fetchFcp(criteria: FcpCreteria): Observable<HttpResponse<Array<Fcp>>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<Fcp>>(`${this.SERVICE_PREFIX}/fcp`,
            {params, observe: 'response'});
    }

    fetchCac(criteria: CacCreteria): Observable<HttpResponse<Array<Fcp>>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<Fcp>>(`${this.SERVICE_PREFIX}/cac`,
            {params, observe: 'response'});
    }

    fetchTypeInstruction(): Observable<Array<TypeInstruction>> {
        return this.httpClient.get<Array<TypeInstruction>>(`${this.SERVICE_PREFIX}/type-instruction`);
    }

    fetchGroupedBycategorie(): Observable<Array<ActualiteByCategorie>> {
        return this.httpClient.get<Array<ActualiteByCategorie>>(`${this.SERVICE_PREFIX}/acteur/group-by-categorie`);
    }

    sendFluxRss(email: any): Observable<any> {
        return this.httpClient.post<any>(`${this.SERVICE_PREFIX}/flux-rss`, email);
    }

    remoteDownloadPdf(fileName: string, table: ETableName, id: number, field: string): Observable<Blob> {
        const headerOptions = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        });

        let url = `${this.SERVICE_PREFIX}/download/${table}/${id}/${field}`;
        if (fileName) {
            url = `${url}/?fileName=${fileName}`;
        }
        return this.httpClient.get(url, {headers: headerOptions, responseType: 'blob' as 'blob'});
    }

    buildUrl(fileName: string, table: ETableName, id: number, field: string): string {
        let url = `${this.SERVICE_PREFIX}/download/${table}/${id}/${field}`;
        if (fileName) {
            url = `${url}/?fileName=${fileName}`;
        }
        return url;
    }

    fetchEmpruntObligatoire(criteria): Observable<HttpResponse<Array<EmpruntObligataire>>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<EmpruntObligataire>>(`${this.SERVICE_PREFIX}/emprunt-obligataire`,
            {params, observe: 'response'});
    }

    fetchEpargneCollective(criteria): Observable<HttpResponse<Array<EpargneCollective>>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<EpargneCollective>>(`${this.SERVICE_PREFIX}/epargne-collective`,
            {params, observe: 'response'});
    }

    fetchIndiceBoursier(criteria): Observable<HttpResponse<Array<IndiceBoursier>>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<IndiceBoursier>>(`${this.SERVICE_PREFIX}/indice-boursier`,
            {params, observe: 'response'});
    }

    fetchNombreOctobre(criteria): Observable<HttpResponse<Array<NombreActeur>>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<NombreActeur>>(`${this.SERVICE_PREFIX}/nombre-acteur`,
            {params, observe: 'response'});
    }

    fetchOperationTitre(criteria): Observable<HttpResponse<Array<OperationTitre>>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<OperationTitre>>(`${this.SERVICE_PREFIX}/operation-titre`,
            {params, observe: 'response'});
    }

    fetchSocieteCote(criteria): Observable<HttpResponse<Array<SocieteCote>>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<SocieteCote>>(`${this.SERVICE_PREFIX}/societe-cote`,
            {params, observe: 'response'});
    }

    fetchOperationBoursiere(criteria): Observable<HttpResponse<Array<OperationBoursiere>>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<OperationBoursiere>>(`${this.SERVICE_PREFIX}/operation-boursiere`,
            {params, observe: 'response'});
    }

    fetchActeurFormulaire(criteria): Observable<HttpResponse<Array<ActeurFormulaire>>> {
        const params = createRequestOption(criteria);
        return this.httpClient.get<Array<ActeurFormulaire>>(`${this.SERVICE_PREFIX}/formulaire`,
            {params, observe: 'response'});
    }

    createActeurFormulaire(acteurFormulaire: ActeurFormulaire): Observable<ActeurFormulaire> {
        return this.httpClient.post<ActeurFormulaire>(`${this.SERVICE_PREFIX}/acteur/doc`, acteurFormulaire);
    }

    private getLangKey(): string {
        return this.translate.currentLang;
    }
}
