<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-20">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Opérations soumises au visa du CREPMF</h3>
        </div>

        <div class="single-content">
            <h4>Article 113 et suivants du Règlement Général</h4>
            <p class="text-justify">
                Le contrôle à priori de l’appel public à l’épargne s’exerce au niveau du Conseil Régional. Il consiste à
                soumettre à cette institution pour visa, tous documents d’information élaborés par les émetteurs
                désirant faire appel public à l’épargne. Des instructions du Conseil Régional arrêtent et précisent les
                mentions obligatoires que doivent comporter ces documents d’information destinés au public.
                <br><br>
                Toute modification affectant les documents d’information est assujettie au visa préalable du Conseil
                Régional.
            </p>
        </div>
        <p-panel>
            <ng-template pTemplate="header">
                <span>Zone de filtrage</span>
            </ng-template>
            <form #searchForm="ngForm">
                <div class="row">
                    <div class="form-group col-sm-6">
                        <label for="field_nature">Natures d'Opérations</label>
                        <p-dropdown id="field_nature" [options]="natureOperationList"
                                    [filter]="true" [showClear]="true"
                                    [autoDisplayFirst]="false"
                                    [(ngModel)]="criteria.natureId" name="nature"
                                    [style]="{width: '100%'}" appendTo="body">
                        </p-dropdown>

                    </div>
                    <div class="form-group col-sm-6">
                        <label for="field_annee">Année</label>
                        <p-dropdown id="field_annee" [options]="anneeList"
                                    [filter]="true" [showClear]="true"
                                    [autoDisplayFirst]="false"
                                    [(ngModel)]="criteria.annee" name="annee"
                                    [style]="{width: '100%'}" appendTo="body">
                        </p-dropdown>

                    </div>
                </div>
                <div class="row mt-20">
                    <div class="form-group">
                        <label for="field_denomination">Dénomination</label>
                        <input id="field_denomination" [(ngModel)]="criteria.denomination"
                               name="denomination" [style]="{width: '100%'}" pInputText/>
                    </div>
                </div>
                <div class="mt-20 pb-5">
                    <div style="float:right;">
                        <button type="button" label="Vérifier" pRipple pButton
                                icon="pi pi-search" class="btn-verif" (click)="searchData()">
                        </button>
                    </div>
                </div>
            </form>
        </p-panel>
        &nbsp;
        <p-table #dt [value]="groupedData" [columns]="cols" responsiveLayout="scroll"
                 [paginator]="false" [rowHover]="true" dataKey="id">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <ng-container *ngFor="let col of columns">
                        <th [style.width]="col.width" [pSortableColumn]="col.field"
                            *ngIf="col.sort">
                            <span>{{col.header}}</span>
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th [style.width]="col.width" *ngIf="!col.sort">
                            <span>{{col.header}}</span>
                        </th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-entity let-columns="columns">
                <tr *ngIf="entity.isGroup">
                    <td colspan="12">
                        <div>
                            <h3>{{entity.codeVisa}} - {{entity.title}}</h3>
                            <span [innerHTML]="entity.desc"></span>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="!entity.isGroup">
                    <ng-container *ngFor="let col of columns">
                        <td [style.width]="col.width">
                            <span class="p-column-title">{{col.header}}</span>
                            <app-table-td [col]="col" [entity]="entity" [indexName]="indexName"></app-table-td>
                        </td>
                    </ng-container>
                </tr>
            </ng-template>
        </p-table>
        <app-pagination [rows]="size" [totalRecords]="totalRecords" (paginate)="pageChange($event)"></app-pagination>
    </div>
</div>
<!-- Terms & Conditions Area End -->
