import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppelOffre } from '../../../../store/entity/entity.model';
import {ETableName, Pagination} from '../../utils/utils';

@Component({
    selector: 'app-offre-template',
    templateUrl: './offre-table.component.html',
    styleUrls: ['./offre-table.component.scss']
})
export class OffreTableComponent {
    @Input() offreList: Array<AppelOffre>;
    @Input() totalRecords: number;
    @Input() rows: number;
    @Output() paginate = new EventEmitter<Pagination>();
    indexName = ETableName.offre

    cols: Array<any> = [];

    constructor() {
        this.cols = [
            {field: 'reference', header: 'Reference', sort: true, type: 'string', width: '20%'},
            {field: 'texte', header: 'Description', sort: true, type: 'string', width: '55%'},
            {field: 'dateLimite', header: 'Date limite', sort: true, type: 'date', width: '20%'},
            {field: 'reference', header: '', type: 'pdf', width: '5%', fileField: 'doc', fileName: 'reference'}
        ];
    }

    pageChanged(pagination: Pagination) {
        this.paginate.emit(pagination);
    }

}
