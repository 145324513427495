import { Component, OnDestroy, OnInit } from '@angular/core';
import { ETypeOffre, Pagination } from '../../common/utils/utils';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AppelOffre } from '../../../store/entity/entity.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { FetchAppelOffreList } from '../../../store/actions/jupiter-portail.actions';
import { selectAppelOffreList } from '../../../store/selectors/jupiter-portail.selectors';

@Component({
    selector: 'app-offre-emploie',
    templateUrl: './appel-offre.component.html',
    styleUrls: ['./appel-offre.component.scss']
})
export class AppelOffreComponent implements OnInit, OnDestroy {
    title = 'Shortcuts.appelOffre.emplois.title';
    typeOffre = ETypeOffre.EMPLOIS;
    appelOffresList: Array<AppelOffre>;
    destroy$ = new Subject<boolean>();
    totalRecords = 0;
    page = 0;
    size = 10;

    constructor(private activatedRoute: ActivatedRoute,
                private store: Store<AppState>) {
        this.title = this.activatedRoute.snapshot.data.title;
        this.typeOffre = this.activatedRoute.snapshot.data.type;

        this.loadData();
    }

    ngOnInit(): void {
        this.store.pipe(select(selectAppelOffreList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.appelOffresList = [...resultSet.data];
                        this.totalRecords = resultSet.totalRecord;
                    }
                }
            });
    }

    loadData() {
        this.store.dispatch(FetchAppelOffreList({
            typeOffre: this.typeOffre,
            page: this.page,
            size: this.size
        }));
    }

    pageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;

            this.loadData();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.subscribe();
    }

}
