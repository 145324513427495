import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/**
 * Service pour gerer le telechargement ou l'impression de fichiers.
 */
@Injectable({providedIn: 'root'})
export class FileHandlingService {
    constructor(protected http: HttpClient) {
    }

    /**
     * Method to download file
     * @param {?} contentType
     * @param {?} data
     * @param {?} fileName
     * @return {?}
     */
    downloadFile(contentType: any, data: any, fileName: string) {
        const blob = new Blob([data], {type: contentType});
        const tempLink = document.createElement('a');
        tempLink.href = window.URL.createObjectURL(blob);
        tempLink.download = fileName;
        tempLink.target = '_blank';
        tempLink.click();
    }

    downloadBinaryFile(contentType: any, data: any, fileName: string) {
        /** @type {?} */
        const byteCharacters = atob(data);
        /** @type {?} */
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        /** @type {?} */
        const byteArray = new Uint8Array(byteNumbers);
        /** @type {?} */
        const blob = new Blob([byteArray], {
            type: contentType
        });
        /** @type {?} */
        const tempLink = document.createElement('a');
        tempLink.href = window.URL.createObjectURL(blob);
        tempLink.download = fileName;
        tempLink.target = '_blank';
        tempLink.click();
        tempLink.remove();
    }
}
