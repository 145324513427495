<ng-container>
    <ng-container *ngIf="col?.type !== 'boolean' && col?.type !== 'pdf' && col?.type !== 'image'
     && col?.type !== 'tag' && col.type !== 'number'">
        <span *ngIf="col?.label">{{entity[col!.label!]}}</span>
        <span *ngIf="!col?.label">
            <span *ngIf="col?.type !=='date'">{{entity[col!.field]}}</span>
            <span *ngIf="col?.type ==='date'">{{entity[col!.field] | date}}</span>
        </span>
    </ng-container>

    <ng-container *ngIf="col?.type === 'number'">
        <span>{{entity[col!.field!] | mask: "separator"}}</span>
    </ng-container>

    <ng-container *ngIf="col?.type === 'boolean'">
        <p-checkbox [name]="col!.field" [binary]="true" [inputId]="col!.field"
                    [ngModel]="entity[col!.field]" [disabled]="true"></p-checkbox>
    </ng-container>

    <ng-container *ngIf="col?.type === 'pdf' && entity[col?.field!]">
        <span class="img-card download-cursor p-button-link" (click)="downloadPdfFile()">
            <ng-container *ngIf="col.showText">
                <span *ngIf="!loader.has(entity['id'])">
                    <img src="../../../../../assets/img/download-pdf.png" alt="" width="16" height="16">
                    <span class="ms-1">{{entity[col?.field!]}}</span>
                </span>
                <span *ngIf="loader.has(entity['id'])">
                    <p-progressSpinner [style]="{width: '16px', height: '16px'}" styleClass="custom-spinner"
                                       strokeWidth="4" fill="var(--surface-ground)"
                                       animationDuration=".5s"></p-progressSpinner>
                </span>
            </ng-container>
            <ng-container *ngIf="!col.showText">
                <span *ngIf="!loader.has(entity['id'])">
                    <img src="../../../../../assets/img/download-pdf.png" alt="" width="32" height="32">
                </span>
                <span *ngIf="loader.has(entity['id'])">
                    <p-progressSpinner [style]="{width: '32px', height: '32px'}" styleClass="custom-spinner"
                                       strokeWidth="4" fill="var(--surface-ground)"
                                       animationDuration=".5s"></p-progressSpinner>
                </span>
            </ng-container>

        </span>
    </ng-container>
</ng-container>
