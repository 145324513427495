import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { DepotDemande } from '../../../store/entity/entity.model';
import { CreateProcedure } from '../../../store/actions/jupiter-portail.actions';
import { emailPattern, ETypeProcedure, StatusEnum } from '../../common/utils/utils';
import { Subject, takeUntil } from 'rxjs';
import { JupiterPortailService } from '../../../store/services/jupiter-portail.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
    contact: DepotDemande = {typeDepot: ETypeProcedure.MESSAGE};
    emailPattern = emailPattern;
    destroy$ = new Subject<boolean>();

    constructor(private store: Store<AppState>,
                private jupiterPortailService: JupiterPortailService) {
    }

    ngOnInit(): void {
        this.jupiterPortailService.getNewValue().pipe(takeUntil(this.destroy$))
            .subscribe(value => {
                if (value === StatusEnum.success) {
                    this.contact = {};
                }
            });
    }

    saveEntity() {
        this.store.dispatch(CreateProcedure(this.contact));
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.subscribe();
    }

}
