<!-- Inner Banner -->
<div class="inner-banner inner-bg7" xmlns="http://www.w3.org/1999/html">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Relations Institutionnelles</h3>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li>Relations Institutionnelles</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-45">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Relations Institutionnelles</h3>
            <h6 class="mt-45">
                <i class="pi pi-chevron-right"></i>
                Coopération Internationale
            </h6>
            <div>
                Au plan multilatéral, l’AMF-UMOA participe activement aux différents travaux menés à l’échelle
                internationale, en matière de régulation des marchés financiers notamment au sein de l’Organisation
                Internationale des Commissions de Valeurs (OICV).
                Ainsi, l’AMF-UMOA est membre de l’Institut Francophone de la Régulation Financière (IFREFI) et
                de l’Africa and Middle East Committee (AMERC).
                Enfin, l’AMF-UMOA a signé des accords de coopération avec l’AMF-France, la COSUMAF et l’AMMC. Une
                convention de partenariat a également été conclue avec l’ICD pour le développement des marchés de
                capitaux islamiques dans l’UEMOA.
            </div>
        </div>

        <div class="single-content">
            <h6>
                <i class="pi pi-chevron-right"></i>
                Coopération Régionale
            </h6>
            <div class="text-justify">
                Au titre de la coopération régionale, l’AMF-UMOA participe à différents travaux visant l’intégration des
                marchés financiers de la CEDEAO ainsi que la stabilité et le développement du marché financier régional
                de l’UMOA.
            </div>
            &nbsp;
            <div>L’AMF-UMOA est seul compétent pour :</div>
            <table role="table" class="d-table table table-hover">
                <thead>
                <tr role="row">
                    <th role="cell"></th>
                    <th role="cell"></th>
                </tr>
                </thead>
                <tbody>
                <tr role="row">
                    <td role="cell">
                        Intégration des marchés financiers dans la Communauté Economique des Etats de l’Afrique de
                        l’Ouest (CEDEAO)
                    </td>
                    <td role="cell" class="text-justify">
                        <ng-container>
                            Les régulateurs de marchés financiers de la CEDEAO, notamment la Securities and Exchange
                            Commission (SEC) du Ghana, la Securities and Exchange Commission (SEC) du Nigéria et
                            l’Autorité des Marchés Financiers de l’Union Monétaire Ouest Africaine (AMF-UMOA), se sont réunis à
                            Abuja, le 08 juillet 2015, pour la signature d’un Protocole d’accord relatif à la création
                            de l’Association des Autorités de Régulation des Marchés Financiers de l’Afrique de l’Ouest
                            (ARMFAO). Les objectifs de l’Association consistent à :
                            <ul class="mt-20">
                                <li>
                                    favoriser l'intégration des marchés des capitaux en Afrique de l’ouest en
                                    établissant une base de coopération technique sur des programmes spécifiques et des
                                    projets communs ;
                                </li>
                                <li>
                                    favoriser l'aide mutuelle entre ses membres par l'exécution de requêtes et le
                                    partage d'informations ;
                                </li>
                                <li>
                                    faciliter et favoriser l’intégration et le développement des marchés financiers
                                    d’Afrique de l’ouest, notamment ceux de la CEDEAO.
                                </li>
                                <li>
                                    Lors de cette rencontre, les différents régulateurs présents, ont désigné Monsieur
                                    Mory SOUMAHORO, Secrétaire Général de l’AMF-UMOA, comme Président de ladite Association
                                    pour un mandat de deux (2) ans.
                                </li>
                            </ul>
                        </ng-container>
                    </td>
                </tr>
                <tr role="row">
                    <td role="cell">
                        Comité de Stabilité Financière de l’UMOA (CSF-UMOA)
                    </td>
                    <td role="cell" class="text-justify">
                        <ng-container>
                            Le Comité de Stabilité Financière dans l'UMOA est composé comme suit :
                            <ul class="mt-20">
                                <li>
                                    la Banque Centrale des Etats de l'Afrique de l'Ouest (BCEAO), représentée par son
                                    Gouverneur ;
                                </li>
                                <li>la Commission Bancaire de l'UMOA, représentée par son Président ;</li>
                                <li>
                                    l’Autorité des Marchés Financiers de l’Union Monétaire Ouest Africaine (AMF-UMOA),
                                    représenté par son Président ;
                                </li>
                                <li>
                                    la Conférence Interafricaine des Marchés d'Assurances (CIMA), représentée par le
                                    Président de la Commission Régionale de Contrôle des Assurances (CRCA) ;
                                </li>
                                <li>
                                    la Conférence Interafricaine de la Prévoyance Sociale (CIPRES), représentée par le
                                    Président de la Commission de Surveillance de la Prévoyance Sociale ;
                                </li>
                                <li>un représentant de chacun des huit (8) Etats membres de l'UMOA.</li>
                            </ul>
                            Le Comité pourra être élargi à toute nouvelle entité dont les missions s'inscrivent dans le
                            cadre de la régulation et de la supervision du secteur financier.
                            La présidence du CSF est assurée par le Gouverneur de la BCEAO.
                            <br>
                            Les missions du Comité sont :
                            <ul class="mt-20">
                                <li>
                                    favoriser la concertation, la coopération et la coordination entre les Autorités
                                    dont les actions concourent à la stabilité financière ;
                                </li>
                                <li>
                                    évaluer les risques susceptibles de nuire à la stabilité du système financier dans
                                    son ensemble, à travers notamment l'analyse des indicateurs macroprudentiels définis
                                    d'un commun accord ;
                                </li>
                                <li>
                                    examiner les dysfonctionnements du système susceptibles d'entraîner, à terme, des
                                    coûts et d'affecter sa résilience aux chocs d'origine interne et externe ;
                                </li>
                                <li>
                                    définir les actions requises pour remédier aux vulnérabilités identifiées et en
                                    assurer la coordination et le suivi ;
                                </li>
                                <li>
                                    émettre des avis et recommandations pour une conduite des actions propres à assurer
                                    le bon fonctionnement et l'efficience du système financier.
                                </li>
                            </ul>
                            Les membres du CSF, chacun en ce qui le concerne, sont chargés de la mise en œuvre des avis
                            et recommandations formulés par le Comité.
                        </ng-container>
                    </td>
                </tr>
                <tr role="row">
                    <td role="cell">
                        Haut Comité ad’hoc sur le Financement des économies
                    </td>
                    <td role="cell" class="text-justify">
                        Au sein de l'Union Economique et Monétaire Ouest Africaine (UEMOA), le financement de l'activité
                        économique constitue l'un des défis majeurs auxquels les Etats membres sont confrontés depuis
                        plusieurs années. En effet, en dépit des actions réalisées dans les Etats, notamment
                        l’assainissement du système bancaire, suite à sa restructuration au début des années 1990, la
                        création du marché financier régional en 1996, la promotion de la microfinance et les
                        initiatives conduites par les Organes et Institutions communautaires, les besoins de financement
                        de l’activité économique restent encore insuffisamment couverts dans l'Union. En outre, les
                        concours à moyen et long termes, essentiels pour assurer les fondements d’une croissance
                        économique durable, représentent une faible proportion des financements disponibles. Par
                        ailleurs, certains secteurs porteurs de croissance, tels que l'industrie et l'agriculture, ainsi
                        que les PME/PMI, potentiellement créateurs d'emplois, bénéficient de très peu de financements.
                        <br/><br/>
                        Au regard de cette situation, qui constitue un handicap majeur pour la réalisation d'une
                        croissance auto-entretenue, forte et durable, la levée des contraintes pesant sur le financement
                        des économies des Etats membres a été inscrite au rang des questions prioritaires à résoudre par
                        l'Union au cours des prochaines années.
                        <br/><br/>
                        A cet égard, la Conférence des Chefs d'Etat et de Gouvernement de l'UEMOA a mis en place en juin
                        2012, un Haut Comité ad’hoc sur le financement des économies présidé par Son Excellence,
                        Monsieur Alassane OUATTARA, Président de la République de Côte d’Ivoire.
                        <br/><br/>
                        L’Autorité des Marchés Financiers de l’Union Monétaire Ouest Africaine (AMF-UMOA) participe au même
                        titre que la Commission de l'UEMOA, la Banque Centrale des Etats de l'Afrique de l'Ouest (BCEAO)
                        et la Banque Ouest Africaine de Développement (BOAD) aux travaux du Comité de Pilotage et du
                        Comité de Suivi aux travaux relatifs audit chantier.
                    </td>
                </tr>
                <tr role="row">
                    <td role="cell">
                        Accord de partenariat signé au niveau régional
                    </td>
                    <td role="cell" class="text-justify">
                        L’AMF-UMOA a signé le 30 septembre 2015 avec la BOAD, un protocole d’accord. Cette convention a
                        pour objet de définir un cadre de partenariat en vue de l’élaboration d’un projet de textes
                        spécifiques relatifs aux opérations de levée de ressources sur le marché financier régional pour
                        le financement des projets de type Partenariat Public – Privé (PPP) dans l’Union.
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
