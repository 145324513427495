<p-panel>
    <ng-template pTemplate="header">
        <span [innerHtml]="title | translate"></span>
    </ng-template>
    <form #searchForm="ngForm">
        <div class="row">
            <div class="form-group col-sm-6">
                <label for="field_libelle">Dénomination</label>
                <input type="text" pInputText required id="field_libelle" name="lib"
                       class="form-control" [(ngModel)]="searchCretiria.lib" minlength="3">

            </div>
            <div class="form-group col-sm-6">
                <label for="field_agrement">N° Agrement</label>
                <input type="text" pInputText required id="field_agrement"  name="agrement"
                       class="form-control" [(ngModel)]="searchCretiria.agrement" minlength="3">

            </div>
        </div>
        <div class="mt-20 pb-5">
            <div style="float:right;">
                <button type="button" label="Vérifier" pRipple id="btn-verif" icon="pi pi-search"
                        pButton [disabled]="searchForm.form.invalid" (click)="searchDataclick()">
                </button>
            </div>
        </div>
    </form>
</p-panel>

