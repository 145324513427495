<!-- Terms & Conditions Area -->
<div class="terms-conditions-area">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Présentation des Intervenants Agréés</h3>
            <div>
                <p class="mb-2">Les acteurs du marché financier régional sont :</p>

                <ul>
                    <li>les Structures Centrales du marché : la Bourse Régionale des Valeurs Mobilières (BRVM) et le
                        Dépositaire Central/Banque de Règlement ;
                    </li>
                    <li>les intervenants commerciaux : les Sociétés de Gestion et d'Intermédiation (SGI),
                        les Banques Teneurs de compte et Conservateurs, les Sociétés de Gestion de Patrimoine (SGP),
                        les Sociétés de Gestion d’Organismes de Placement Collectif, les Sociétés de Gestion des Fonds
                        Communs de Titrisation de Créances (SG-FCTC), les Organismes de Placement Collectif (OPC),
                        les Fonds Communs de Titrisation de Créances (FCTC), les Listings-Sponsors, les Conseils en
                        Investissements Boursiers, les Apporteurs d'Affaires et les Démarcheurs.
                        Ils sont agréés par le Conseil Régional.
                    </li>
                </ul>

                <p>
                    Il est fait interdiction à toute entreprise ou personne autre qu’une structure ou un intervenant
                    agréé par l’AMF-UMOA d’utiliser une dénomination, une raison sociale, une publicité ou d’une façon
                    générale des expressions faisant croire qu’elle est agréée en tant que structure de gestion du
                    marché ou intervenant commercial.
                </p>

                <p>
                    Il est également interdit à toute structure ou à tout intervenant commercial agréé par L’AMF-UMOA
                    de laisser entendre qu’il appartient à une catégorie autre que celle au titre de laquelle il a
                    obtenu son agrément ou de créer une confusion sur ce point.
                </p>
            </div>
        </div>

        <div class="single-content text-justify">
            <h4>
                <i class="pi pi-chevron-right"></i>
                Structures centrales du marché
            </h4>
            <p>
                Elles sont composées de la Bourse Régionale des Valeurs Mobilières (BRVM) et du Dépositaire
                Central/Banque de Règlement (DC/BR).
            </p>
            &nbsp;
            &nbsp;
            <h5>Bourse Régionale des Valeurs Mobilières (BRVM)</h5>
            <p>
                La BRVM est une société anonyme de droit privé dont le siège social est situé à Abidjan. Le capital
                social de la BRVM est détenu par les Etats de l’Union, les Sociétés de Gestion et d’Intermédiation (SGI),
                les Chambres de Commerce et d’Industrie, les institutions sous régionales et d’autres personnes physiques
                ou morales. La BRVM bénéficie d’une concession exclusive de service public sur l’ensemble des territoires
                des Etats membres de l’UMOA, qui est accordée par l’AMF-UMOA, pour le compte des États membres de l’UMOA.
            </p>
            <p>
                La BRVM est l’une des deux Structures Centrales du marché financier régional de l’UMOA.
            </p>
            &nbsp;
            <h6>Missions</h6>
            <p>La BRVM a pour principale mission l'organisation et la gestion du marché boursier et la diffusion
                d'informations boursières. A ce titre la BRVM dispose d’une antenne dans chaque Etat membre de l’Union.
            </p>
            &nbsp;
            <h6>Organisation</h6>
            <p>
                La BRVM est dotée d’une organisation visant à la réalisation des opérations ci-après :
            </p>
            <ul class="mt-20">
                <li>l’inscription des titres à la cote de la Bourse,</li>
                <li>la cotation,</li>
                <li>la publication des informations boursières,</li>
                <li>la promotion et le développement du marché.</li>
            </ul>

            <h6>Fonctionnement</h6>
            <p class="mb-2">
                La BRVM est un marché au comptant centralisé et dirigé par les ordres. Elle organise quotidiennement une
                séance de cotation qui se fait en « continu ». La BRVM est dotée d’un système électronique de cotation et
                dispose d’un réseau de télécommunication qui permet aux SGI de saisir les ordres de bourse à partir des
                différents pays de l’Union dans le système central situé à Abidjan. Elle dispose de deux (2) compartiments
                pour les actions et d’un (1) compartiment pour les obligations.
            </p>
            <p class="mb-2">
                Le premier compartiment des actions est réservé aux sociétés anonymes justifiant d’au moins cinq (5) années
                d’existence, d’une capitalisation boursière de plus de cinq cent millions (500.000.000) de Francs CFA
                et ayant diffusé au moins 20 % de leur capital dans le public.
            </p>
            <p class="mb-2">
                Le second compartiment des actions est accessible aux sociétés anonymes ayant une capitalisation boursière
                d’au moins deux cent millions (200.000.000) de Francs CFA ainsi que deux (2) années d’existence.
                Elles doivent s’engager à diffuser au moins 20 % de leur capital dans le public dans un délai de
                deux (2) ans, ou 15 % en cas d’introduction par augmentation de capital.
            </p>
            <p class="mb-2">
                Le compartiment obligataire est accessible aux emprunts obligataires dont le nombre total de titres à
                l’émission est supérieur à vingt-cinq mille (25.000) et le montant nominal de l’émission au moins égal à
                cinq cent millions (500.000.000) de Francs CFA.
            </p>
            &nbsp;
            <h5>Dépositaire Central/Banque de Règlement (DC/BR)</h5>
            <p>Le DC/BR est une société anonyme de droit privé dont le siège social est situé à Abidjan.
                Le capital social du DC/BR est détenu par les Etats de l’Union, les SGI, les Chambres de Commerce et d’Industrie,
                les institutions sous régionales et d’autres personnes physiques ou morales. Le DC/BR bénéficie d’une
                concession exclusive de service public sur l’ensemble des territoires des Etats membres de l’UMOA,
                qui est accordée par l’AMF-UMOA, pour le compte des États membres de l’UMOA.
                Le DC/BR est l’une des deux Structures Centrales du marché financier régional de l’UMOA
                .
            </p>
            &nbsp;
            <h6>Missions</h6>
            <p>Le DC/BR est chargé de la conservation et de la circulation des valeurs mobilières. Il agit aussi bien
                pour le compte des émetteurs que pour celui des intermédiaires. Il fait office de Banque de Règlement
                et peut détenir des encaisses des négociateurs.</p>
            &nbsp;
            <h6>Organisation</h6>
            <p>
                Le DC/BR dispose d’une organisation visant à la réalisation des opérations suivantes:
            </p>
            <ul class="mt-20">
                <li>la centralisation de la conservation des comptes-titres pour ses adhérents,</li>
                <li>le dénouement des opérations de bourse,</li>
                <li>le règlement des soldes résultant des compensations des opérations de marché et le paiement des
                    produits (intérêts, dividendes, etc.),
                </li>
                <li>la gestion du Fonds de Garantie du Marché.</li>
            </ul>
            &nbsp;
            <h6>Fonctionnement</h6>
            <p>
                Les titres inscrits en compte chez le DC/BR sont dématérialisés pour en faciliter la circulation et
                réduire les risques liés à leur conservation. Les opérations du marché financier sont actuellement
                dénouées à J+3. Le DC/BR est doté, pour la gestion de ces opérations (gestion des comptes titres et
                opérations de règlement – livraison), "d’un système électronique entièrement automatisé".
            </p>
            &nbsp;
            <h4>
                <i class="pi pi-chevron-right"></i>
                Intervenants commerciaux
            </h4>
            <p>Les intervenants commerciaux sont les Sociétés de Gestion et d'Intermédiation (SGI), les Banques Teneurs
                de compte et Conservateurs (BTCC), les Sociétés de Gestion de Patrimoine (SGP), les Organismes de
                Placements Collectifs (OPC), les Sociétés de Gestion d’OPC, les Sociétés de Gestion de Fonds Communs
                de Titrisation de Créances, les Démarcheurs, les Conseils en Investissements Boursiers (CIB) et les
                Apporteurs d'Affaires (AA).
            </p>
            &nbsp;
            <h5>Sociétés de Gestion et d'Intermédiation (SGI)</h5>
            <p class="mb-2">
                Les Sociétés de Gestion et d'Intermédiation (SGI), sont une catégorie d’établissements financiers constituées
                en Sociétés Anonymes, expressément soustraite des dispositions à la réglementation bancaire et ayant reçu
                la qualité de Société de Gestion et d'intermédiation, par agrément de l’AMF-UMOA.
            </p>
            <p>
                Les SGI sont autorisées, à titre exclusif, à exercer, dès leur agrément par l’AMF-UMOA à exercer :
            </p>
            <ul class="mt-20">
                <li>à titre exclusif, les activités de négociateur de valeurs mobilières cotées pour le compte de tiers;</li>
                <li>les activités de teneur de comptes / conservateurs;</li>
                <li>le placement de titres émis ou à émettre sur le marché financier régional de l'UMOA ou sur tout
                    autre marche admis par l’AMF-UMOA.
                </li>
            </ul>
            <p class="mb-2">
                Les activités citées ci-dessus doivent obligatoirement figurer dans l'objet social de la SGI.
            </p>
            <p>
                Les SGI sont admises à exercer, à titre d’activités connexes, dans le respect des règles spécifiques édictées par l’AMF-UMOA.
            </p>
            <ul class="mt-20">
                <li>la gestion sous mandat;</li>
                <li>le conseil en ingénierie financière;</li>
                <li>l'arrangement et la structuration d’Operations financières;</li>
                <li>le Spécialiste en Valeurs du Trésor;</li>
                <li>la prise ferme de titres à émettre sur le marché financier régional de l'UMOA;</li>
                <li>le dépositaire d'Organisme de Placement Collectif (OPC) agréé sur le marché financier régional de l'UMOA;</li>
                <li>le listing sponsor.</li>
            </ul>
            <p class="mb-2">
                Toutes les cessions sur titres cotés à la Bourse Régionale des Valeurs Mobilières sont effectuées par
                l’entremise d’une SGI, sauf cas de dérogation accordée par la Bourse Régionale des Valeurs Mobilières.
            </p>
            <p class="mb-2">
                Les SGI peuvent, seules en association avec d’autres SGI ou en concours avec des établissements bancaires,
                et en vertu d’un contrat écrit avec l’émetteur, assurer le placement de titres à émettre par ce dernier.
            </p>
            <p class="mb-2">
                Pour exercer légalement leur activité professionnelle sur le marché financier, les SGI ont l’obligation
                d’obtenir un agrément de la part du CREPMF. Leur capital minimum est fixé à un (01) milliard de  Francs CFA.
            </p>
            <p class="mb-2">
                L’agrément accordé aux SGI par L’AMF-UMOA emporte agrément pour toutes les activités qui leur sont ouvertes.
            </p>
            &nbsp;
            <h5>Teneurs de Comptes Conservateurs (TCC)</h5>
            <p class="mb-2">
                Un teneur de compte conservateur s’illustre comme étant un dépositaire, chargé de conserver les titres
                financiers pour sa clientèle. Ces titres sont dématérialisés et enregistrés sur chaque compte titre
                de ses clients, qui en restent les seuls propriétaires et décident librement de leur usage (conservation ou vente).
            </p>
            <p class="mb-2">
                Le teneur de compte conservateur est par ailleurs chargé de la gestion comptable, ainsi que des prélèvements fiscaux.
            </p>
            <p class="mb-2">
                Les Banques Teneurs de compte et Conservateurs partagent avec les SGI le monopole de la conservation de titres
                pour le compte de la clientèle.
            </p>
            <p>
                Les banques de L’Union sont habilitées à exercer les activités de teneur de compte et de compensateur pour le
                compte des clients. Elles devront recevoir, à cet effet l'agrément  de l’AMF-UMOA.
            </p>
            &nbsp;
            <h5>Sociétés de Gestion de Patrimoine (SGP)</h5>
            <p class="mb-2">
                Les Sociétés de Gestion de Patrimoine, sont des personnes morales qui, par le biais de placements et
                négociations en bourse effectués par les SGI, interviennent discrétionnairement dans la gestion des titres
                qui leur sont confiés sur la base d'un mandat de gestion établi avec leurs clients.
            </p>
            <p>
                Ces sociétés ne doivent pas détenir les titres et/ou les fonds de leurs clients.
                Pour exercer légalement leur activité professionnelle sur le marché financier, les Sociétés de Gestion
                de Patrimoine ont l’obligation d’obtenir un agrément de la part de l’AMF-UMOA.

            </p>
            &nbsp;
            <h5>Sociétés de Gestion d’Organismes de Placement Collectif</h5>
            <p>
                Il s’agit des sociétés de gestion d’Organismes de Placement Collectif dédiées en fonction de leurs agréments,
                à la gestion de Fonds d’Investissements Alternatifs (FIA) et/ou d’Organismes de Placement Collectif
                en Valeurs Mobilières (OPCVM).
            </p>
            &nbsp;
            <h5>Sociétés de Gestion de FCTC</h5>
            <p class="mb-2">
                La Société de Gestion de FCTC est une société commerciale qui représente les Fonds Communs de Titrisation
                de Créances (FCTC) dont elle assure la gestion à l'égard des tiers et dans toute action en justice,
                tant en demande qu'en défense. Elle doit avoir son siège social dans l’un des Etats membres de l’UEMOA.
            </p>
            <p>
                La Société de Gestion de FCTC doit avoir pour objet social exclusif d'assurer la gestion d'un ou de
                plusieurs FCTC et être agréée par  l’AMF-UMOA.
            </p>
            &nbsp;
            <h5>Organismes de Placement Collectif</h5>
            <p class="mb-2">
                Il s’agit d’un organisme de gestion collective qui peut être, soit un OPCVM, soit un FIA de droit régional
                ou de droit étranger, en forme contractuelle de Fonds Communs de Placement gérés par une Société de Gestion
                d’OPC ou en forme de Société d’Investissement.
            </p>
            <p>
                Les FCP sont représentés par une Société de gestion dans tous les actes intéressant les droits et
                obligations des porteurs de parts ainsi que dans toute action en justice, tant en demande qu’en défense.
                L'investisseur en achetant des parts devient membre d'une copropriété de valeurs mobilières mais ne dispose
                d'aucun droit de vote. Il n'en est pas actionnaire. Les dispositions relatives à l'indivision et aux
                sociétés ne lui sont pas applicables.
            </p>
            &nbsp;
            <h6><small>Organisme de Placement Collectif en Valeurs Mobilières (OPCVM):</small></h6>
            <p>un OPC dont:</p>
            <ul>
                <li>l’objet exclusif est le placement collectif en valeurs mobilières ou d’autres instruments
                    financiers liquides visés par les dispositions règlementaires, des capitaux recueillis auprès
                    d’investisseurs qualifiés ou non qualifiés et dont le fonctionnement est soumis au principe
                    de la répartition des risques;</li>
                <li>les parts sont, à la demande des porteurs, rachetées ou remboursées, directement ou indirectement,
                    à charge des actifs de ces organismes. Est assimilé à de tels rachats ou remboursements le fait,
                    pour un OPCVM, d’agir afin que la valeur de ses parts en bourse ne s’écarte pas sensiblement
                    de leur valeur d’inventaire nette; </li>
                <li>les règles de fonctionnement et d’investissement respectent la politique de placement définie par la règlementation. </li>
            </ul>
            <h6><small>Fonds d’Investissement Alternatif (FIA):</small></h6>
            <p>un OPC qui n’est pas un OPCVM et qui lève des capitaux auprès d’un certain nombre d’investisseurs en vue
                de les investir, conformément à une politique d’investissement définie, dans l’intérêt de ces investisseurs</p>
            &nbsp;
            <h6><small>Société d’Investissement:</small></h6>
            <p class="mb-2">
                des OPC dotés de la personnalité morale. Elles peuvent être autogérés ou gérés par une Société d’Investissement.
            </p>
            <p class="mb-2">
                Elles peuvent être constituées sous forme de Société d’Investissement à Capital Variable (SICAV),
                à savoir un OPC ayant la personnalité juridique, qui émet des actions. Elles peuvent être dédiées
                à l’épargne salariale. Il s’agit alors des SICAVAS (SICAV d’Actionnariat Salarié (SICAVAS).
            </p>
            <p>
                La Société d’Investissement  est régie par les dispositions applicables à l’appel public à l’épargne et
                de celles de l’Acte Uniforme relatif au droit des sociétés commerciales et du GIE du Traité de l’OHADA.
            </p>
            &nbsp;
            <h5>Fonds Communs de Placement (FCP)</h5>
            <p>
                Le Fonds Commun de Placement (FCP) est une copropriété de valeurs mobilières qui n’est pas dotée de la personnalité morale.
            </p>
            &nbsp;
            <h5>Fonds Communs de Titrisation de Créances (FCTC)</h5>
            <p class="mb-2">
                Un Fonds Commun de Titrisation de Créances est une copropriété qui a pour objet d’acquérir des créances
                et d’émettre des obligations ou des parts ou actions représentatives de ces créances.
            </p>
            <p class="mb-2">Le FCTC n'est pas une société et n'a pas la personnalité morale. Les dispositions relatives à l'indivision
                et aux sociétés en participation ne lui sont pas applicables. Autrement c’est le véhicule dédié à une
                opération de titrisation. La titrisation est une opération par laquelle un FCTC acquiert, soit directement
                auprès de tiers cédants, soit par l'intermédiaire d'un autre organisme habilité pour ce faire,
                des créances, ainsi que les sûretés, garanties et accessoires y afférents, en finançant cette acquisition
                par l'émission de titres négociables représentatifs desdites créances, dont la souscription et la
                détention est ouverte aux investisseurs qualifiés ou au public.
            </p>
            <p class="mb-2">Le FCTC est constitué à l'initiative conjointe d'une Société de Gestion et d'un Dépositaire.
                Cette constitution conjointe est matérialisée par le Règlement, qui est cosigné par ces deux entités et
                décrit les modalités applicables au fonctionnement, à l'adaptation et à la liquidation du FCTC.
            </p>
            <p class="mb-2">La Société de Gestion et le Dépositaire établissent également une Note d’Information décrivant l'opération
                de titrisation qui est destinée à l'information préalable des souscripteurs des titres qui seront émis par le FCTC.
            </p>
            <p>Encourent une sanction pénale, les Arrangeurs d’un FCTC qui auront procédé au placement des parts de ce
                FCTC sans agrément de la Société de Gestion ou sans visa par l’AMF-UMOA de sa Note d’Information.
            </p>
            &nbsp;
            <h5>Apporteurs d'Affaires (AA)</h5>
            <p class="mb-2">
                Les Apporteurs d’Affaires sont des personnes physiques ou morales qui transmettent les ordres de leurs
                clients aux Sociétés de Gestion et d'intermédiation (SGI) et/ou qui mettent en relation un client avec
                une SGI, une Société de Gestion de Patrimoine (SGP) ou une Société de Gestion d'Organismes de Placement
                Collectif (SG d'OPC) pour l'ouverture d'un compte de titres, la souscription de parts d'OPC et pour les
                conseils en placement ou la gestion sous mandat.
            </p>
            <p class="mb-2">Il est fait interdiction aux Apporteurs d’Affaires de recevoir de leurs clients des dépôts de fonds et de titres.</p>
            <p class="mb-2">Toute personne physique ou morale qui fait profession habituelle ou accessoire d’Apporteur d’Affaires,
                à titre indépendant, ne peut exercer cette activité sans l’agrément préalable de l’AMF-UMOA.
            </p>
            <p>Outre, les personnes physiques ou morales agréées à cet effet, les établissements bancaires sont autorisés
                à effectuer des activités d’Apporteurs d’Affaires.</p>
            &nbsp;
            <h5>Conseils en Investissements Boursiers (CIB)</h5>
            <p>
                Les Conseils en Investissements Boursiers sont les personnes physiques ou morales qui orientent le choix
                de leurs clients sans se substituer à eux quant à leurs décisions finales. Ils peuvent aussi fournir à
                un émetteur le conseil en matière de structuration de dette et de capital, de stratégie industrielle,
                de fusion et de rachat d'entreprises ainsi que la prise en charge des bulletins de souscription par
                voie de convention précisant notamment les obligations du conseiller en investissements participatifs
                et tes frais facturés.
            </p>
            &nbsp;
            <h5>Démarcheurs</h5>
            <p>
                Les Démarcheurs sont des personnes physiques se livrant à des opérations qui consistent à se rendre de
                façon habituelle soit au domicile ou à la résidence des personnes, soit sur leurs lieux de travail,
                soit dans les lieux ouverts au public et non réservés à de telles fins en vue de conseiller la souscription,
                l’achat, l’échange, la vente de valeurs mobilières ou, la participation à des opérations sur ces valeurs.
            </p>
            <p class="mb-2">
                Sont également considérées comme activités de démarchage, les offres de service faites ou les conseils donnés,
                de façon habituelle, en vue des mêmes fins que celles visées à l’alinéa précédent, au domicile ou à la
                résidence des personnes ou sur les lieux de travail par l'envoi de lettres ou circulaires,
                ou par communication téléphonique.
            </p>
            <p class="mb-2">
                Est prohibée toute forme de colportage des valeurs mobilières qui consiste à se rendre au domicile ou à
                la résidence des personnes ou sur leurs lieux de travail ou dans des lieux publics pour offrir ou
                acquérir des valeurs mobilières avec livraison immédiate des titres et paiement immédiat total ou
                partiel sous quelque forme que ce soit.
            </p>
            <p>
                Nul ne peut, à titre de profession habituelle, sans l'agrément préalable de l’AMF-UMOA,
                exercer les activités d’Apporteurs d’Affaires,  de Conseil en Investissement Boursier ou de Démarcheur.
            </p>
            &nbsp;
            <h4>
                <i class="pi pi-chevron-right"></i>
                Listing Sponsor
            </h4>
            <p class="mb-2">
                Le Listing Sponsor est une entreprise habilitée assister et conseiller une autre entreprise dans le
                cadre de son processus d'admission au Troisième Compartiment de la Bourse Régionale des
                Valeurs Mobilières (BRVM) de l'UMOA et tout au long de sa présence audit Compartiment.
            </p>
            <p class="mb-2">
                L'activité de Listing Sponsor est exercée par les Sociétés de Gestion et d'intermédiation (SGI),
                les Etablissements de crédit ainsi que les Cabinets d'audit et/ou de conseil financier(s).
            </p>
            <p>
                L'exercice de l'activité de Listing Sponsor sur le marché financier régional de l’UMOA est
                subordonné l'agrément préalable de l’AMF-UMOA.
            </p>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
