<!-- Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'Acceuil.cartes.intervenants' | translate}}</h3>
            <ul>
                <li><a routerLink="/">{{'MenuBar.accueil' | translate}}</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li><a>{{'Acceuil.cartes.intervenants' | translate}}</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->
<div class="blog-area-two pt-20 pb-20">
    <div class=" card container p-2">
        <p-tabView styleClass="tabview-custom" (activeIndexChange)="onTabChange($event)">
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-users">&nbsp;</i>
                    <span>Présentation</span>
                </ng-template>
                <app-presentation></app-presentation>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-dollar">&nbsp;</i>
                    <span>Intervenants agréés</span>
                </ng-template>
                <app-intervenant-agrees></app-intervenant-agrees>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-file-pdf">&nbsp;</i>
                    <span>Liste des OPC</span>
                </ng-template>
                <app-intervenant-opc></app-intervenant-opc>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-sun">&nbsp;</i>
                    <span>Activités Réglementées</span>
                </ng-template>
                <app-activite-soumise></app-activite-soumise>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-sun">&nbsp;</i>
                    <span>Guide des acteurs</span>
                </ng-template>
                <app-intervenant-guide></app-intervenant-guide>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-sun">&nbsp;</i>
                    <span>Autres intervenants</span>
                </ng-template>
                <app-intervenant-autre></app-intervenant-autre>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
