<nav class="side-menu">
    <ul>
        <li>
            <a [routerLink]="['/pratique/verification/agrement']">
                {{'Acceuil.pratiques.agrement.title' | translate}}
                <span>
                    <img id="li-5" src="../../../../assets/img/verification-agre-blue.png" alt="" height="42">
                </span>
            </a>
        </li>
        <li>
            <a [routerLink]="['/pratique/verification/cartepro']">
                <small>{{'Acceuil.pratiques.cartePro.title' | translate}}</small>
                <span>
                    <img id="li-6" src="../../../../assets/img/verify-carte-blue.png" alt="" height="42">
                </span>
            </a>
        </li>
        <li>
            <a [routerLink]="['/pratique/procedure/requete']">
                {{'Acceuil.pratiques.requete.title' | translate}}
                <span>
                    <img id="li-4" src="../../../../assets/img/requete-blue.png" alt="" height="42">
                </span>
            </a>
        </li>
        <li>
            <a [routerLink]="['/pratique/procedure/plainte']">
                {{'Acceuil.pratiques.plainte.title' | translate}}
                <span>
                    <img id="li-3" src="../../../../assets/img/complaint-blue.png" alt="" height="42">
                </span>
            </a>
        </li>
        <li>
            <a [routerLink]="['/pratique/appel-offre/emplois']">
                {{'Acceuil.pratiques.offreEmploi.title' | translate}}
                <span>
                    <img id="li-1" src="../../../../assets/img/offer-blue.png" alt="" height="42">
                </span>
            </a>
        </li>
        <li>
            <a [routerLink]="['/pratique/appel-offre/marche']">
                {{'Acceuil.pratiques.appelOffre.title' | translate}}
                <span>
                    <img id="li-2" src="../../../../assets/img/market-blue.png" alt="" height="42">
                </span>
            </a>
        </li>
    </ul>
</nav>
