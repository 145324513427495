import { Component, OnInit } from '@angular/core';
import {
    FetchActeurList,
    FetchCacList,
    FetchFcpList,
    SetActeurList,
    SetCacList,
    SetFcpList
} from '../../../store/actions/jupiter-portail.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';

@Component({
    selector: 'app-intervenants',
    templateUrl: './intervenants.component.html',
    styleUrls: ['./intervenants.component.scss']
})
export class IntervenantsComponent implements OnInit {

    constructor(private store: Store<AppState>) {
    }

    ngOnInit(): void {
    }

    onTabChange(tabIndex: number) {
        switch (tabIndex) {
            case 1: {
                this.store.dispatch(SetActeurList({data: [], totalRecord: 0}));
                this.store.dispatch(FetchActeurList({
                    size: 10,
                    page: 0
                }));

                break;
            }
            case 2: {
                this.store.dispatch(SetFcpList({data: [], totalRecord: 0}));
                this.store.dispatch(FetchFcpList({
                    size: 10,
                    page: 0
                }));

                this.store.dispatch(SetActeurList({data: [], totalRecord: 0}));
                this.store.dispatch(FetchActeurList({
                    typeIds: [5, 11],
                    size: 100,
                }));

                break;
            }
            case 5: {
                this.store.dispatch(SetActeurList({data: [], totalRecord: 0}));
                this.store.dispatch(FetchActeurList({
                        typeIds: [12, 13],
                        size: 10,
                        page: 0
                    }
                ));

                this.store.dispatch(SetCacList({data: [], totalRecord: 0}));
                this.store.dispatch(FetchCacList({
                    size: 10,
                    page: 0
                }));

                break;
            }
        }
    }
}
