import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intervenant-guide',
  templateUrl: './intervenant-guide.component.html',
  styleUrls: ['./intervenant-guide.component.scss']
})
export class IntervenantGuideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
