<!-- Inner Banner -->
<div class="inner-banner inner-bg7" xmlns="http://www.w3.org/1999/html">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Organisation</h3>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li>Organisation</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-45">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Organisation</h3>
            <div>
                <span>L’Autorité des Marchés Financiers de l’Union Monétaire Ouest Africaine (AMF-UMOA) est un Organe de l'UMOA.
                    Il a été créé le 3 juillet 1996 par décision du Conseil des Ministres de l'UMOA,
                    dans le cadre de la mise en place du Marché Financier Régional dont il assure la tutelle.
                    Il est chargé d’une mission générale de protection de l’épargne investie en valeurs mobilières
                    et en tout autre placement donnant lieu à une procédure d’appel public à l’épargne dans l’ensemble
                    des Etats membres de l’Union.
                </span>
            </div>
            <div class="mt-10">Son siège est à Abidjan, en Côte d'Ivoire.</div>

        </div>

        <div class="single-content text-justify">
            <h6>
                <i class="pi pi-chevron-right"></i>
                Les instances décisionnelles
            </h6>
            <div>
                L' <strong>AMF-UMOA</strong> est dirigé par un collège de douze (12) membres et un Comité
                Exécutif de quatre (04) membres.
            </div>
            <br/>
            <div>Le collège des douze membres comprend les personnalités suivantes :</div>
            <ul class="mt-20">
                <li>
                    un représentant de chaque Etat membre, nommé par le Conseil des Ministres de l'Union Monétaire Ouest
                    Africaine (UMOA) sur proposition de l'Etat concerné, en raison de sa compétence et de son expérience
                    en matière financière, comptable ou juridique, pour un mandat de trois ans, renouvelable une fois,
                </li>
                <li>le Gouverneur de la Banque Centrale des Etats de l'Afrique de l'Ouest (BCEAO) ou son représentant,
                </li>
                <li>
                    le Président de la Commission de l'Union Economique et Monétaire Ouest Africaine (UEMOA) ou son
                    représentant,
                </li>
                <li>
                    un Magistrat spécialisé en matière financière, nommé par le Conseil des Ministres de l'UMOA pour un
                    mandat de trois ans, renouvelable une fois, sur la base d'une liste proposée par le Président de la
                    Cour de Justice de l'UEMOA,
                </li>
                <li>
                    un Expert Comptable réputé, nommé par le Conseil des Ministres de l’UMOA pour un mandat de trois
                    ans, renouvelable une fois, sur la base d'une liste proposée par le Président de la Cour des Comptes
                    de l'UEMOA.
                </li>
            </ul>
            <div class="mt-20 mb-2">Le Collège des Membres de l’AMF-UMOA se réunit en session ordinaire.</div>
            <p>
                Le Comité Exécutif est composé du Président du l’AMF-UMOA et du Gouverneur de la BCEAO ou son
                représentant en qualité de membres permanents ainsi que de deux autres membres désignés parmi le
                Collège des Membres nommés par le Conseil des Ministres de l’UMOA pour une durée d’un an.
            </p>
            <div class="mt-20">
                Le Comité Exécutif agit dans les limites de la délégation de pouvoirs conférée par l’AMF-UMOA.
                Il lui rend compte de ses décisions.
            </div>
            &nbsp;
            <h6>
                <i class="pi pi-chevron-right"></i>
                Le Secrétariat Général
            </h6>
            <div class="mt-20 mb-2">
                L’AMF-UMOA est doté d'un Secrétariat Général qui veille à l’exécution des
                décisions des instances décisionnelles.
            </div>
            <p>
                Il comprend un corps d'inspecteurs et un service administratif. Il est dirigé par un Secrétaire Général,
                nommé pour une période de cinq ans, renouvelable une fois.
            </p>
            <div class="mt-20">L’organigramme du Secrétariat Général se présente comme suit :</div>
            <div class="mt-45">
                <p-organizationChart [value]="data" selectionMode="single" styleClass="company">
                    <ng-template let-node pTemplate="person">
                        <div class="node-header">{{node.label}}</div>
                        <div class="node-content">
                        </div>
                    </ng-template>
                    <ng-template let-node pTemplate="department">
                        {{node.label}}
                    </ng-template>
                </p-organizationChart>
            </div>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
