<div class="blog-area-two">
    <div class="container">
        <div class="actuality-title">
            <h3><span class="">{{'Acceuil.actualites.title' | translate}}</span></h3>
        </div>
        <div class="pt-30">
            <div id="flux-rss" class="mb-sm-3">
                <a href="javascript:void(0)" (click)="showRssInput = !showRssInput">
                    <span class="pi pi-wifi">&nbsp;</span>
                    &nbsp;
                    <span>{{'Acceuil.actualites.rss' | translate}}</span>
                </a>
                <div class="p-inputgroup" *ngIf="showRssInput">
                    <input #inputEmail type="email" placeholder="Votre adresse E-mail*"
                           name="EMAIL" required minlength="3">
                    <p-captcha siteKey="6LfBB2smAAAAAAZpGRGO6vIYjXMEBBDCsXEgxhND"
                               (onResponse)="showResponse($event)" initCallback="onloadCallback"
                               [language]="'fr'"></p-captcha>
                    <button [disabled]="isDisabled" pButton type="submit"  (click)="saveEmail(inputEmail)">
                        <i class="pi pi-send"></i>&nbsp;Envoyer
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-4" *ngFor="let actualite of actualityList">
                    <div class="blog-card">
                        <a routerLink="/actuality-details" [queryParams]="{actualiteId: actualite.id}">
                            <img [src]="jupiterPortailService.buildUrl('photoActualiteHomePage', elasticIndexName.actualite, actualite.id, 'photoActualiteHomePage')" alt="">
                        </a>
                        <div class="content">
                            <ul class="list-tag">
                                <li *ngFor="let tag of actualite.tags"><a class="tag">{{tag}}</a></li>
                            </ul>
                            <p>{{actualite.date | date}}</p>
                            <p class="p-color">{{actualite.resume}}</p>
                            <app-actua-social-link [actualite]="actualite"></app-actua-social-link>
                            <a routerLink="/actuality-details" [queryParams]="{actualiteId: actualite.id}"
                               class="blog-more">
                                <i class="flaticon-double-right-arrows-angles"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="service-view-btn text-center">
        <a routerLink="/actualities" class="view-btn">
            <i class="flaticon-double-right-arrows-angles"></i>
            &nbsp;
            <span>{{'Acceuil.actualites.plus' | translate}}</span>
        </a>
    </div>
</div>
