<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-20">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Liste des OPC agréés du Marché Financier Régional de l'UMOA</h3>
        </div>
        <p-panel>
            <ng-template pTemplate="header">
                <span>Zone de filtrage</span>
            </ng-template>
            <form #searchForm="ngForm">
                <div class="row">
                    <div class="form-group col-sm-6">
                        <label for="field_secteur">Types Fonds</label>
                        <p-dropdown id="field_secteur" [options]="typeFcpList"
                                    [filter]="true" [showClear]="true"
                                    [autoDisplayFirst]="false"
                                    [(ngModel)]="criteria.typeFcpId" name="secteur"
                                    [style]="{width: '100%'}" appendTo="body">
                        </p-dropdown>

                    </div>
                    <div class="form-group col-sm-6">
                        <label for="field_formj">Société de gestion</label>
                        <p-dropdown id="field_formj" [options]="acteurList"
                                    [filter]="true" [showClear]="true"
                                    [autoDisplayFirst]="false"
                                    [(ngModel)]="criteria.acteurId" name="formj"
                                    [style]="{width: '100%'}" appendTo="body">
                        </p-dropdown>

                    </div>
                </div>
                <!--<div class="row mt-20">
                    <div class="form-group">
                        <label for="field_denomination">Dénomination</label>
                        <input id="field_denomination" [(ngModel)]="criteria.denomination"
                               name="denomination" [style]="{width: '100%'}" pInputText/>

                    </div>
                </div>-->
                <div class="mt-20 pb-5">
                    <div style="float:right;">
                        <button type="button" label="Vérifier" pRipple pButton
                                icon="pi pi-search" class="btn-verif" (click)="searchData()">
                        </button>
                    </div>
                </div>
            </form>
        </p-panel>
        &nbsp;
        <p-table #dt [value]="fcpList" [columns]="cols" responsiveLayout="scroll"
                 [paginator]="false" [rowHover]="true" dataKey="id">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <ng-container *ngFor="let col of columns">
                        <th [style.width]="col.width" [pSortableColumn]="col.field"
                            *ngIf="col.sort">
                            <span>{{col.header}}</span>
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th [style.width]="col.width" *ngIf="!col.sort">
                            <span>{{col.header}}</span>
                        </th>
                    </ng-container>
                    <th [style.width]="'5%'"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-entity let-columns="columns">
                <tr>
                    <ng-container *ngFor="let col of columns">
                        <td [style.width]="col.width">
                            <span class="p-column-title">{{col.header}}</span>
                            <app-table-td [col]="col" [entity]="entity" [indexName]="indexName"></app-table-td>
                        </td>
                    </ng-container>
                    <td [style.width]="'5%'">
                        <div class="flex justify-content-center">
                            <button pButton pRipple icon="pi pi-eye" title="Détails"
                                    class="p-button-rounded btn-style"
                                    (click)="displayDialog(false, entity)">
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <app-pagination [rows]="size" [totalRecords]="totalRecords" (paginate)="pageChange($event)"></app-pagination>
    </div>
    <p-dialog #dd [(visible)]="display" [style]="{width: '80vw', height: '80vw'}" [maximizable]="true"
              header="Détails Acteur" [modal]="true" [closable]="true" (onHide)="displayDialog(true)">
        <form>
            <app-verif-details title="Détails Acteur"
                               [tab0Cols]="detCols"
                               [defaultPagination]="true"
                               [addTabView]="true"
                               [justForms]="true"
                               title3="Formulaires" [typeEntity]="'idActeur'"
                               [searchedEntity]="selectedOpc">
            </app-verif-details>
        </form>
    </p-dialog>
</div>
<!-- Terms & Conditions Area End -->
