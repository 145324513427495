import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import {
    FetchEmetteurList,
    FetchOperationList,
    SetEmetteurList,
    SetOperationList
} from '../../../store/actions/jupiter-portail.actions';

@Component({
    selector: 'app-emetteurs',
    templateUrl: './emetteurs.component.html',
    styleUrls: ['./emetteurs.component.scss']
})
export class EmetteursComponent implements OnInit {

    constructor(private store: Store<AppState>) {
    }

    ngOnInit(): void {
    }

    onTabChange(tabIndex: number) {
        switch (tabIndex) {
            case 0: {
                this.store.dispatch(SetEmetteurList({data: [], totalRecord: 0}));
                this.store.dispatch(FetchEmetteurList({
                    size: 10,
                    page: 0
                }));

                break;
            }
            case 1: {
                this.store.dispatch(SetOperationList({data: [], totalRecord: 0}));
                this.store.dispatch(FetchOperationList({
                    size: 50,
                    page: 0
                }));

                break;
            }
        }
    }

}
