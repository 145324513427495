import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { Subject, takeUntil } from 'rxjs';
import { selectActeurList, selectCacList, selectPaysList } from '../../../../store/selectors/jupiter-portail.selectors';
import { Acteur } from '../../../../store/entity/entity.model';
import { ETableName, Pagination } from '../../../common/utils/utils';
import {
    FetchActeurList,
    FetchCacList,
    FetchPaysList,
    SetActeurList,
    SetCacList
} from '../../../../store/actions/jupiter-portail.actions';
import { ActeurCriteria } from '../../../../store/services/request-params';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'app-intervenant-autre',
    templateUrl: './intervenant-autre.component.html',
    styleUrls: ['./intervenant-autre.component.scss']
})
export class IntervenantAutreComponent implements OnInit, OnDestroy {
    colsActeurs: Array<any>;
    organismeDataList: Array<Acteur>;
    agenceNotationDataList: Array<Acteur>;
    criteria: ActeurCriteria = {typeIds: [12, 13]};
    paysList: Array<SelectItem>;
    colsCacs: Array<any>;
    commissaireCompteDataList: Array<any>;
    destroy$ = new Subject<boolean>();
    cacTotalRecords = 0;
    acteurTotalRecords = 0;
    page = 0;
    size = 10;
    cacIndexName = ETableName.cac;
    acteurIndexName = ETableName.acteur;


    constructor(private store: Store<AppState>) {
        this.store.dispatch(FetchPaysList());

        this.colsActeurs = [
            {field: 'lib', header: 'Dénomination', type: 'string', width: '25%', sort: true},
            {
                field: 'agrement', header: 'Approbation', type: 'pdf', sort: true,
                fileField: 'decision', width: '15%', showText: true
            },
            {field: 'dateAgrement', header: 'Date Approb.', type: 'date', sort: true, width: '15%'},
            {field: 'mail', header: 'Mail', type: 'string', sort: true, width: '15%'},
            {field: 'telephone', header: 'Tél', type: 'string', sort: true, width: '10%'},
            {field: 'paysCode', header: 'Pays', type: 'string', sort: true, width: '15%'}
        ];


        this.colsCacs = [
            {field: 'lib', header: 'Dénomination', type: 'string', width: '25%', sort: true},
            {field: 'numInscriptionOrdre', header: 'N° Inscrip.', type: 'string', sort: true, width: '12%'},
            {field: 'dateApprobation', header: 'Date Approb.', type: 'date', sort: true, width: '15%'},
            {field: 'nationalite', header: 'Nationalité', type: 'string', sort: true, width: '13%'},
            {field: 'adresse', header: 'Adresse', type: 'string', sort: true, width: '15%'},
            {field: 'catPers', header: 'Pers.', type: 'string', sort: true, width: '15%'}
        ];
    }

    ngOnInit(): void {
        this.store.pipe(select(selectPaysList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.paysList = [];
                        resultSet.map(item => this.paysList.push({label: item.lib, value: item.id}));
                    }
                }
            });

        this.store.pipe(select(selectActeurList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    this.organismeDataList = [];
                    this.agenceNotationDataList = [];
                    if (resultSet) {
                        this.organismeDataList = resultSet.data.filter(item => item.typeId === 12);
                        this.agenceNotationDataList = resultSet.data.filter(item => item.typeId === 13);
                        this.acteurTotalRecords = resultSet.totalRecord;
                    }
                }
            });

        this.store.pipe(select(selectCacList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    this.commissaireCompteDataList = [];
                    if (resultSet) {
                        this.commissaireCompteDataList = [...resultSet.data];
                        this.cacTotalRecords = resultSet.totalRecord;
                    }
                }
            });
    }

    acuteurPageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;

            this.store.dispatch(SetActeurList({data: [], totalRecord: 0}));
            this.store.dispatch(FetchActeurList({
                    ...this.criteria,
                    size: this.size,
                    page: this.page
                }
            ));
        }
    }

    cacPageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;

            this.store.dispatch(SetCacList({data: [], totalRecord: 0}));
            this.store.dispatch(FetchCacList({
                ...this.criteria,
                size: this.size,
                page: this.page
            }));
        }
    }

    searchData() {
        this.store.dispatch(SetActeurList({data: [], totalRecord: 0}));
        if (this.criteria.denomination || this.criteria.paysId) {
            this.store.dispatch(FetchActeurList({
                ...this.criteria,
                size: this.size,
                page: this.page
            }));
        } else {
            this.store.dispatch(FetchActeurList({
                ...this.criteria,
                size: this.size,
                page: this.page
            }));
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
