import { Component, OnDestroy, OnInit } from '@angular/core';
import { Fcp } from '../../../../store/entity/entity.model';
import { SelectItem } from 'primeng/api';
import { FcpCreteria } from '../../../../store/services/request-params';
import { select, Store } from '@ngrx/store';
import {
    selectActeurList,
    selectFcpList,
    selectTypeFcpList
} from '../../../../store/selectors/jupiter-portail.selectors';
import { Subject, takeUntil } from 'rxjs';
import { ETableName, Pagination } from '../../../common/utils/utils';
import { AppState } from '../../../../store/app.state';
import {
    FetchActeurList,
    FetchFcpList,
    FetchTypeFcpList,
    SetFcpList
} from '../../../../store/actions/jupiter-portail.actions';

@Component({
    selector: 'app-intervenant-opc',
    templateUrl: './intervenant-opc.component.html',
    styleUrls: ['./intervenant-opc.component.scss']
})
export class IntervenantOpcComponent implements OnInit, OnDestroy {
    cols: Array<any>;
    display = false;
    selectedOpc: Fcp;
    detCols: Array<any>;
    fcpList: Array<Fcp>;
    typeFcpList: Array<SelectItem>;
    acteurList: Array<SelectItem>;
    criteria: FcpCreteria = {};
    destroy$ = new Subject<boolean>();
    totalRecords = 0;
    page = 0;
    size = 10;
    indexName = ETableName.fcp;

    constructor(private store: Store<AppState>) {
        this.store.dispatch(FetchTypeFcpList());
        this.cols = [
            {field: 'typeFcpLib', header: 'Type', type: 'string', width: '15%', sort: true},
            {
                field: 'lib', header: 'Dénomination', type: 'pdf', width: '22%',
                sort: true, fileField: 'noteInfo', showText: true
            },
            {
                field: 'agrement', header: 'Agrément', type: 'pdf', sort: true,
                fileField: 'decision', width: '13%', showText: true
            },
            {
                field: 'dateAgrement', header: 'Date Agrément', type: 'date',
                sort: true, width: '15%', styleClass: 'text-center'
            },
            {
                field: 'prospectusUrl', header: 'Prospectus', type: 'pdf', sort: true,
                width: '12%', fileField: 'prospectusUrl', showText: true
            },
            {field: 'acteurLib', header: 'SGO', type: 'string', sort: true, width: '18%'}
        ];

        this.detCols = [
            {field: 'acteurLib', header: 'Société Gestion', type: 'string'},
            {field: 'typeFcpLib', header: 'Type', type: 'string'},
            {field: 'lib', header: 'Dénomination', type: 'string'},
            {field: 'siege', header: 'Siège social', type: 'string'},
            {field: 'rccm', header: 'RCCM', type: 'string'},
            {field: 'agrement', header: 'Agrément', type: 'string'},
            {field: 'dateAgrement', header: 'Date d\'Agrément', type: 'date'},
            {field: 'telephone', header: 'Téléphone', type: 'string'},
            {field: 'fax', header: 'Fax', type: 'string'},
            {field: 'mail', header: 'Email', type: 'string'},
            {field: 'siteWeb', header: 'Site Web', type: 'string'},
            {field: 'prospectus', header: 'Site Web', type: 'string'}
        ];
    }

    ngOnInit(): void {
        this.store.pipe(select(selectTypeFcpList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.typeFcpList = [];
                        resultSet.map(item => this.typeFcpList.push({label: item.lib, value: item.id}));
                    }
                }
            });

        this.store.pipe(select(selectActeurList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.acteurList = [];
                        resultSet.data.map(item => this.acteurList.push({label: item.lib, value: item.id}));
                    }
                }
            });

        this.store.pipe(select(selectFcpList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.fcpList = [...resultSet.data];
                        this.totalRecords = resultSet.totalRecord;
                    } else {
                        this.searchData();
                    }
                }
            });
    }

    searchData() {
        this.store.dispatch(SetFcpList({data: [], totalRecord: 0}));
        if (this.criteria.acteurId || this.criteria.typeFcpId) {
            this.store.dispatch(FetchFcpList({
                ...this.criteria,
                size: this.size,
                page: this.page
            }));
        } else {
            this.store.dispatch(FetchFcpList({
                size: this.size,
                page: this.page
            }));
        }
    }

    displayDialog(hide: boolean, entity?: any) {
        if (this.display || hide) {
            this.display = false;
            this.selectedOpc = null;
        } else {
            this.selectedOpc = entity;
            this.display = true;
        }
    }

    pageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;

            this.searchData();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
