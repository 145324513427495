<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-20">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Activités soumises à l’agrément de l'AMF-UMOA</h3>
            <p>
                Conformément à la règlementation en vigueur, l’exercice des activités ci-après sur le marché financier régional,
                sans que cette liste ne soit limitative, est soumis à l’agrément ou l’approbation de l’AMF-UMOA.
            </p>
        </div>

        <div class="single-content">
            <table role="table" class="d-table table table-hover">
                <thead>
                <tr>
                    <th>Activités</th>
                    <th>Description</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Négociation de valeurs mobilières</td>
                    <td>
                        <p class="pb-2">Cette activité, exclusivement réservée aux SGI, consiste principalement à
                            l’exécution des transactions sur les valeurs mobilières au niveau du marché boursier.</p>

                        <p>Placement de Valeurs Mobilières Constitue un service de placement, le fait de rechercher des
                            souscripteurs ou des acquéreurs pour le compte d'un émetteur ou d'un cédant d'instruments
                            financiers. C’est notamment le cas à l’occasion des opérations d’introductions en bourse,
                            d’offres publiques ou d’émissions obligataires.</p>
                    </td>
                </tr>
                <tr>
                    <td>Tenue de Compte de Titres</td>
                    <td>
                        <p>La tenue de comptes est une activité par laquelle un intermédiaire financier procède à
                            l’ouverture de comptes titres, à l’enregistrement des opérations sur les titres et à la
                            conservation des actifs au profit de ses clients. Cette activité est exercée par des
                            teneurs de comptes agréés par l’AMF-UMOA.</p>
                    </td>
                </tr>
                <tr>
                    <td>Réception et Transmission d’Ordres</td>
                    <td>
                        <p>On appelle la réception- transmission d'ordres pour le compte de tiers l'activité exercée par
                            tout intervenant agréé qui, pour le compte d'un donneur d'ordres, transmet à un prestataire
                            habilité, en vue de leur exécution, des ordres portant sur la négociation d'instruments financiers.</p>
                    </td>
                </tr>
                <tr>
                    <td>Animation</td>
                    <td>
                        <p>Les SGI peuvent être mandatées par les sociétés cotées en vue de présenter quotidiennement
                            des offres d’achat et de vente afin d’améliorer la liquidité des titres.</p>
                    </td>
                </tr>
                <tr>
                    <td>Gestion sous mandat</td>
                    <td>
                        <p>Sont réputées exercer, à titre principal, une activité de gestion de titres sous mandat,
                            à caractère privé, les personnes morales qui, par le biais de placements et négociations
                            en bourse effectués par les SGI auxquelles elles transmettent les ordres correspondants,
                            interviennent discrétionnairement dans la gestion des titres qui leur sont confiés sur
                            la base d’un mandat de gestion établi avec leurs clients.</p>
                    </td>
                </tr>

                <tr>
                    <td>Conseil en Investissement Boursier</td>
                    <td>
                        <p>Les personnes physiques et morales qui exercent les activités de conseil en investissements
                            boursiers orientent les choix de leurs clients sans se substituer à eux
                            quant à leurs décisions finales.</p>
                    </td>
                </tr>

                <tr>
                    <td>Démarchage Financier</td>
                    <td>
                        <p class="pb-2">Sont considérées comme activités de démarchage, toutes opérations auxquelles se
                            livre celui qui se rend habituellement soit au domicile ou à la résidence des personnes,
                            soit sur leurs lieux de travail, soit sur les lieux ouverts au public et non réservés à de
                            telles fins en vue de conseiller la souscription, l'achat, l'échange, la vente de valeurs
                            mobilières ou, la participation à des opérations sur ces valeurs.</p>
                        <p>Sont également considérées comme activités de démarchage, les offres de service faites ou
                            les conseils donnés, de façon habituelle, en vue des mêmes fins que celles visées à
                            l’alinéa précédent, au domicile ou à la résidence des personnes ou sur les lieux de travail
                            par l'envoi de lettres ou circulaires, ou par communication téléphonique.</p>
                    </td>
                </tr>

                <tr>
                    <td>Gestion collectif d’actifs</td>
                    <td>
                        <p class="pb-2">C’est une activité qui consiste à gérer les capitaux confiés par un Organisme
                            de Placement Collectif dans le respect des contraintes réglementaires et contractuelles,
                            en appliquant les consignes et/ou politiques d'investissements définies par le détenteur
                            des actifs gérés, pour en tirer le meilleur rendement possible en fonction d'un niveau
                            de risque choisi.</p>
                        <p>Sur le marché financier régional, cette activité est exclusivement réservée
                            aux Société de gestion d’OPC.</p>
                    </td>
                </tr>
                <tr>
                    <td>Titrisation de créances</td>
                    <td>
                        <p>C’est une opération par laquelle un Fonds commun de titrisation de créances acquiert,
                            soit directement auprès de tiers cédants, soit par l'intermédiaire d'un autre organisme
                            habilité pour ce faire, des créances, ainsi que les sûretés, garanties et accessoires
                            y afférents, en finançant cette acquisition par l'émission de titres négociables
                            représentatifs desdites créances, dont la souscription et la détention est ouverte
                            aux investisseurs qualifiés ou au public.</p>
                    </td>
                </tr>
                <tr>
                    <td>Notation financière</td>
                    <td>
                        <p>Une notation est un avis émis par application d’un système de classification bien et bien
                            établi prévoyant différentes catégories de notations concernant la solvabilité d’une entité,
                            la qualité de crédit d’un titre de créance ou d’une mobilière représentative de créance.</p>
                    </td>
                </tr>
                <tr>
                    <td>Garantie des opérations financières</td>
                    <td>
                        <p>Les organismes de garantie sont des personnes morales qui apportent leur cautionnement dans
                            l’émission des emprunts obligataires ou de tout autre titre faisant objet d’appel public
                            à l’épargne. En cas de défaillance de l’émetteur, l’organisme couvrira ses échéances
                            de remboursement ou engagements.</p>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
