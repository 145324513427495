import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import {Meta} from "@angular/platform-browser";

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(private router: Router,
                private meta: Meta) {
        // TODO: Deactivate this for installation on MOSIUM !!!!!!!!!!!!!
        this.addTags();
    }

    ngOnInit() {
        this.recallJsFuntions();
    }

    addTags() {
        this.meta.addTag({
            name: 'title',
            content: 'Autorité des Marchés Financiers de l\'Union Monétaire Ouest Africaine (AMF-UMOA)' });

        this.meta.addTag({
            name: 'description',
            content: 'L\'Autorité des Marchés Financiers de l\'Union Monétaire Ouest Africaine (AMF-UMOA) ' +
                'est l\'autorité publique de régulation qui s\'assure du bon fonctionnement du marché financier régional. ' +
                'Il a été créé le 3 juillet 1996 par une Convention signée entre les huit (08) Etats de l’Union que sont ' +
                'Bénin, Burkina Faso, Côte d\'Ivoire, Guinée Bissau, Mali, Niger, Sénégal et Togo. Son siège se trouve à ' +
                'Abidjan, en Côte d’Ivoire' });

    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('.preloader').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/custom.js');
                $('.preloader').fadeOut('slow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}
