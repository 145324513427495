<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-20">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Présentation des Autres Intervenants</h3>
            <p>
                Il s’agit des acteurs dont les interventions sur le marché financier régional sont soumises à
                l’approbation préalable de l’AMF-UMOA.
                <br><br>
                Ce sont actuellement les Organismes de garantie, les Agences de Notation et les Commissaires aux
                Comptes.
            </p>
        </div>
        <p-panel>
            <ng-template pTemplate="header">
                <span>Zone de filtrage</span>
            </ng-template>
            <form #searchForm="ngForm">
                <div class="row">
                    <div class="form-group col-sm-6">
                        <label for="field_pays">Pays</label>
                        <p-dropdown id="field_pays" [options]="paysList"
                                    [filter]="true" [showClear]="true"
                                    [autoDisplayFirst]="false"
                                    [(ngModel)]="criteria.paysId" name="pays"
                                    [style]="{width: '100%'}" appendTo="body">
                        </p-dropdown>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="field_denomination">Dénomination</label>
                        <input id="field_denomination" [(ngModel)]="criteria.denomination"
                               name="denomination" [style]="{width: '100%'}" pInputText/>
                    </div>
                </div>
                <div class="mt-20 pb-5">
                    <div style="float:right;">
                        <button type="button" label="Vérifier" pRipple pButton
                                icon="pi pi-search" class="btn-verif" (click)="searchData()">
                        </button>
                    </div>
                </div>
            </form>
        </p-panel>
        <div class="single-content">
            <h3>Organismes de garantie</h3>
            <p>
                Les Organismes de garantie sont des personnes morales qui apportent leur cautionnement dans l’émission
                des emprunts obligataires ou de tout autre titre faisant l’objet d’appel public à l’épargne. En cas de
                défaillance de l’émetteur, l’organisme couvrira ses échéances de remboursement ou engagements.
                <br><br>
                Sont habilitées, après approbation par L’AMF-UMOA, à se constituer garants dans le cadre des
                opérations d’appel public à l’épargne sur le marché financier de l’UMOA, les personnes morales suivantes
                :
            </p>
            <ul>
                <li>les organismes financiers internationaux et régionaux ;</li>
                <li>les institutions publiques d’aide au développement ou de coopération autorisées par traité,
                    convention ou accord.
                </li>
            </ul>
            <p>
                Par dérogation, les établissements de crédit exerçant dans l’UMOA sont habilités de plein droit à
                émettre des garanties admises dans le cadre des opérations d’appel public à l’épargne sur le marché
                financier de l’UMOA. Toutefois, l’acceptation par L’AMF-UMOA de leurs garanties pour une
                opération financière est subordonnée au respect, par lesdits établissements, du cahier des charges des
                Garants.
            </p>
            &nbsp;
            <app-data-table [dataList]="organismeDataList"
                            [cols]="colsActeurs"
                            [indexName]="acteurIndexName"
                            [addDetails]="false" [rows]="size"
                            [totalRecords]="acteurTotalRecords"
                            (paginate)="acuteurPageChange($event)">
            </app-data-table>
        </div>
        <div class="single-content">
            <h3>Agences de notation</h3>
            <p class="pb-2">
                Les Agences de notation sont des sociétés commerciales dont l'activité principale et régulière consiste
                à émettre des notations, qui sont des avis, émis par application d'un système de classification bien
                défini et bien établi prévoyant différentes catégories de notations, concernant la solvabilité d'une entité,
                la qualité de crédit d'un titre de créance ou d'une valeur mobilière représentative de créance.
            </p>
            <p>
                L'exercice de l'activité d'Agence de notation sur le marché financier régional de l'UMOA est subordonné
                à ['approbation préalable de l’AMF-UMOA.
            </p>
            &nbsp;
            <app-data-table [dataList]="agenceNotationDataList"
                            [cols]="colsActeurs" [addDetails]="false"
                            [indexName]="acteurIndexName"
                            [rows]="size" [totalRecords]="acteurTotalRecords"
                            (paginate)="acuteurPageChange($event)">
            </app-data-table>
        </div>
        <div class="single-content">
            <h3>Commissaires aux Comptes</h3>
            <p class="pb-2">
                Après leur nomination par une Structure Agréée et/ou une Société Cotées à la BRVM et avant l’exercice
                de leurs fonctions, les Commissaires aux Comptes titulaires ainsi que leurs suppléants doivent
                être approuvés par l’AMF-UMOA.
            </p>
            <p class="pb-2">
                Nul ne peut exercer les fonctions de commissaires aux comptes d'une Société cotée sur le marché
                financier régional de l’UMOA ou d'une structure agréée marché financier régional de l’UMOA,
                sans approbation de sa désignation par l’AMF-UMOA conformément à la procédure en vigueur.
            </p>
            <p class="pb-2">
                Les Commissaires aux Comptes doivent s’assurer de l’approbation de leurs mandats par l’AMF-UMOA avant
                l’exercice de leurs fonctions. Dans le cas contraire, ils commettent un manquement à la réglementation
                du marché financier régional de l’UMOA.
            </p>
            <p>
                Les Commissaires aux Comptes doivent obligatoirement être inscrits au tableau de l'Ordre des Experts
                Comptables de l’un des Etats membres de l 'UMOA et à jour de leurs obligations au niveau de cet ordre.
            </p>
            &nbsp;
            <app-data-table [dataList]="commissaireCompteDataList"
                            [cols]="colsCacs" [addDetails]="false"
                            [rows]="size" [totalRecords]="cacTotalRecords"
                            (paginate)="cacPageChange($event)"
                            [indexName]="cacIndexName">
            </app-data-table>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
