import { Component } from '@angular/core';

@Component({
    selector: 'app-mot-president',
    templateUrl: './mot-president.component.html',
    styleUrls: ['./mot-president.component.scss']
})
export class MotPresidentComponent {

    constructor() {
    }

}
