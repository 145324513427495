<div class="blog-area-two pt-20 pb-20">
    <div class=" card container p-2">
        <p-tabView styleClass="tabview-custom">
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-money-bill">&nbsp;</i>
                    <span>La bourse et le financement des entreprises.</span>
                </ng-template>
                <div class="terms-conditions-area pt-20">
                    <div class="container">
                        <youtube-player videoId="xcaQNIgF3R4"> </youtube-player>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
