import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';

@Component({
    selector: 'app-pub-communique',
    templateUrl: './pub-etude-analyse.component.html',
    styleUrls: ['./pub-etude-analyse.component.scss']
})
export class PubEtudeAnalyseComponent {
    title = 'MenuBar.Publication.Documentation.etudeAnalyse';

    constructor(private store: Store<AppState>) {
    }
}
