<!-- Footer Area -->
<footer class="footer-area">
    <div class="row">
        <div class="col-lg-2 col-md-6 pt-30">
            <div class="footer-widget">
                <div class="footer-logo">
                    <a routerLink="/"><img src="assets/img/footer-logo.png" alt="Images"></a>
                </div>
                <ul class="social-link mt-128 ps-sm-4">
                    <li><a href="https://www.facebook.com/AMFUMOA/" target="_blank"><i class="bx bxl-facebook"></i></a>
                    </li>
                    <li><a href="https://www.linkedin.com/in/amfumoa-5215791a5" target="_blank"><i
                            class="bx bxl-linkedin"></i></a></li>
                    <li><a href="https://twitter.com/AMF_UMOA" target="_blank"><i
                            class="bx bxl-twitter"></i></a></li>
                    <li><a href="https://www.youtube.com/@amf-umoa" target="_blank"><i class="bx bxl-youtube"></i></a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="footer-widget">
                <h3>{{'Acceuil.footer.liens' | translate}}</h3>

                <ul class="footer-list">
                    <li><a href="http://www.bceao.int/" target="_blank">Banque Centrale des Etats de l'Afrique de
                        l'Ouest (BCEAO)</a></li>
                    <li><a href="http://www.uemoa.int/" target="_blank">Commission de l'UEMOA</a></li>
                    <li><a href="http://www.boad.org/" target="_blank">Banque Ouest Africaine de Développement
                        (BOAD)</a></li>
                    <li><a href="http://www.brvm.org/" target="_blank">Bourse Régionale des Valeurs Mobilières (BRVM) et
                        Dépositaire Central Banque de Règlement (DC/BR)</a></li>
                </ul>

                <p-carousel [value]="links" [numVisible]="3" [numScroll]="1" [circular]="true"
                            [responsiveOptions]="responsiveOptions" [autoplayInterval]="3000">
                    <ng-template pTemplate="header">
                        <h5>Basic</h5>
                    </ng-template>
                    <ng-template let-link pTemplate="item">
                        <a [pTooltip]="link.desc" [href]="link.url" target="_blank">
                            <div class="product-item">
                                <div class="product-item-content">
                                    <div class="mb-3">
                                        <img [src]="link.logo" [alt]="link.desc" height="64"/>
                                    </div>
                                </div>
                            </div>
                        </a>
                        &nbsp;
                    </ng-template>
                </p-carousel>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 ps-lg-4">
            <div class="footer-widget row">
                <h3>
                    <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                       [routerLinkActiveOptions]="{exact: true}">
                        {{'MenuBar.Contacts.title' | translate}}
                    </a>
                </h3>
                <ul class="footer-list-two">
                    <li>
                        <i class="bx bx-location-plus"></i>
                        <span>Avenue Joseph ANOMA </span>
                        <span>01 BPM 1878 Abidjan 01 - Côte d’Ivoire</span>
                    </li>
                    <li>
                        <i class="bx bx-phone"></i>
                        <a href="tel:+2252720332304">(225) 27 20 33 23 04</a>
                    </li>
                    <li>
                        <i class="bx bx-phone"></i>
                        <a href="tel:+2252720332304">(225) 27 20 21 57 42</a>
                    </li>
                    <li>
                        <i class="bx bx-phone"></i>
                        <a href="tel:+2252720332304">(225) 27 20 31 56 20</a>
                    </li>
                    <li>
                        <i class="bx bxs-envelope"></i>
                        <a href="mailto:sg@amf-umoa.org">sg@amf-umoa.org</a>
                    </li>
                    <li>
                        <i class="bx bxs-envelope"></i>
                        <a href="mailto:communication@amf-umoa.org">communication@amf-umoa.org</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
<!-- Footer Area End -->

<!-- Copy-Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-text text-center" style="font-size: 13px; font-weight: 600">
            Copyright &copy; 2023 AMF-UMOA, Tous droits réservés
        </div>
    </div>
</div>
<!-- Copy-Right Area End -->
