<!-- Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{title | translate}}</h3>
            <ul>
                <li><a routerLink="/">{{'MenuBar.accueil' | translate}}</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li><a>{{title | translate}}</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->
<div class="terms-conditions-area pt-45">
    <div class="container">
        <div class="single-content text-justify">
            <h3>{{title| translate}}</h3>
        </div>

        <div class="single-content text-justify">
            <h6 class="pb-20">
                <i class="pi pi-chevron-right"></i>
                {{subTitle | translate}}
            </h6>

            <ng-container *ngFor="let data of operationBoursiereList">
                <app-stats-panel [id]="data.id" [title]="opBoursiere"
                                 chartType="line" [cols]="data.cols"
                                 [chartCols]="operationBoursiereChartCols"
                                 [debutAnnee]="data.debutAnnee"
                                 [data]="data.data" [finAnnee]="data.finAnnee"
                                 [totalRecords]="operarionTotalRecords" [size]="size"
                                 (paginate)="operationPageChange($event)">
                </app-stats-panel>
                &nbsp;
            </ng-container>
            <ng-container *ngFor="let data of indiceBoursierList">
                <app-stats-panel [id]="data.id" [title]="indiceBoursier"
                                 chartType="line" [cols]="data.cols"
                                 [chartCols]="indiceBoursierCapitalCols"
                                 [debutAnnee]="data.debutAnnee"
                                 [data]="data.data" [finAnnee]="data.finAnnee"
                                 [totalRecords]="indiceTotalRecords" [size]="size"
                                 (paginate)="indicePageChange($event)">
                </app-stats-panel>
                &nbsp;
            </ng-container>
            <ng-container *ngFor="let data of societeTitreList">
                <app-stats-panel [id]="data.id" [title]="societeTitre"
                                 chartType="line" [cols]="data.cols"
                                 [chartCols]="societeSocieteCols"
                                 [debutAnnee]="data.debutAnnee"
                                 [data]="data.data" [finAnnee]="data.finAnnee"
                                 [totalRecords]="societeTotalRecords" [size]="size"
                                 (paginate)="societePageChange($event)">
                </app-stats-panel>
                &nbsp;
            </ng-container>
        </div>
    </div>
</div>
