import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-epargnant-glossaire',
    templateUrl: './epargnant-glossaire.component.html',
    styleUrls: ['./epargnant-glossaire.component.scss']
})
export class EpargnantGlossaireComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
