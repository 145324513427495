<!-- Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Historique</h3>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li><a routerLink="/historique">Historique</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-45">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Historique</h3>
            <div>
                <span>La mise en place d’un marché financier organisé a été prévue par le Traité du 14 novembre 1973
                    instituant l’Union Monétaire Ouest Africaine (UMOA) qui regroupe huit (8) pays, à savoir :
                    le Bénin, le Burkina-Faso, la Côte d’Ivoire, la Guinée-Bissau, le Mali, le Niger, le Sénégal et le Togo.
                </span>
            </div>
            <div>
                <span>La création officielle de ce marché est intervenue le 3 juillet 1996 par la signature d’une
                    Convention portant création de l’Autorité des Marchés Financiers de l’Union Monétaire Ouest Africaine (AMF-UMOA).
                </span>
            </div>
        </div>

        <div class="single-content">
            <h6>L’historique de la mise en place du marché financier régional se présente comme ci-après :</h6>

            <table role="table" class="d-table table table-hover">
                <thead>
                    <tr role="row">
                        <th role="cell">Date</th>
                        <th role="cell">Evènements</th>
                    </tr>
                </thead>
                <tbody>
                    <tr role="row">
                        <td role="cell">03 juil. 1996</td>
                        <td role="cell">signature d’une Convention portant création du CREPMF</td>
                    </tr>
                    <tr role="row">
                        <td role="cell">16 déc. 1996</td>
                        <td role="cell">Création de la Bourse Régionale des Valeurs Mobilières (BRVM) et du Dépositaire Central/Banque de Règlement (DC/BR).</td>
                    </tr>
                    <tr role="row">
                        <td role="cell">20 oct. 1997</td>
                        <td role="cell">Investiture des premiers membres de l’AMF-UMOA.</td>
                    </tr>
                    <tr role="row">
                        <td role="cell">28 nov. 1997</td>
                        <td role="cell">Adoption par le Conseil des Ministres de l’UMOA du Règlement Général relatif à l’organisation, au fonctionnement et au contrôle du marché financier de l’UMOA.</td>
                    </tr>
                    <tr role="row">
                        <td role="cell">29 nov. 1997</td>
                        <td role="cell">Agrément de la Bourse Régionale des Valeurs Mobilières (BRVM) et du Dépositaire Central/Banque de Règlement (DC/BR).</td>
                    </tr>
                    <tr role="row">
                        <td role="cell">16 sept. 1998</td>
                        <td role="cell">Démarrage effectif des activités de la BRVM et du DC/BR.</td>
                    </tr>
                    <tr role="row">
                        <td role="cell">01 oct. 2021</td>
                        <td role="cell">Changement de dénomination de l’organe de CREMF en AMF-UMOA.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
