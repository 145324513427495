<div *ngIf="field?.invalid && (field?.dirty || field?.touched)"
     class="text-danger">

    <div *ngIf="field?.errors?.['required']">
        Ce champ est obligatoire.
    </div>
    <div *ngIf="field?.errors?.['email']">
        Le format du n'est pas bon.
    </div>

</div>
