<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-20">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Liste des documents à fournir pour une opération sur titre APE (Actions & Obligations)</h3>
        </div>

        <div class="single-content">
            <p-panel>
                <ng-template pTemplate="header">
                    <h3>Exigences réglementaires</h3>
                </ng-template>
                <table role="table" class="d-table table table-hover">
                    <thead>
                    <tr>
                        <th style="width:10%">N°</th>
                        <th style="width:90%">Documents</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>1°</td>
                        <td>Projet de Note d'information comprenant les mentions obligataires</td>
                    </tr>
                    <tr>
                        <td>2°</td>
                        <td>Attestation des Commissaires aux Comptes sur la note d'information</td>
                    </tr>
                    <tr>
                        <td>3°</td>
                        <td>Attestation du Responsable de la Note d’Information</td>
                    </tr>
                    <tr>
                        <td>4°</td>
                        <td>Mandat exclusif de la SGI chargée de l’opération</td>
                    </tr>
                    <tr>
                        <td>5°</td>
                        <td>Procès-verbal de l’instance ayant autorisé l'opération</td>
                    </tr>
                    <tr>
                        <td>6°</td>
                        <td>Statuts et actes constitutifs</td>
                    </tr>
                    <tr>
                        <td>7°</td>
                        <td>Etats financiers sociaux certifiés des trois (3) derniers exercices</td>
                    </tr>
                    <tr>
                        <td>8°</td>
                        <td>Comptes d'exploitation prévisionnels des cinq exercices suivants celui au titre duquel le
                            dépôt du dossier a été effectué
                        </td>
                    </tr>
                    <tr>
                        <td>9°</td>
                        <td>Identité et activité des actionnaires détenant plus de 10% du capital</td>
                    </tr>
                    <tr>
                        <td>10°</td>
                        <td>Dernier rapport disponible de la commission bancaire</td>
                    </tr>
                    <tr>
                        <td>11°</td>
                        <td>Etat de mise en œuvre des recommandations de la Commission Bancaires certifié par les CAC
                        </td>
                    </tr>
                    </tbody>
                </table>
            </p-panel>
            &nbsp;
            <p-panel>
                <ng-template pTemplate="header">
                    <h3>Informations complémentaires</h3>
                </ng-template>
                <table role="table" class="d-table table table-hover">
                    <thead>
                    <tr>
                        <th style="width:10%">N°</th>
                        <th style="width:90%">Documents</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>1</td>
                        <td>Notation Financière ou engagement de l’émetteur à se faire noter</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Chronogramme de réalisation de l'OPV</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Pan triennal de Développement de la Banque</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Rapport d'évaluation de la société et de ses actions</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Rapports d'activités ou de gestion de l'émetteur sur les 3 derniers exercices</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Note sur les modalités de souscription, d’allocation et de dénouement</td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Règlement de l'opération réservée au personnel</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>Décomposition du capital</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Ratio prudentiel 2013 2014 2015</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Rapports généraux et spéciaux des CAC des 3 derniers exercices</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>Procès-verbaux des assemblées générales ordinaires ayant approuvé les comptes sociaux de
                            l'émetteur au cours des 3 derniers exercices
                        </td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>Procès-verbaux des Conseil d'administration 2014-2015</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>Statuts et RCCM du principal actionnaire</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>Rapports d'activité du principal actionnaire</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>Rapport des CAC relatif aux cinquante(50) plus gros engagements de la banque en 2014 et
                            2015
                        </td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>Documents publicitaires (brochures, dépliants, encarts, spots, communiqués télé ou radio,
                            etc.)
                        </td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>Bulletin de souscription</td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>La liste des salariés de la banque</td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>l’état récapitulatif des engagements de l’émetteur 31 décembre 2014 et 2015</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>le rapport du Conseil Juridique sur la conformité et la légalité de l’opération projetée, le
                            cas échéant
                        </td>
                    </tr>
                    <tr>
                        <td>21</td>
                        <td>l’engagement formel de l’arrangeur à centraliser et à transmettre quotidiennement au CREPMF
                            l’état des souscriptions aux guichets des membres du syndicat de placement. Cet engagement
                            peut être matérialisé dans la note d’information
                        </td>
                    </tr>
                    <tr>
                        <td>22</td>
                        <td>Indication sur le compartiment de Cotation</td>
                    </tr>
                    </tbody>
                </table>
            </p-panel>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
