import { HttpParams } from '@angular/common/http';
import { StatsDataList } from '../../../store/entity/entity.model';
import { MessageService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { SendFluxRss } from '../../../store/actions/jupiter-portail.actions';

export enum CategorieActualite {
    Communique = 'Communique',
    Reglement = 'Reglement',
    Decision = 'Decision',
    Sanctions = 'Sanctions',
    Instruction = 'Instruction',
    Consultation = 'Consultation',
    Circulaire = 'Circulaire',
    Rapport = 'Rapport',
    Actualite = 'Actualite'
}

export enum ELangue {
    en = 'en',
    fr = 'fr',
    pt = 'pt'
}

export interface Status {
    status?: StatusEnum;
    message?: string;
    reset?: boolean;
    params?: any;
}

export enum StatusEnum {
    error = 'error',
    success = 'success',
    warning = 'warn'
}

export const FilterItems = {
    agrement: 'Agréments / Habilitations',
    tarification: 'Tarification (Homologation)',
    redevance: 'Redevances',
    instruction: 'Introductions en bourse',
    concervation: 'Conservations',
    opcvm: 'OPCVM',
    diffusion: 'Diffusion d\'Informations',
    sanction: 'Sanctions',
    esv: 'ESV',
    operation: 'Opérations Financières',
    finance: 'Finance Islamique',
    Autre: 'Autres'
};

export function createRequestOption(req?: any): HttpParams {
    let options: HttpParams = new HttpParams();
    if (req) {
        Object.keys(req).forEach(key => {
            if (key !== 'sort' && (req[key] || req[key] === 0)) {
                if (typeof req[key] === 'string') {
                    if (req[key].trim().length > 0) {
                        options = options.set(key, req[key]);
                    }
                } else {
                    options = options.set(key, req[key]);
                }
            }
        });

        if (req.sort) {
            req.sort.forEach((val: string) => {
                options = options.append('sort', val);
            });
        }
    }

    return options;
}

export function splitData(data: any, id: string, cols): Array<StatsDataList> {
    let statsDataList = [];

    const dataSize = data.length;
    if (dataSize > 10) {
        let splitSize;

        if (dataSize % 10 === 0) {
            splitSize = dataSize / 10;
        } else {
            splitSize = (dataSize / 10) + 1;
        }

        for (let i = 1; i <= splitSize; i++) {
            const tmpData = data.splice(0, 10);
            statsDataList.push({
                id: `${id}_${i}`,
                cols,
                data: tmpData,
                debutAnnee: tmpData[0].annee,
                finAnnee: tmpData[tmpData.length - 1].annee
            });
        }
    } else {
        statsDataList = [
            {
                id: `${id}_1`,
                cols,
                data,
                debutAnnee: data[0].annee,
                finAnnee: data[data.length - 1].annee
            }
        ];
    }

    return statsDataList;
}

export function pivotTable(data: any, id: string, cols): Array<StatsDataList> {
    return [
        {
            id: `${id}_1`,
            cols,
            data,
            debutAnnee: data[0].annee,
            finAnnee: data[data.length - 1].annee
        }
    ];
}

export const epruntCols = [
    {field: 'etats', header: 'Etats'},
    {field: 'organisations', header: 'Organisations Régionales et Int.'},
    {field: 'secteurPrive', header: 'Secteur privé'},
    {field: 'entreprisesPubliques', header: 'Entreprises publiques'}
];

export const titreCapitalCols = [
    {field: 'opv', header: 'OPV'},
    {field: 'opa', header: 'OPA'},
    {field: 'ope', header: 'OPE'},
    {field: 'pe', header: 'PE'},
    {field: 'opr', header: 'OPR'}
];

export const epargneCollectCols = [
    {field: 'fctcEtat', header: 'FCTC ETAT'},
    {field: 'fctcSecteurPrive', header: 'FCTC SECTEUR PRIVE'}
];

export const NOMBRE_ACTEUR_CHART_COLS = [
    {field: 'bourse', header: 'Bourse', borderColor: '#f60303'},
    {field: 'central', header: 'Déposit. Centr./Banq. de Règl.', borderColor: '#e96f12'},
    {field: 'societeGestion', header: 'Soc. Gest. Interm.', borderColor: '#045547'},
    {field: 'societeGestionPatrimoine', header: 'Soc. Gest. Patri.', borderColor: '#41066f'},
    {field: 'societeGestionOpcvm', header: 'Soc. Gest. OPCVM', borderColor: '#0dcaf0'},
    {field: 'sicav', header: 'SICAV', borderColor: '#714949'},
    {field: 'fcp', header: 'FCP', borderColor: '#e9c912'},
    {field: 'fctc', header: 'FCTC', borderColor: '#8db4dd'},
    {field: 'bnqTeneurCompteConserv', header: 'Banq. Teneur Cpt. Conserv.', borderColor: '#0952a1'},
    {field: 'apporteurAffaires', header: 'Apport. Aff.', borderColor: 'rgb(189,211,196)'},
    {field: 'demarcheur', header: 'Démarcheur', borderColor: 'rgb(129,226,161)'},
    {field: 'agenceNotation', header: 'Agence notation', borderColor: 'rgb(7,7,7)'},
    {field: 'garants', header: 'Garants', borderColor: 'rgb(194,189,211)'}
];

export const nbActeurCols = [
    {field: 'bourse', header: 'Bourse'},
    {field: 'central', header: 'Dépositaire Central/Banque de Règlement'},
    {field: 'societeGestion', header: 'Sociétés de Gestion et d\'Intermédiation'},
    {field: 'societeGestionPatrimoine', header: 'Sociétés de Gestion de Patrimoine'},
    {field: 'societeGestionOpcvm', header: 'Sociétés de Gestion d\'OPCVM'},
    {field: 'sicav', header: 'SICAV'},
    {field: 'fcp', header: 'FCP'},
    {field: 'fctc', header: 'FCTC'},
    {field: 'bnqTeneurCompteConserv', header: 'Banques Teneurs de Comptes Conservateurs'},
    {field: 'apporteurAffaires', header: 'Apporteurs d\'Affaires'},
    {field: 'demarcheur', header: 'Démarcheur'},
    {field: 'agenceNotation', header: 'Agence de notation'},
    {field: 'garants', header: 'Garants'}
];

export const opBoursierCols = [
    {field: 'volumeTransaction', header: 'Volume des transactions'},
    {field: 'valeurTransaction', header: 'Valeurs des transactions (milliards de FCFA)'},
    {field: 'capitalisation', header: 'Capitalisation Boursière (milliards de FCFA)'},
    {field: 'marcheAction', header: 'Marché des actions (milliards de FCFA)'},
    {field: 'marcheObligatoire', header: 'Marché des obligations (milliards de FCFA)'}
];

export const indiceBoursierCols = [
    {field: 'indiceBrvm10', header: 'Indice BRVM 10'},
    {field: 'indiceBrvmComposit', header: 'Indice BRVM Composite'}
];

export const societeTitreCols = [
    {field: 'nombreSociete', header: 'Nombre de sociétés cotées'},
    {field: 'nombreLigne', header: 'Nombre de lignes obligataires'}
];

export const EMPRUNT_CHART_COLS = [
    {field: 'etats', header: 'Etats', borderColor: '#f60303'},
    {field: 'organisations', header: 'Org. Rég. et Int.', borderColor: '#e96f12'},
    {field: 'secteurPrive', header: 'Sect. privé', borderColor: '#045547'},
    {field: 'entreprisesPubliques', header: 'Ent. pub.', borderColor: '#41066f'}
];

export const TITRE_CAPITAL_CHART_COLS = [
    {field: 'opa', header: 'OPA', borderColor: '#f60303'},
    {field: 'ope', header: 'OPE', borderColor: '#e96f12'},
    {field: 'opr', header: 'OPR', borderColor: '#045547'},
    {field: 'opv', header: 'OPV', borderColor: '#41066f'},
    {field: 'pe', header: 'PE', borderColor: '#0dcaf0'}
];

export const EPARGNE_COLLECT_CHART_COLS = [
    {field: 'fctcEtat', header: 'FCTC ETAT', borderColor: '#f60303'},
    {field: 'fctcSecteurPrive', header: 'FCTC SECTEUR PRIVE', borderColor: '#045547'}
];

export const OPERATION_BOURSIERE_CHART_COLS = [
    {field: 'volumeTransaction', header: 'Volume. transaction', borderColor: '#f60303'},
    {field: 'valeurTransaction', header: 'Valeur transaction', borderColor: '#e96f12'},
    {field: 'capitalisation', header: 'Capitalisation boursière', borderColor: '#045547'},
    {field: 'marcheAction', header: 'Marché actions', borderColor: '#41066f'},
    {field: 'marcheObligatoire', header: 'Marché obligations', borderColor: '#0dcaf0'}
];

export const INDICE_BOURSIERE_CHART_COLS = [
    {field: 'indiceBrvm10', header: 'BRVM', borderColor: '#045547'},
    {field: 'indiceBrvmComposit', header: 'BRVM Composite', borderColor: '#e96f12'}
];

export const SOCIETE_GESTION_CHART_COLS = [
    {field: 'nombreSociete', header: 'NB sociétés côtées', borderColor: '#0dcaf0'},
    {field: 'nombreLigne', header: 'NB lignes. obligataires', borderColor: '#e96f12'}
];

export enum ETypeOffre {
    EMPLOIS = 'EMPLOIS',
    MARCHE = 'MARCHE'
}

export enum ETypeProcedure {
    REQUETE = 'demande',
    PLAINTE = 'plainte',
    MESSAGE = 'message'
}

export enum ETypeVerification {
    AGREMENT = 'AGREMENT',
    CARTEPRO = 'CARTEPRO'
}

export const codeNatureList = [
    {
        code: 'APE',
        title: 'Emprunt Obligataire par Appel Public à l\'Epargne',
        desc: '<p>Sont réputés faire appel public à l\'épargne, les Etats, les sociétés ou toute autre entité : </p>\n' +
            '<ul>\n' +
            '<li>dont les titres sont disséminés au travers d\'un cercle de cent personnes au moins, n\'ayant aucun lien juridique entre elles,</li>\n' +
            '<li>qui, pour offrir au public de l\'UMOA des produits de placement, ont recours à des procédés quelconques de sollicitation du public, au titre desquels figurent notamment la publicité et le démarchage,</li>\n' +
            '<li>dont les titres sont inscrits à la cote de la Bourse Régionale des Valeurs Mobilières.</li>\n' +
            '</ul>'
    },
    {
        code: 'EOP',
        title: 'Emprunt Obligataire Privé',
        desc: '<p>L\'emprunt obligataire privé sur le marché financier de l\'UMOA est constitué par :</p>' +
            '<ul>' +
            '<li>L\'émission d\'obligations réalisées sans publicité et dont les titres ne feront pas l\'objet d\'inscription à la cote de la Bourse Régional des Valeurs Mobilières;</li>' +
            '<li>L\'émission d\'obligations placées au travers d\'un cercle d\'au plus cent (100) investisseurs qualifiés agissant pour leur propre compte. Un investisseur qualifié est une personne morale à même d\'appréhender les mérites et les risques inhérents aux opérations sur les instruments financiers;</li>' +
            '<li>L\'émission d\'obligations dont le nominal est de F CFA 50 millions.</li>' +
            '</ul>' +
            '<p>Les conditions sus énumérées sont cumulatives.</p>'
    },
    {
        code: 'OPA',
        title: 'Offre Publique d\'Achat',
        desc: '<p>L\'Offre Publique d\'Achat "OPA" s\'entend de la procédure par laquelle une personne physique ou morale fait connaître publiquement qu\'il s\'engage à acquérir à un prix déterminé une quantité de titres d\'un autre émetteur.</p>'
    },
    {
        code: 'OPR',
        title: 'Offre Publique de Retrait',
        desc: '<p>L\'Offre Publique de Retrait, ou "OPR" s\'entend d\'une offre publique d\'achat qui vise les actionnaires minoritaires d\'une société, avec l\'objectif avoué de faire procéder par les actionnaires majoritaires, à la radiation des titres de cet émetteur de la cote.</p>'
    },
    {
        code: 'OPV',
        title: 'Offre Publique de Vente',
        desc: '<p>L\'Offre Publique de Vente, "OPV", est la procédure par laquelle un (ou plusieurs) détenteur (s) de titres fait connaître publiquement son intention de céder une quantité déterminée de titres à un prix ferme et définitif.</p>'
    },
    {
        code: 'OPE',
        title: 'Offre Publique d\'Echange',
        desc: '<p>L\'Offre Publique d\'Echange ou "OPE" ne diffère de la procédure d\'OPA qu\'en ce que les titres visés par l\'initiateur de l\'offre sont payés en d\'autres titres et non en numéraire.</p>'
    },
    {
        code: 'PE',
        title: 'Placement Etranger',
        desc: '<p>C’est le placement de titres par une entreprise auprès d’un public hors de son territoire siège. Sur le Marché FR les placements étrangers sont régis par les articles 174 – 175 – 176 du Règlement Général.</p>'
    },
    {
        code: 'PPA',
        title: 'Placement Privé d\'Actions',
        desc: ''
    },
    {
        code: 'SO',
        title: 'Stock Option',
        desc: '<p>Les stocks options sont des options d\'achat ou de souscription d\'action à un prix fixe distribué généralement aux gestionnaires d\'une entreprise afin de leur donner un intérêt direct à l\'accroissement de la valeur de l\'entreprise. L\'octroi de ces titres peut donc être analysé comme un moyen de résoudre les conflits d\'agence entre actionnaires et gestionnaires.</p>'
    }
];

export function showToast(severity: string, summary: string, detail: string, messageService: MessageService) {
    messageService.add({severity, summary, detail});
}

export function sendFluxRss(email: string, store: Store<AppState>) {
    store.dispatch(SendFluxRss({email}));
}

export const categorieLabels = {
    Communique: 'Communiqués',
    Reglement: 'Règlements',
    Decision: 'Décisions',
    Sanctions: 'Sanctions',
    Instruction: 'Instructions',
    Consultation: 'Consultations',
    Circulaire: 'Circulaires',
    Rapport: 'Rapports',
    Actualite: 'Actualités'
};

export enum ETableName {
    acteur = 'acteur',
    fcp = 'fcp',
    cac = 'cac',
    emetteur = 'emetteur',
    actualite = 'actualite',
    operation = 'operation',
    offre = 'offre'
}

export interface Pagination {
    first: number; // Index of the first record
    rows: number; // Number of rows to display in new page
    page: number; // Index of the new page
    pageCount: number; // Total number of pages
}

export const emailPattern = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';

export const AcceptFileTypeList = [
    {label: 'PDF', value: 'application/pdf', img: '../../../../../assets/img/download-pdf.png'},
    {label: 'CSV', value: 'text/csv', img: '../../../../../assets/img/download-csv.png'},
    {
        label: 'WORDS',
        value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword',
        img: '../../../../../assets/img/download-docx.png'

    },
    {
        label: 'EXCEL',
        value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
        img: '../../../../../assets/img/download-xlsx.png'
    }
];


