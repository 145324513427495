import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    EMPRUNT_CHART_COLS,
    EPARGNE_COLLECT_CHART_COLS,
    epargneCollectCols,
    epruntCols,
    Pagination,
    pivotTable,
    TITRE_CAPITAL_CHART_COLS,
    titreCapitalCols
} from '../../../common/utils/utils';
import { StatsDataList } from '../../../../store/entity/entity.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { Subject, takeUntil } from 'rxjs';
import {
    selectEmpruntObligatoireList,
    selectEpargneCollectiveList,
    selectOperationTitreList
} from '../../../../store/selectors/jupiter-portail.selectors';
import {
    FetchEmpruntObligataireList,
    FetchEpargneCollectiveList,
    FetchOperationTitreList
} from '../../../../store/actions/jupiter-portail.actions';

@Component({
    selector: 'app-stats-marche-primaire',
    templateUrl: './stats-marche-primaire.component.html',
    styleUrls: ['./stats-marche-primaire.component.scss']
})
export class StatsMarchePrimaireComponent implements OnInit, OnDestroy {
    title = 'MenuBar.Publication.StatsMarche.marchePrimaire.pageTitle';
    subTitle = 'MenuBar.Publication.StatsMarche.marchePrimaire.subTitle';
    empruntObligatoire = 'MenuBar.Publication.StatsMarche.marchePrimaire.panelTitles.empObligatoire';
    titreCapital = 'MenuBar.Publication.StatsMarche.marchePrimaire.panelTitles.titreCapital';
    epargneCollective = 'MenuBar.Publication.StatsMarche.marchePrimaire.panelTitles.epargneCollective';

    empruntObligatoireList: Array<StatsDataList>;
    titreCapitalList: Array<StatsDataList>;
    epargneCollectiveList: Array<StatsDataList>;

    empruntChartCols = EMPRUNT_CHART_COLS;
    titreCapitalCols = TITRE_CAPITAL_CHART_COLS;
    epargneCollectiveCols = EPARGNE_COLLECT_CHART_COLS;

    destroy$ = new Subject<boolean>();
    empruntTotalRecords = 0;
    epargneTotalRecords = 0;
    operationTotalRecords = 0;
    page = 0;
    size = 10;

    constructor(private store: Store<AppState>) {
        this.store.dispatch(FetchEmpruntObligataireList({size: this.size, page: this.page}));
        this.store.dispatch(FetchEpargneCollectiveList({size: this.size, page: this.page}));
        this.store.dispatch(FetchOperationTitreList({size: this.size, page: this.page}));
    }

    ngOnInit() {
        this.loadEmpruntObligatoires();
        this.loadTitreCapitals();
        this.loadEpargneCollectives();
    }

    loadEmpruntObligatoires() {
        this.store.pipe(select(selectEmpruntObligatoireList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet && resultSet.data && resultSet.data.length > 0) {
                        this.empruntTotalRecords = resultSet.totalRecord;
                        const dataList = [...resultSet.data].sort((a, b) => a.annee - b.annee);
                        this.empruntObligatoireList = pivotTable(dataList, 'emprunt_obligatoire', epruntCols);
                    }
                }
            });
    }

    loadTitreCapitals() {
        this.store.pipe(select(selectOperationTitreList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet && resultSet.data && resultSet.data.length > 0) {
                        this.operationTotalRecords = resultSet.totalRecord;
                        const dataList = [...resultSet.data].sort((a, b) => a.annee - b.annee);
                        this.titreCapitalList = pivotTable(dataList, 'titre_capital', titreCapitalCols);
                    }
                }
            });
    }

    loadEpargneCollectives() {
        this.store.pipe(select(selectEpargneCollectiveList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet && resultSet.data && resultSet.data.length > 0) {
                        this.epargneTotalRecords = resultSet.totalRecord;
                        const dataList = [...resultSet.data].sort((a, b) => a.annee - b.annee);
                        this.epargneCollectiveList = pivotTable(dataList, 'epargne_collective', epargneCollectCols);
                    }
                }
            });
    }

    titrePageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;

            this.store.dispatch(FetchOperationTitreList({size: this.size, page: this.page}));
        }
    }

    epargnePageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;

            this.store.dispatch(FetchEpargneCollectiveList({size: this.size, page: this.page}));
        }
    }

    empruntPageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;

            this.store.dispatch(FetchEmpruntObligataireList({size: this.size, page: this.page}));
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
