import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CategorieActualite } from '../../utils/utils';
import { Subject, takeUntil } from 'rxjs';
import { Actualite } from '../../../../store/entity/entity.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { FetchActualityList } from '../../../../store/actions/jupiter-portail.actions';
import { selectActualityList } from '../../../../store/selectors/jupiter-portail.selectors';

@Component({
    selector: 'app-table-data',
    templateUrl: './table-data.component.html',
    styleUrls: ['./table-data.component.scss']
})
export class TableDataComponent implements OnInit, OnDestroy {
    @Input() title: string;
    @Input() categorie: CategorieActualite;
    @Input() paragraphe: string;
    @Input() cols: Array<any>;

    destroy$ = new Subject<boolean>();
    actualities: Array<Actualite>;

    constructor(private store: Store<AppState>) {
        this.loadData();
    }

    ngOnInit(): void {
        this.store.pipe(select(selectActualityList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.actualities = [...resultSet.data];
                    }
                }
            });
    }

    loadData() {
        this.store.dispatch(FetchActualityList({
            categorie: CategorieActualite.Reglement
        }));
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
