<!-- Inner Banner -->
<div class="inner-banner inner-bg4">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Détails Actualité</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li>Détails Actualité</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Blog Details Area -->
<div class="blog-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-article">
                    <div class="blog-article-img">
                        <img [src]="jupiterPortailService.buildUrl('photoActualite', elasticIndexName.actualite, actuality?.id, 'photoActualite')"
                             alt="">
                    </div>

                    <div class="article-content">
                        <h2>{{actuality?.titre}}</h2>
                        <p>{{actuality?.resume}}</p>

                        <blockquote class="blockquote">
                            <div class="row">
                                <div class="col-sm-11" [ngClass]="{'col-sm-12': !actuality?.doc}">
                                    <p>{{actuality?.texte}}</p>
                                </div>
                                <div class="col-sm-1 img-card download-cursor p-button-link" *ngIf="actuality?.doc">
                                    <img src="assets/img/download-pdf.png" alt="" width="32" height="32" (click)="downloadPdfFile()">
                                </div>
                            </div>
                        </blockquote>
                        <p>{{actuality.date | date}}</p>
                    </div>

                    <div class="another-content">
                        <div class="content-img">
                            <div id="carouselExampleCaptions" class="banner-content banner-content-three carousel slide"
                                 data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item" [ngClass]="{'active': i===0}"
                                         *ngFor="let image of actuality.photoGalleries; let i=index">
                                        <img [src]="sanitize('data:image/jpg;base64,' + image)"
                                             class="d-block w-100" alt="...">
                                    </div>
                                </div>
                                <button class="carousel-control-prev carousel" type="button"
                                        data-bs-target="#carouselExampleCaptions"
                                        data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next carousel" type="button"
                                        data-bs-target="#carouselExampleCaptions"
                                        data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="blog-article-share">
                        <app-actua-social-link [actualite]="actuality"></app-actua-social-link>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <app-side-bar-categorie [destroy$]="destroy$" [isFromDetail]="true"></app-side-bar-categorie>
            </div>
        </div>
    </div>
</div>
<!-- Blog Details Area End -->
