import { Component, OnDestroy, OnInit } from '@angular/core';
import { StatsDataList } from '../../../../store/entity/entity.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { Subject, takeUntil } from 'rxjs';
import {
    FetchIndiceBoursierList,
    FetchOperationBoursiereList,
    FetchSocieteCoteList
} from '../../../../store/actions/jupiter-portail.actions';
import {
    selectIndiceBoursierList,
    selectOperationBoursiereList,
    selectSocieteCoteList
} from '../../../../store/selectors/jupiter-portail.selectors';
import {
    EPARGNE_COLLECT_CHART_COLS, INDICE_BOURSIERE_CHART_COLS,
    indiceBoursierCols,
    opBoursierCols,
    OPERATION_BOURSIERE_CHART_COLS,
    Pagination,
    pivotTable, SOCIETE_GESTION_CHART_COLS,
    societeTitreCols,
    TITRE_CAPITAL_CHART_COLS
} from '../../../common/utils/utils';

@Component({
    selector: 'app-stats-marche-secondaire',
    templateUrl: './stats-marche-secondaire.component.html',
    styleUrls: ['./stats-marche-secondaire.component.scss']
})
export class StatsMarcheSecondaireComponent implements OnInit, OnDestroy {
    title = 'MenuBar.Publication.StatsMarche.marcheSecondaire.pageTitle';
    subTitle = 'MenuBar.Publication.StatsMarche.marcheSecondaire.subTitle';
    opBoursiere = 'MenuBar.Publication.StatsMarche.marcheSecondaire.panelTitles.opBoursiere';
    indiceBoursier = 'MenuBar.Publication.StatsMarche.marcheSecondaire.panelTitles.indiceBoursier';
    societeTitre = 'MenuBar.Publication.StatsMarche.marcheSecondaire.panelTitles.societeTitre';

    operationBoursiereList: Array<StatsDataList>;
    indiceBoursierList: Array<StatsDataList>;
    societeTitreList: Array<StatsDataList>;

    operationBoursiereChartCols = OPERATION_BOURSIERE_CHART_COLS;
    indiceBoursierCapitalCols = INDICE_BOURSIERE_CHART_COLS;
    societeSocieteCols = SOCIETE_GESTION_CHART_COLS;

    destroy$ = new Subject<boolean>();
    operarionTotalRecords = 0;
    indiceTotalRecords = 0;
    societeTotalRecords = 0;
    page = 0;
    size = 10;

    constructor(private store: Store<AppState>) {
        this.store.dispatch(FetchOperationBoursiereList({size: this.size, page: this.page}));
        this.store.dispatch(FetchIndiceBoursierList({size: this.size, page: this.page}));
        this.store.dispatch(FetchSocieteCoteList({size: this.size, page: this.page}));
    }

    ngOnInit() {
        this.loadOperationBoursieres();
        this.loadIndiceBoursiers();
        this.loadSocieteTitres();
    }

    loadOperationBoursieres() {
        this.store.pipe(select(selectOperationBoursiereList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet && resultSet.data && resultSet.data.length > 0) {
                        this.operarionTotalRecords = resultSet.totalRecord;
                        const dataList = [...resultSet.data].sort((a, b) => a.annee - b.annee);
                        this.operationBoursiereList = pivotTable(dataList, 'operation_boursiere', opBoursierCols);
                    }
                }
            });
    }

    loadIndiceBoursiers() {
        this.store.pipe(select(selectIndiceBoursierList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet && resultSet.data && resultSet.data.length > 0) {
                        this.indiceTotalRecords = resultSet.totalRecord;
                        const dataList = [...resultSet.data].sort((a, b) => a.annee - b.annee);
                        this.indiceBoursierList = pivotTable(dataList, 'indice_boursier', indiceBoursierCols);
                    }
                }
            });
    }

    loadSocieteTitres() {
        this.store.pipe(select(selectSocieteCoteList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet && resultSet.data && resultSet.data.length > 0) {
                        this.societeTotalRecords = resultSet.totalRecord;
                        const dataList = [...resultSet.data].sort((a, b) => a.annee - b.annee);
                        this.societeTitreList = pivotTable(dataList, 'societe_titre', societeTitreCols);
                    }
                }
            });
    }

    operationPageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;
            this.store.dispatch(FetchOperationBoursiereList({size: this.size, page: this.page}));
        }
    }

    indicePageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;
            this.store.dispatch(FetchIndiceBoursierList({size: this.size, page: this.page}));
        }
    }

    societePageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;
            this.store.dispatch(FetchSocieteCoteList({size: this.size, page: this.page}));
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
