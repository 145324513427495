<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-20">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Comment se faire agréer sur le Marché Financier Régional ?</h3>
            <p>
                Les acteurs qui concourent au fonctionnement du marché financier de l’UMOA sont regroupés en deux pôles
            </p>
            <ul>
                <li>un pôle public constitué par l’AMF-UMOA, organe représentant les Etats de l’Union,</li>
                <li>un pôle privé qui regroupe les structures de gestion du marché boursier et les intervenants
                    commerciaux.
                </li>
            </ul>
            <p>l’AMF-UMOA est l’organe de tutelle du marché financier. A ce titre, il a entre autres
                attributions, l’habilitation et le contrôle des intervenants sur le marché financier régional.</p>
        </div>

        <div class="single-content">
            <p-accordion>
                <p-accordionTab
                        header="A- Identification des acteurs du marché financier régional"
                        [selected]="true">
                    <div class="single-content text-justify">
                        <h3>Les structures centrales du marché</h3>
                        <ul>
                            <li>la Bourse Régionale des Valeurs Mobilières,</li>
                            <li>le Dépositaire Central / Banque de Règlement.</li>
                        </ul>
                        <h3>Les intervenants commerciaux</h3>
                        <ul>
                            <li>les Sociétés de Gestion et d’Intermédiation (SGI);</li>
                            <li>les Société de Gestion d’OPC composées des Sociétés de Gestion d’OPCVM,
                                des Sociétés de Gestion de Fonds Commun de Placement d’Enterprise,
                                des Sociétés de Gestion d’OPC à Risque, des Sociétés de Gestion d'OPC de
                                Titrisation de Créances et des Sociétés de Gestion d'OPC immobiliers;
                            </li>
                            <li>les Organismes de Placement Collectif  constitués des OPCVM ( SICAV et FCP)
                                et les Fonds d’Investissement Alternatifs (FIA)</li>
                            <li>Les Fonds Communs de Titrisation de Créances; </li>
                            <li>les Sociétés de Gestion de Patrimoine (SGP);</li>
                            <li>les Banques Teneurs de Comptes et Compensateurs BTC/C);</li>
                            <li>les Conseils en Investissements Boursiers (CIB);</li>
                            <li>les Apporteurs d’Affaires;</li>
                            <li>les Démarcheurs;</li>
                            <li>les Listing Sponsors.</li>
                        </ul>
                        <p>
                            Chaque acteur intervient dans un cadre bien défini conformément à sa catégorie.
                            L’article 9 du Règlement Général interdit à toute structure ou à tout intervenant
                            commercial agréé par L’AMF-UMOA de laisser entendre qu’il appartient à une catégorie autre
                            que celle au titre de laquelle il a obtenu son agrément ou de créer une confusion sur ce point.
                            Il est donc important, avant de saisir L’AMF-UMOA d’une demande d’agrément, de connaître
                            le domaine d’activité du type d’acteur auquel l’on s’intéresse.
                        </p>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="B- les conditions d’agrément">
                    <div class="single-content text-justify">
                        <h3>Personnes morales</h3>
                        <p>Pour ces types d’acteurs, il est généralement requis de fournir les documents et
                            renseignements suivants :</p>
                        <ul>
                            <li>les statuts,</li>
                            <li>le montant, la répartition du capital et l’identité des principaux actionnaires,</li>
                            <li>la composition des organes sociaux,</li>
                            <li>la liste des dirigeants sociaux et leurs casiers judiciaires,</li>
                            <li>les moyens techniques et humains dont est dotée la société.</li>
                        </ul>
                        <p>
                            Ils doivent disposer, en permanence, de moyens organisationnels et humains nécessaires
                            à l'exercice de l’activité visé et doivent présenter des garanties suffisantes notamment
                            en ce qui concerne leur organisation, les moyens techniques et financiers ainsi
                            que l'expérience de leurs dirigeants.
                        </p>

                        <h3>Personnes physiques</h3>
                        <p>
                            Peuvent intervenir sur le marché financier en qualité d’acteur personnes physiques, les
                            apporteurs d’affaires, les Conseils en Investissements Boursiers, les Démarcheurs. Ceux-ci
                            doivent transmettre à l’AMF-UMOA, dans le cadre de leur demande d’agrément, les
                            documents suivants :
                        </p>
                        <ul>
                            <li>un extrait de casier judiciaire;</li>
                            <li>un curriculum vitae;</li>
                            <li>des garanties (caution bancaire et une police d'assurance responsabilité civile professionnelle).</li>
                        </ul>
                        <p>Ils doivent justifier d’une expérience suffisante dans le domaine d’activité visé.</p>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="C- L’exigence de cartes professionnelles">
                    <div class="single-content text-justify">
                        <p class="pb-2">
                            Selon les dispositions de l’article 1 de l’Instruction n° 41/2009 (REVISEE) relative à
                            la délivrance des cartes professionnelles, Les personnes physiques travaillant auprès
                            des intervenants commerciaux ou exerçant à titre individuel sur le marché financier
                            régional de l'Union Monétaire Ouest Africaine, sont soumises à la détention de
                            cartes professionnelles délivrées par l’AMF-UMOA.
                        </p>
                        <p class="pb-2">
                            La détention de cartes professionnelles est obligatoire pour les personnes appelées à
                            être en contact avec la clientèle ou travaillant dans des domaines nécessitant une
                            attention particulière au regard des exigences du métier et de la gouvernance d’entreprise.
                        </p>
                        <p>
                            Pour l'obtention des cartes professionnelles, les intervenants commerciaux dont relèvent
                            les personnes concernées transmettent à l’AMF-UMOA un dossier comprenant :
                        </p>
                        <ul>
                            <li>un extrait du casier judiciaire datant de mains de trois mois (non applicable en
                                cas de changement de fonction chez le même intervenant commercial);</li>
                            <li>un curriculum vitae actualisé et certifie sincère; </li>
                            <li>une attestation d'aptitude professionnelle (délivrée par le Dirigeant ou par le
                                Président du Conseil d’Administration, lorsqu'il s'agit du Dirigeant de
                                l'intervenant commercial) ; le procès-verbal de nomination du Dirigeant; </li>
                            <li>deux (02) photos d'identité (non applicable en cas de changement de fonction
                                chez le même intervenant commercial. </li>
                        </ul>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="D- les frais d’agrément">
                    <div class="single-content text-justify">
                        <p>
                            Les agréments octroyés aux acteurs du marché financier donnent lieu au paiement, de
                            commissions à l’AMF-UMOA. Ces commissions se présentent comme suit :
                        </p>
                        <table role="table" class="d-table table table-hover">
                            <thead>
                            <tr>
                                <th [style.width]="'80%'">FRAIS POUR HABILITATION, AGREMENT ET APPROBATION</th>
                                <th [style.width]="'20%'">MONTANT</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Bourse</td>
                                <td>50 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>Dépositaire Central, Banque de Règlement</td>
                                <td>30 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>Organismes de garantie</td>
                                <td>15 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>SGI et BTCC</td>
                                <td> 10 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>Agences de notation</td>
                                <td> 5 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>Société de Gestion de Patrimoine, d’OPCVM ou de FCTC</td>
                                <td>3 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>SICAV, SICAR</td>
                                <td>3 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>Autres OPC (FCP, FCTC, FCPR)</td>
                                <td>2 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>Apporteurs d’Affaires/Conseils en Investissements Boursiers / Démarcheurs (personnes
                                    morales)
                                </td>
                                <td>1 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>Apporteurs d’Affaires/Conseils en Investissements Boursiers / Démarcheurs (personnes
                                    physiques)
                                </td>
                                <td>500 000 FCFA</td>
                            </tr>
                            </tbody>
                        </table>
                        &nbsp;
                        <table role="table" class="d-table table table-hover">
                            <thead>
                            <tr>
                                <th [style.width]="'80%'">REDEVANCE ANNUELLE</th>
                                <th [style.width]="'20%'">MONTANT</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Bourse</td>
                                <td>50 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>Dépositaire Central, Banque de Règlement</td>
                                <td>30 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>SGI et BTCC</td>
                                <td>5 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>Organismes de garantie</td>
                                <td>15 000 000 FCFA</td>
                            </tr>

                            <tr>
                                <td>Agences de notation</td>
                                <td>5 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>Société de Gestion de Patrimoine, d’OPCVM ou de FCTC</td>
                                <td>2 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>OPC (OPCVM et autres Organismes de Placement Collectif)</td>
                                <td>1 000 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>Apporteurs d’Affaires/Conseils en Investissements Boursiers / Démarcheurs (personnes
                                    morales)
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>Personnes physiques</li>
                                    </ul>
                                </td>
                                <td>250 000 FCFA</td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>Personnes morales</li>
                                    </ul>
                                </td>
                                <td>500 000 FCFA</td>
                            </tr>
                            </tbody>
                        </table>
                        &nbsp;
                        <table role="table" class="d-table table table-hover">
                            <thead>
                            <tr>
                                <th [style.width]="'80%'">COMMISSION SUR ACTIFS</th>
                                <th [style.width]="'20%'">TAUX</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>sous conservation (hors actifs des OPCVM) auprès des SGI et Teneurs de Comptes</td>
                                <td>0,1 ‰</td>
                            </tr>
                            <tr>
                                <td>sous gestion auprès des Sociétés de Gestion d’OPCVM ou de FCTC</td>
                                <td>0,1 ‰</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
