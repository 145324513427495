<div class="footer-widget social-network" *ngIf="actualite">
    <ul class="social-link ps-sm-4">
        <li *ngIf="actualite.lienFacebook">
            <a [href]="actualite.lienFacebook" target="_blank"><i class="bx bxl-facebook"></i></a>
        </li>
        <li *ngIf="actualite.lienTwitter">
            <a [href]="actualite.lienTwitter" target="_blank"><i class="bx bxl-twitter"></i></a>
        </li>
        <li *ngIf="actualite.lienLinkedin">
            <a [href]="actualite.lienLinkedin" target="_blank"><i class="bx bxl-linkedin"></i></a>
        </li>
        <li *ngIf="actualite.lienYoutube">
            <a [href]="actualite.lienYoutube" target="_blank"><i class="bx bxl-youtube"></i></a>
        </li>
    </ul>
</div>
