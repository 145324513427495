import { Action, createReducer, on } from '@ngrx/store';
import { EntityResultSetList, JupiterPortailState } from '../app.state';
import {
    SetActeurFormulaireList,
    SetActeurList,
    SetActualiteByCategorieList,
    SetActualityAlaUneList,
    SetActualityList,
    SetAppelOffreList,
    SetCacList,
    SetDetenteurCarteList,
    SetEmetteurList,
    SetEmpruntObligataireList,
    SetEpargneCollectiveList,
    SetFcpList,
    SetFormeJuridiqueList,
    SetIndiceBoursierList,
    SetNatureOperationList,
    SetNombreActeurList,
    SetOperationBoursiereList,
    SetOperationList,
    SetOperationTitreList,
    SetPaysList,
    SetSecteurActiviteList,
    SetSocieteCoteList,
    SetTypeActeurList,
    SetTypeFcpList,
    SetTypeInstructionList
} from '../actions/jupiter-portail.actions';
import {
    Acteur,
    ActeurFormulaire,
    Actualite,
    ActualiteByCategorie,
    AppelOffre,
    Cac,
    DetenteurCarte,
    Emetteur,
    EmpruntObligataire,
    EpargneCollective,
    Fcp,
    FormeJuridique,
    IndiceBoursier,
    NatureOperation,
    NombreActeur,
    Operation,
    OperationBoursiere,
    OperationTitre,
    Pays,
    SecteurActivite,
    SocieteCote,
    TypeActeur,
    TypeFcp,
    TypeInstruction
} from '../entity/entity.model';
import { CategorieActualite } from '../../components/common/utils/utils';

export const initialState: JupiterPortailState = {
    appelOffreResultSet: null,
    actualiteResultSet: null,
    detenteurResultSet: null,
    acteurResultSet: null,
    formJuridiqueResultSet: null,
    sectActiviteResultSet: null,
    emetteurResultSet: null,
    natureOperationResultSet: null,
    operationResultSet: null,
    paysResultSet: null,
    typeActeurResultSet: null,
    typeFcpResultSet: null,
    fcpResultSet: null,
    cacResultSet: null,
    typeInstructionResultSet: null,
    actualiteAlaUneResultSet: null,
    typeActualiteByCategResultSet: null,
    empruntObligatoireResultSet: null,
    epargneCollectiveResultSet: null,
    indiceBoursierResultSet: null,
    nombreActeurResultSet: null,
    operationTitreResultSet: null,
    societeCoteResultSet: null,
    operationBoursiereResultSet: null,
    formulaireResultSet: null,
    decisionsResultSet: null,
    instructionResultSet: null,
    reglementResultSet: null,
    circulairesResultSet: null
};

/**
 * Global reducer
 */
const jupiterPortailReducers = createReducer(
    initialState,
    on(SetActualityList, (state, payload: EntityResultSetList<Actualite>) => {
        if (payload.abroge) {
            switch (payload.categorie) {
                case CategorieActualite.Decision: {
                    return {
                    ...state,
                        decisionsResultSet: {data: payload.data, totalRecord: payload.totalRecord}
                    };
                }
                case CategorieActualite.Instruction: {
                    return {
                        ...state,
                        instructionResultSet: {data: payload.data, totalRecord: payload.totalRecord}
                    };
                }
                case CategorieActualite.Reglement: {
                    return {
                        ...state,
                        reglementResultSet: {data: payload.data, totalRecord: payload.totalRecord}
                    };
                }
                case CategorieActualite.Circulaire: {
                    return {
                        ...state,
                        circulairesResultSet: {data: payload.data, totalRecord: payload.totalRecord}
                    };
                }
            }
        }

        return {
            ...state,
            actualiteResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    }),
    on(SetActualityAlaUneList, (state, payload: { actualiteAlaUneResultSet: Array<Actualite> }) => {
        return {
            ...state,
            actualiteAlaUneResultSet: payload.actualiteAlaUneResultSet
        };
    }),
    on(SetAppelOffreList, (state, payload: EntityResultSetList<AppelOffre>) => {
        return {
            ...state,
            appelOffreResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    }),
    on(SetActeurList, (state, payload: EntityResultSetList<Acteur>) => {
        return {
            ...state,
            acteurResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    }),
    on(SetDetenteurCarteList, (state, payload: { detenteurResultSet: Array<DetenteurCarte> }) => {
        return {
            ...state,
            detenteurResultSet: payload.detenteurResultSet
        };
    }),
    on(SetFormeJuridiqueList, (state, payload: { formJuridiqueResultSet: Array<FormeJuridique> }) => {
        return {
            ...state,
            formJuridiqueResultSet: payload.formJuridiqueResultSet
        };
    }),
    on(SetSecteurActiviteList, (state, payload: { sectActiviteResultSet: Array<SecteurActivite> }) => {
        return {
            ...state,
            sectActiviteResultSet: payload.sectActiviteResultSet
        };
    }),
    on(SetEmetteurList, (state, payload: EntityResultSetList<Emetteur>) => {
        return {
            ...state,
            emetteurResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    }),
    on(SetNatureOperationList, (state, payload: { natureOperationResultSet: Array<NatureOperation> }) => {
        return {
            ...state,
            natureOperationResultSet: payload.natureOperationResultSet
        };
    }),
    on(SetOperationList, (state, payload: EntityResultSetList<Operation>) => {
        return {
            ...state,
            operationResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    }),
    on(SetTypeActeurList, (state, payload: { typeActeurResultSet: Array<TypeActeur> }) => {
        return {
            ...state,
            typeActeurResultSet: payload.typeActeurResultSet
        };
    }),
    on(SetPaysList, (state, payload: { paysResultSet: Array<Pays> }) => {
        return {
            ...state,
            paysResultSet: payload.paysResultSet
        };
    }),
    on(SetTypeFcpList, (state, payload: { typeFcpResultSet: Array<TypeFcp> }) => {
        return {
            ...state,
            typeFcpResultSet: payload.typeFcpResultSet
        };
    }),
    on(SetFcpList, (state, payload: EntityResultSetList<Fcp>) => {
        return {
            ...state,
            fcpResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    }),
    on(SetCacList, (state, payload: EntityResultSetList<Cac>) => {
        return {
            ...state,
            cacResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    }),
    on(SetTypeInstructionList, (state, payload: { typeInstructionResultSet: Array<TypeInstruction> }) => {
        return {
            ...state,
            typeInstructionResultSet: payload.typeInstructionResultSet
        };
    }),
    on(SetActualiteByCategorieList, (state, payload: { typeActualiteByCategResultSet: Array<ActualiteByCategorie> }) => {
        return {
            ...state,
            typeActualiteByCategResultSet: payload.typeActualiteByCategResultSet
        };
    }),
    on(SetEmpruntObligataireList, (state, payload: EntityResultSetList<EmpruntObligataire>) => {
        return {
            ...state,
            empruntObligatoireResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    }),
    on(SetEpargneCollectiveList, (state, payload: EntityResultSetList<EpargneCollective>) => {
        return {
            ...state,
            epargneCollectiveResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    }),
    on(SetIndiceBoursierList, (state, payload: EntityResultSetList<IndiceBoursier>) => {
        return {
            ...state,
            indiceBoursierResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    }),
    on(SetNombreActeurList, (state, payload: EntityResultSetList<NombreActeur>) => {
        return {
            ...state,
            nombreActeurResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    }),
    on(SetOperationTitreList, (state, payload: EntityResultSetList<OperationTitre>) => {
        return {
            ...state,
            operationTitreResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    }),
    on(SetSocieteCoteList, (state, payload: EntityResultSetList<SocieteCote>) => {
        return {
            ...state,
            societeCoteResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    }),
    on(SetOperationBoursiereList, (state, payload: EntityResultSetList<OperationBoursiere>) => {
        return {
            ...state,
            operationBoursiereResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    }),
    on(SetActeurFormulaireList, (state, payload: EntityResultSetList<ActeurFormulaire>) => {
        return {
            ...state,
            formulaireResultSet: {data: payload.data, totalRecord: payload.totalRecord}
        };
    })
);

export function jupiterPortailReducer(state: any | undefined, action: Action) {
    return jupiterPortailReducers(state, action);
}
