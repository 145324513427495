<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-20">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Liste des Emetteurs du Marché Financier Régional de l'UMOA</h3>
        </div>

        <div class="single-content">
            <table role="table" class="d-table table table-hover" id="static-table">
                <thead>
                <tr>
                    <th style="width:20%">Expressions</th>
                    <th style="width:80%">Définitions</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>EMETTEURS</td>
                    <td>Sociéte, entité publique ou privée ou Etat dont les titres sont inscrits en compte auprès du
                        Dépositaire Central/Banque de Règlement (DC/BR) et/ou admis à la cote de la Bourse Régionale des
                        valeurs Mobilières (BRVM).
                    </td>
                </tr>
                <tr>
                    <td>SOCIETES COTEES</td>
                    <td>Sociéte dont les titres sont admis à la cote de la Bourse Régionale des Valeurs Mobilières
                        (BRVM) et inscrits en compte auprès du Dépositaire Central/Banque de Règlement (DC/BR).
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <p-panel>
            <ng-template pTemplate="header">
                <span>Zone de filtrage</span>
            </ng-template>
            <form #searchForm="ngForm">
                <div class="row">
                    <div class="form-group col-sm-6">
                        <label for="field_secteur">Secteur d'activité</label>
                        <p-dropdown id="field_secteur" [options]="secteurActiviteList"
                                    [filter]="true" [showClear]="true"
                                    [autoDisplayFirst]="false"
                                    [(ngModel)]="criteria.secteurActiviteId" name="secteur"
                                    [style]="{width: '100%'}" appendTo="body">
                        </p-dropdown>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="field_formj">Forme Juridique</label>
                        <p-dropdown id="field_formj" [options]="formJuridiqueList"
                                    [filter]="true" [showClear]="true"
                                    [autoDisplayFirst]="false"
                                    [(ngModel)]="criteria.formeJuridiqueId" name="formj"
                                    [style]="{width: '100%'}" appendTo="body">
                        </p-dropdown>
                    </div>
                </div>
                <div class="row mt-20">
                    <div class="form-group">
                        <label for="field_denomination">Dénomination</label>
                        <input id="field_denomination" [(ngModel)]="criteria.denomination"
                               name="denomination" [style]="{width: '100%'}" pInputText/>
                    </div>
                </div>
                <div class="mt-20 pb-5">
                    <div style="float:right;">
                        <button type="button" label="Vérifier" pRipple pButton
                                icon="pi pi-search" class="btn-verif" (click)="searchData()">
                        </button>
                    </div>
                </div>
            </form>
        </p-panel>
        &nbsp;
        <p-table #dt [value]="emetteurList" [columns]="cols" responsiveLayout="scroll"
                 [paginator]="false" [rowHover]="true" dataKey="id">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <ng-container *ngFor="let col of columns">
                        <th [style.width]="col.width" [pSortableColumn]="col.field"
                            *ngIf="col.sort">
                            <span>{{col.header}}</span>
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th [style.width]="col.width" *ngIf="!col.sort">
                            <span>{{col.header}}</span>
                        </th>
                    </ng-container>
                    <th [style.width]="'5%'"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-entity let-columns="columns">
                <tr>
                    <ng-container *ngFor="let col of columns">
                        <td [style.width]="col.width">
                            <span class="p-column-title">{{col.header}}</span>
                            <app-table-td [col]="col" [entity]="entity"></app-table-td>
                        </td>
                    </ng-container>
                    <td [style.width]="'5%'">
                        <div class="flex justify-content-center">
                            <button pButton pRipple icon="pi pi-eye" title="Détails"
                                    class="p-button-rounded"
                                    (click)="displayDialog(false, entity)">
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <app-pagination [rows]="size" [totalRecords]="totalRecords" (paginate)="pageChange($event)"></app-pagination>
    </div>
    <p-dialog #dd [(visible)]="display" [style]="{width: '80vw', height: '80vw'}" [maximizable]="true"
              header="Détails Emetteur" [modal]="true" [closable]="true" (onHide)="displayDialog(true)">
        <form>
            <app-verif-details title="Détails Emetteur" [defaultPagination]="true"
                               [tab0Cols]="detCols" [tab1Cols]="det1Cols"
                               [tab2Cols]="det2Cols" [addTabView]="true"
                               field2="instruments" field1="operations"
                               title2="Marché Primaire" title1="Marché Sécondaire"
                               title3="Formulaires" [typeEntity]="'idEmetteur'"
                               [searchedEntity]="selectedEmetteur">
            </app-verif-details>
        </form>
    </p-dialog>
</div>
<!-- Terms & Conditions Area End -->
