import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-search-form',
    templateUrl: './search-form.component.html',
    styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent {
    @Input() title: string;
    @Output() searchData = new EventEmitter<any>();
    @Input() searchCretiria: { lib?: string, agrement?: string } = {};

    constructor() {
    }

    searchDataclick() {
        this.searchData.emit(this.searchCretiria);
    }
}
