import { Component, Input, OnInit } from '@angular/core';
import { ActualiteByCategorie } from '../../../../store/entity/entity.model';
import { CategorieActualite, categorieLabels } from '../../utils/utils';
import { Subject, takeUntil } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import {
    FetchActualiteByCategorieList,
    FetchActualityList,
    SetActualiteByCategorieList,
    SetActualityList
} from '../../../../store/actions/jupiter-portail.actions';
import { selectActualityByCategorieList } from '../../../../store/selectors/jupiter-portail.selectors';
import { Router } from '@angular/router';

@Component({
    selector: 'app-side-bar-categorie',
    templateUrl: './side-bar-categories.component.html',
    styleUrls: ['./side-bar-categories.component.scss']
})
export class SideBarCategoriesComponent implements OnInit {
    @Input() destroy$: Subject<boolean>;
    @Input() isFromDetail = false;
    byCategorieList: Array<ActualiteByCategorie>;
    categorieLabels = categorieLabels;
    query: string;

    constructor(private store: Store<AppState>,
                private router: Router) {

        this.store.dispatch(SetActualiteByCategorieList({typeActualiteByCategResultSet: []}));
        this.store.dispatch(FetchActualiteByCategorieList());
    }

    ngOnInit() {
        this.store.pipe(select(selectActualityByCategorieList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.byCategorieList = [...resultSet];
                    }
                }
            });
    }

    reloadData(categorie?: CategorieActualite) {
        if (this.isFromDetail) {
            this.router.navigate(['actualities'], {
                queryParams: {categorie, query: this.query}
            });
        } else {
            this.store.dispatch(SetActualityList({data: [], totalRecord: 0}));
            this.store.dispatch(FetchActualityList({categorie, query: this.query, size: 10, page: 0}));
        }
    }

    relaodAll() {
        if (!this.query || this.query.length === 0) {
            this.reloadData();
        }
    }
}
