import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    appVersion: string;
    email: string;
    responsiveOptions: any;
    links: Array<any>;

    constructor() {
        this.appVersion = environment.VERSION;

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '768px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    ngOnInit(): void {
        this.links = [
            {
                logo: 'assets/img/useful-links/bceao.png',
                desc: 'Banque Centrale des Etats de l\'Afrique del\'Ouest (BCEAO)',
                url: 'http://www.bceao.int/'
            },
            {
                logo: 'assets/img/useful-links/uemoa.jpeg',
                desc: 'Commission de l\'UEMOA',
                url: 'http://www.uemoa.int/'
            },
            {
                logo: 'assets/img/useful-links/boad.png',
                desc: 'Banque Ouest Africaine de Développement (BOAD)',
                url: 'http://www.boad.org/'
            },
            {
                logo: 'assets/img/useful-links/brvm.png',
                desc: 'Bourse Régionale des Valeurs Mobilières (BRVM) et Dépositaire Central Banque de Règlement (DC/BR)',
                url: 'http://www.brvm.org/'
            },
            {
                logo: 'assets/img/useful-links/apsgi.png',
                desc: 'Association Professionnelle des Sociétés de Gestion et d’Intermédiation de l’UEMOA (APSGI-UEMOA)',
                url: 'https://apsgi.org/'
            }
        ];
    }
}
