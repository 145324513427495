import { Component, OnDestroy, OnInit } from '@angular/core';
import { StatsDataList } from '../../../../store/entity/entity.model';
import { AppState } from '../../../../store/app.state';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { FetchNombreActeurList } from '../../../../store/actions/jupiter-portail.actions';
import { nbActeurCols, NOMBRE_ACTEUR_CHART_COLS, Pagination, pivotTable } from '../../../common/utils/utils';
import { selectNombreActeurList } from '../../../../store/selectors/jupiter-portail.selectors';

@Component({
    selector: 'app-stats-nombre-acteur',
    templateUrl: './stats-nombre-acteur.component.html',
    styleUrls: ['./stats-nombre-acteur.component.scss']
})
export class StatsNombreActeurComponent implements OnInit, OnDestroy {
    title = 'MenuBar.Publication.StatsMarche.nombreActeur.pageTitle';
    subTitle = 'MenuBar.Publication.StatsMarche.nombreActeur.subTitle';
    nbActeur = 'MenuBar.Publication.StatsMarche.nombreActeur.panelTitles.nbActeur';

    nombreActeurList: Array<StatsDataList>;
    destroy$ = new Subject<boolean>();
    totalRecords = 0;
    page = 0;
    size = 10;
    cols = NOMBRE_ACTEUR_CHART_COLS;

    constructor(private store: Store<AppState>) {
        this.store.dispatch(FetchNombreActeurList({size: this.size, page: this.page}));
    }

    ngOnInit() {
        this.loadNombreActeurs();
    }

    loadNombreActeurs() {
        this.store.pipe(select(selectNombreActeurList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet && resultSet.data && resultSet.data.length > 0) {
                        this.totalRecords = resultSet.totalRecord;
                        const dataList = [...resultSet.data].sort((a, b) => a.annee - b.annee);
                        this.nombreActeurList = pivotTable(dataList, 'nombre_acteur', nbActeurCols);
                    }
                }
            });
    }

    pageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;

            this.store.dispatch(FetchNombreActeurList({size: this.size, page: this.page}));
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
