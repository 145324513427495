<div class="client-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="client-title">
                    <div class="section-title">
                        <h2>{{'Acceuil.pratiques.title' | translate}}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="services-area">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="services-card services-card-bg">
                        <a [routerLink]="['/pratique/verification/agrement']" class="services-icon">
                            <img id="li-5" src="../../../../../assets/img/verification-agre-blue.png" alt="">
                        </a>
                        <h3><a [routerLink]="['/pratique/verification/agrement']">{{'Acceuil.pratiques.agrement.title' | translate}}</a></h3>
                        <p></p>
                        <a [routerLink]="['/pratique/verification/agrement']" class="services-more"><i
                            class="flaticon-double-right-arrows-angles"></i></a>
                        <div class="services-card-bottom"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="services-card services-card-bg">
                        <a [routerLink]="['/pratique/verification/cartepro']" class="services-icon">
                            <img id="li-6" src="../../../../../assets/img/verify-carte-blue.png" alt="">
                        </a>
                        <h3><a [routerLink]="['/pratique/verification/cartepro']"><small>{{'Acceuil.pratiques.cartePro.title' | translate}}</small></a></h3>
                        <p></p>
                        <a [routerLink]="['/pratique/verification/cartepro']" class="services-more"><i
                            class="flaticon-double-right-arrows-angles"></i></a>
                        <div class="services-card-bottom"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="services-card services-card-bg">
                        <a [routerLink]="['/pratique/procedure/requete']" class="services-icon">
                            <img id="li-4" src="../../../../../assets/img/requete-blue.png" alt="">
                        </a>
                        <h3><a [routerLink]="['/pratique/procedure/requete']">{{'Acceuil.pratiques.requete.title' | translate}}</a></h3>
                        <p></p>
                        <a [routerLink]="['/pratique/procedure/requete']" class="services-more"><i
                            class="flaticon-double-right-arrows-angles"></i></a>
                        <div class="services-card-bottom"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="services-card services-card-bg">
                        <a [routerLink]="['/pratique/procedure/plainte']" class="services-icon">
                            <img id="li-3" src="../../../../../assets/img/complaint-blue.png" alt="">
                        </a>
                        <h3><a [routerLink]="['/pratique/procedure/plainte']">{{'Acceuil.pratiques.plainte.title' | translate}}</a></h3>
                        <p></p>
                        <a [routerLink]="['/pratique/procedure/plainte']" class="services-more"><i
                                class="flaticon-double-right-arrows-angles"></i></a>
                        <div class="services-card-bottom"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="services-card services-card-bg">
                        <a [routerLink]="['/pratique/appel-offre/emplois']" class="services-icon">
                            <img id="li-1" src="../../../../../assets/img/offer-blue.png" alt="">
                        </a>
                        <h3><a [routerLink]="['/pratique/appel-offre/emplois']">{{'Acceuil.pratiques.offreEmploi.title' | translate}}</a></h3>
                        <p></p>
                        <a [routerLink]="['/pratique/appel-offre/emplois']" class="services-more"><i
                                class="flaticon-double-right-arrows-angles"></i></a>
                        <div class="services-card-bottom"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="services-card services-card-bg">
                        <a [routerLink]="['/pratique/appel-offre/marche']" class="services-icon">
                            <img id="li-2" src="../../../../../assets/img/market-blue.png" alt="">
                        </a>
                        <h3><a [routerLink]="['/pratique/appel-offre/marche']">{{'Acceuil.pratiques.appelOffre.title' | translate}}</a></h3>
                        <p></p>
                        <a [routerLink]="['/pratique/appel-offre/marche']" class="services-more"><i
                                class="flaticon-double-right-arrows-angles"></i></a>
                        <div class="services-card-bottom"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
