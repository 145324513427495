import { Component, OnDestroy, OnInit } from '@angular/core';
import { Acteur, Emetteur, Pays } from '../../../../store/entity/entity.model';
import { SelectItem } from 'primeng/api';
import { ActeurCriteria } from '../../../../store/services/request-params';
import { Subject, takeUntil } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import {
    FetchActeurList,
    FetchPaysList,
    FetchTypeActeurList,
    SetActeurList
} from '../../../../store/actions/jupiter-portail.actions';
import {
    selectActeurList,
    selectPaysList,
    selectTypeActeurList
} from '../../../../store/selectors/jupiter-portail.selectors';
import { ETableName, Pagination } from '../../../common/utils/utils';
import { JupiterPortailService } from '../../../../store/services/jupiter-portail.service';

@Component({
    selector: 'app-intervenant-agrees',
    templateUrl: './intervenant-agrees.component.html',
    styleUrls: ['./intervenant-agrees.component.scss']
})
export class IntervenantAgreesComponent implements OnInit, OnDestroy {
    cols: Array<any>;
    display = false;
    detCols: Array<any>;
    selectedIntervenant: Acteur;
    intervenantList: Array<Acteur>;
    typeActeurList: Array<SelectItem>;
    paysList: Array<SelectItem>;
    criteria: ActeurCriteria = {};
    destroy$ = new Subject<boolean>();
    det1Cols: Array<any>;
    det2Cols: Array<any>;
    indexName = ETableName.acteur;
    totalRecords = 0;
    page = 0;
    size = 10;

    constructor(private store: Store<AppState>,
                private jupiterPortailService: JupiterPortailService) {
        this.store.dispatch(FetchTypeActeurList());
        this.store.dispatch(FetchPaysList());

        this.cols = [
            {field: 'typeLib', header: 'Type', type: 'string', width: '20%', sort: true},
            {field: 'lib', header: 'Dénomination', type: 'string', width: '25%', sort: true},
            {
                field: 'agrement', header: 'Agrément', type: 'pdf', sort: true,
                fileField: 'decision', width: '17%', showText: true
            },
            {field: 'dateAgrement', header: 'Date Agrément', type: 'date', sort: true, width: '15%'},
            {field: 'paysLib', header: 'Pays', type: 'string', sort: true, width: '18%'}
        ];

        this.detCols = [
            {field: 'lib', header: 'Dénomination', type: 'string'},
            {field: 'siege', header: 'Siège social', type: 'string'},
            {field: 'rccm', header: 'RCCM', type: 'string'},
            {field: 'agrement', header: 'Agrément', type: 'string'},
            {field: 'dateAgrement', header: 'Date d\'Agrément', type: 'date'},
            {field: 'telephone', header: 'Téléphone', type: 'string'},
            {field: 'fax', header: 'Fax', type: 'string'},
            {field: 'mail', header: 'Email', type: 'string'},
            {field: 'siteWeb', header: 'Site Web', type: 'string'}
        ];

        this.det1Cols = [
            {field: 'dirigeantLib', header: 'Nom & Prénom(s)', type: 'string', sort: true, width: '60%'},
            {field: 'fonctionLib', header: 'Fonction', type: 'string', sort: true, width: '40%'}
        ];
        this.det2Cols = [
            {field: 'fonctionReglementaireLib', header: 'Fonction', type: 'string', sort: true, width: '25%'},
            {field: 'ref', header: 'Ref. Carte', type: 'string', sort: true, width: '15%'},
            {field: 'nom', header: 'Nom', type: 'string', sort: true, width: '20%'},
            {field: 'prenom', header: 'Prénom(s)', type: 'string', sort: true, width: '30%'},
            {field: 'photo', header: '', type: 'image', width: '10%'}
        ];
    }

    ngOnInit(): void {
        this.store.pipe(select(selectTypeActeurList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.typeActeurList = [];
                        resultSet.map(item => this.typeActeurList.push({label: item.lib, value: item.id}));
                    }
                }
            });

        this.store.pipe(select(selectPaysList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.paysList = [];
                        resultSet.map(item => this.paysList.push({label: item.lib, value: item.id}));
                    }
                }
            });

        this.store.pipe(select(selectActeurList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    this.intervenantList = [];
                    if (resultSet) {
                        this.intervenantList = [...resultSet.data];
                        this.totalRecords = resultSet.totalRecord;
                    } else {
                        this.searchData();
                    }
                }
            });
    }

    searchData() {
        this.store.dispatch(SetActeurList({data: [], totalRecord: 0}));
        if (this.criteria.paysId || this.criteria.typeId || this.criteria.denomination) {
            this.store.dispatch(FetchActeurList({
                ...this.criteria,
                size: this.size,
                page: this.page
            }));
        } else {
            this.store.dispatch(FetchActeurList({
                size: this.size,
                page: this.page
            }));
        }
    }

    displayDialog(hide: boolean, entity?: Emetteur) {
        if (this.display || hide) {
            this.display = false;
            this.selectedIntervenant = null;
        } else {
            this.jupiterPortailService.fetchActeurs({id: entity.id!})
                .subscribe({
                    next: resultSet => {
                        if (resultSet.body.length > 0) {
                            this.selectedIntervenant = resultSet.body[0];
                            this.display = true;
                        }
                    }
                });
        }
    }

    pageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;

            this.searchData();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
