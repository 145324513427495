import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JupiterPortailService } from '../services/jupiter-portail.service';
import { catchError, map, mergeMap, of } from 'rxjs';
import {
    CreateActeurFormulaire,
    CreateProcedure,
    FetchActeurFormulaireList,
    FetchActeurList,
    FetchActualiteByCategorieList,
    FetchActualityAlaUneList,
    FetchActualityList,
    FetchAppelOffreList,
    FetchCacList,
    FetchDetenteurCarteList,
    FetchEmetteurList,
    FetchEmpruntObligataireList,
    FetchEpargneCollectiveList,
    FetchFcpList,
    FetchFormeJuridiqueList,
    FetchIndiceBoursierList,
    FetchNatureOperationList,
    FetchNombreActeurList,
    FetchOperationBoursiereList,
    FetchOperationList,
    FetchOperationTitreList,
    FetchPaysList,
    FetchSecteurActiviteList,
    FetchSocieteCoteList,
    FetchTypeActeurList,
    FetchTypeFcpList,
    FetchTypeInstructionList,
    SendFluxRss,
    SetActeurFormulaireList,
    SetActeurList,
    SetActualiteByCategorieList,
    SetActualityAlaUneList,
    SetActualityList,
    SetAppelOffreList,
    SetCacList,
    SetDetenteurCarteList,
    SetEmetteurList,
    SetEmpruntObligataireList,
    SetEpargneCollectiveList,
    SetFcpList,
    SetFormeJuridiqueList,
    SetIndiceBoursierList,
    SetNatureOperationList,
    SetNombreActeurList,
    SetOperationBoursiereList,
    SetOperationList,
    SetOperationTitreList,
    SetPaysList,
    SetSecteurActiviteList,
    SetSocieteCoteList,
    SetStatus,
    SetTypeActeurList,
    SetTypeFcpList,
    SetTypeInstructionList
} from '../actions/jupiter-portail.actions';
import {
    ActeurCriteria,
    ActualiteCriteria,
    AppelOffreCriteria,
    CacCreteria,
    DetCarteCriteria,
    EmetteurCriteria,
    FcpCreteria,
    OperationCriteria
} from '../services/request-params';
import { showToast, StatusEnum } from '../../components/common/utils/utils';
import { DepotDemande } from '../entity/entity.model';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MessageService } from 'primeng/api';

@Injectable()
export class JupiterPortailEffects {
    successfullMessage = 'Opération effectué avec succès.';

    constructor(
        private actions$: Actions,
        private messageService: MessageService,
        private jupiterPortailService: JupiterPortailService
    ) {
    }

    private getTotalRecord(headers: HttpHeaders): number {
        return Number(headers.get('X-Total-Count'));
    }

    fetchActualityResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchActualityList),
            mergeMap((criteria: ActualiteCriteria) => this.jupiterPortailService.fetchActualities(criteria)
                .pipe(
                    map(resp => SetActualityList({
                        data: resp.body,
                        categorie: criteria.categorie,
                        abroge: criteria.abroge,
                        totalRecord: this.getTotalRecord(resp.headers)
                    })),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchActualityAlaUneResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchActualityAlaUneList),
            mergeMap((criteria: ActualiteCriteria) => this.jupiterPortailService.fetchActualities(criteria)
                .pipe(
                    map(value => SetActualityAlaUneList({actualiteAlaUneResultSet: value.body})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchAppelOffreResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchAppelOffreList),
            mergeMap((criteria: AppelOffreCriteria) => this.jupiterPortailService.fetchAppelOffres(criteria)
                .pipe(
                    map(resp => SetAppelOffreList({data: resp.body, totalRecord: this.getTotalRecord(resp.headers)})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    createProcedure$ = createEffect(() => this.actions$.pipe(
            ofType(CreateProcedure),
            mergeMap((depotDemande: DepotDemande) =>
                this.jupiterPortailService.createProcedure(depotDemande)
                    .pipe(
                        map(() => {
                            this.showSuccessMessage('Dépôt demande', 'Votre demande a été transmise, merci de rester à l\'écoute.');
                            this.jupiterPortailService.setNewValue<StatusEnum>(StatusEnum.success);
                            return SetStatus(null);
                        }),
                        catchError((errResp) => {
                            this.showErrorMessage(errResp, 'Erreur lors du dépôt de demande');
                            return of();
                        })
                    ))
        )
    );

    fetchActeurResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchActeurList),
            mergeMap((criteria: ActeurCriteria) => this.jupiterPortailService.fetchActeurs(criteria)
                .pipe(
                    map(resp => SetActeurList({data: resp.body, totalRecord: this.getTotalRecord(resp.headers)})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchDetenteurResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchDetenteurCarteList),
            mergeMap((criteria: DetCarteCriteria) => this.jupiterPortailService.fetchDetenteurs(criteria)
                .pipe(
                    map(value => SetDetenteurCarteList({detenteurResultSet: value})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchFormJuridiqueResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchFormeJuridiqueList),
            mergeMap(() => this.jupiterPortailService.fetchFormeJuridiques()
                .pipe(
                    map(value => SetFormeJuridiqueList({formJuridiqueResultSet: value})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchSecteurActiviteResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchSecteurActiviteList),
            mergeMap(() => this.jupiterPortailService.fetchSecteurActivites()
                .pipe(
                    map(value => SetSecteurActiviteList({sectActiviteResultSet: value})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchEmetteurResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchEmetteurList),
            mergeMap((criteria: EmetteurCriteria) => this.jupiterPortailService.fetchEmetteurs(criteria)
                .pipe(
                    map(resp => SetEmetteurList({data: resp.body, totalRecord: this.getTotalRecord(resp.headers)})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchNatureOperationResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchNatureOperationList),
            mergeMap(() => this.jupiterPortailService.fetchNatureOperations()
                .pipe(
                    map(value => SetNatureOperationList({natureOperationResultSet: value})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchOperationResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchOperationList),
            mergeMap((criteria: OperationCriteria) => this.jupiterPortailService.fetchOperation(criteria)
                .pipe(
                    map(resp => SetOperationList({data: resp.body, totalRecord: this.getTotalRecord(resp.headers)})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchTypeActeurResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchTypeActeurList),
            mergeMap(() => this.jupiterPortailService.fetchTypeActeur()
                .pipe(
                    map(value => SetTypeActeurList({typeActeurResultSet: value})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchPaysResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchPaysList),
            mergeMap(() => this.jupiterPortailService.fetchPays()
                .pipe(
                    map(value => SetPaysList({paysResultSet: value})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchTypeFcpResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchTypeFcpList),
            mergeMap(() => this.jupiterPortailService.fetchTypeFcp()
                .pipe(
                    map(value => SetTypeFcpList({typeFcpResultSet: value})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchFcpResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchFcpList),
            mergeMap((criteria: FcpCreteria) => this.jupiterPortailService.fetchFcp(criteria)
                .pipe(
                    map(resp => SetFcpList({data: resp.body, totalRecord: this.getTotalRecord(resp.headers)})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchCacResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchCacList),
            mergeMap((criteria: CacCreteria) => this.jupiterPortailService.fetchCac(criteria)
                .pipe(
                    map(resp => SetCacList({data: resp.body, totalRecord: this.getTotalRecord(resp.headers)})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchTypeInstructionResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchTypeInstructionList),
            mergeMap(() => this.jupiterPortailService.fetchTypeInstruction()
                .pipe(
                    map(value => SetTypeInstructionList({typeInstructionResultSet: value})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    sendFluRss$ = createEffect(() => this.actions$.pipe(
            ofType(SendFluxRss),
            mergeMap((obj: any) =>
                this.jupiterPortailService.sendFluxRss(obj)
                    .pipe(
                        map(() => {
                            this.showSuccessMessage('Flux Rss', 'Votre demande a été transmise, merci de rester à l\'écoute.');
                            return SetStatus(null);
                        }),
                        catchError((errResp) => {
                            this.showErrorMessage(errResp);
                            return of();
                        })
                    ))
        )
    );

    fetchActualiteByCategorieResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchActualiteByCategorieList),
            mergeMap(() => this.jupiterPortailService.fetchGroupedBycategorie()
                .pipe(
                    map(value => SetActualiteByCategorieList({typeActualiteByCategResultSet: value})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchEmpruntObligataireResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchEmpruntObligataireList),
            mergeMap((payload) => this.jupiterPortailService.fetchEmpruntObligatoire(payload)
                .pipe(
                    map(resp => SetEmpruntObligataireList({
                        data: resp.body,
                        totalRecord: this.getTotalRecord(resp.headers)
                    })),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchEpargneCollectiveResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchEpargneCollectiveList),
            mergeMap((payload) => this.jupiterPortailService.fetchEpargneCollective(payload)
                .pipe(
                    map(resp => SetEpargneCollectiveList({
                        data: resp.body,
                        totalRecord: this.getTotalRecord(resp.headers)
                    })),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchIndiceBoursierResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchIndiceBoursierList),
            mergeMap((payload) => this.jupiterPortailService.fetchIndiceBoursier(payload)
                .pipe(
                    map(resp => SetIndiceBoursierList({data: resp.body, totalRecord: this.getTotalRecord(resp.headers)})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchNombreActeurResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchNombreActeurList),
            mergeMap((payload) => this.jupiterPortailService.fetchNombreOctobre(payload)
                .pipe(
                    map(resp => SetNombreActeurList({data: resp.body, totalRecord: this.getTotalRecord(resp.headers)})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchOperationTitreResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchOperationTitreList),
            mergeMap((payload) => this.jupiterPortailService.fetchOperationTitre(payload)
                .pipe(
                    map(resp => SetOperationTitreList({data: resp.body, totalRecord: this.getTotalRecord(resp.headers)})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchSocieteCoteResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchSocieteCoteList),
            mergeMap((payload) => this.jupiterPortailService.fetchSocieteCote(payload)
                .pipe(
                    map(resp => SetSocieteCoteList({data: resp.body, totalRecord: this.getTotalRecord(resp.headers)})),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    fetchOperationBoursiereResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchOperationBoursiereList),
            mergeMap((payload) => this.jupiterPortailService.fetchOperationBoursiere(payload)
                .pipe(
                    map(resp => SetOperationBoursiereList({
                        data: resp.body,
                        totalRecord: this.getTotalRecord(resp.headers)
                    })),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    createActeurFormulaire$ = createEffect(() => this.actions$.pipe(
            ofType(CreateActeurFormulaire),
            mergeMap((obj: any) =>
                this.jupiterPortailService.createActeurFormulaire(obj.entity)
                    .pipe(
                        map(() => {
                            this.showSuccessMessage('Chargement de fichier');
                            return FetchActeurFormulaireList(obj.criteria);
                        }),
                        catchError((errResp) => {
                            this.showErrorMessage(errResp);
                            return of();
                        })
                    ))
        )
    );

    fetchActeurFormulaireResultSet$ = createEffect(() => this.actions$.pipe(
            ofType(FetchActeurFormulaireList),
            mergeMap((payload) => this.jupiterPortailService.fetchActeurFormulaire(payload)
                .pipe(
                    map(resp => SetActeurFormulaireList({
                        data: resp.body,
                        totalRecord: this.getTotalRecord(resp.headers)
                    })),
                    catchError((errResp) => {
                        this.showErrorMessage(errResp);
                        return of();
                    })
                ))
        )
    );

    private showErrorMessage(errResp: HttpErrorResponse, summary: string = 'Erreur lors du chargement des données') {
        showToast(StatusEnum.error, summary, errResp.error.message, this.messageService);
    }

    private showSuccessMessage(summary: string, details = this.successfullMessage) {
        showToast(StatusEnum.success, summary, details, this.messageService);
    }

}

