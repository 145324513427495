<div class="single-content text-justify">
    <h3>Règlement Général</h3>
    <p>
        Le Règlement Général est un texte de base qui définit l’organisation, le fonctionnement et le contrôle
        du marché financier régional. Il a été adopté le 28 novembre 1997 par le Conseil des Ministres de
        l’Union Monétaire Ouest Africain (UMOA) aux termes de la Décision n°001/97.
        Sa dernière modification est survenue le 16 septembre 2005.
    </p>
</div>

<div class="single-content text-justify">
    Le Règlement Général, comporte trois titres: le titre I est relatif aux dispositions générales,
    le titre II énonce les principes déontologiques et le titre III, subdivisé en six (06) sous-titres,
    traite des domaines ci-après :
    <ul class="mt-20">
        <li>Sous-titre 1 : Les acteurs du marché financier régional ;</li>
        <li>Sous-titre 2 : Les émissions de valeurs mobilières;</li>
        <li>Sous-titre 3 : Les clients et le public ;</li>
        <li>Sous-titre 4 : Les produits de placement étrangers ;</li>
        <li>Sous-titre 5 : Le règlement des différends professionnels ;</li>
        <li>Sous-titre 6 : Les dispositions diverses</li>
    </ul>
    <table role="table" class="d-table table table-hover">
        <thead>
        <tr role="row">
            <th role="cell" [style.width]="'5%'"></th>
            <th role="cell" [style.width]="'80%'">Dénomination</th>
            <th role="cell" [style.width]="'15%'">Date</th>
        </tr>
        </thead>
        <tbody>
        <tr role="row">
            <td role="cell" [style.width]="'5%'">
                <a href="../../../../../assets/docs/general/Reglement_General.pdf" target="_blank">
                    <img src="../../../../../assets/img/download-pdf.png" alt="">
                </a>
            </td>
            <td role="cell" [style.width]="'80%'">
                Règlement Général relatif à l’organisation, au fonctionnement
                et au contrôle du Marché Financier Régional de l’UMOA
            </td>
            <td role="cell" [style.width]="'15%'" class="border-left"></td>
        </tr>
        <tr role="row">
            <td role="cell" [style.width]="'5%'">
                <a href="../../../../../assets/docs/general/Decision_CM_000.pdf" target="_blank">
                    <img src="../../../../../assets/img/download-pdf.png" alt="">
                </a>
            </td>
            <td role="cell" [style.width]="'80%'">
                Décision portant modification des dispositions de l'article 37 du Règlement Général relatif à
                l'organisation, au fonctionnement et au contrôle du Marché Financier Régional de l’UMOA
            </td>
            <td role="cell" [style.width]="'15%'" class="border-left">27 mar. 1998</td>
        </tr>
        <tr role="row">
            <td role="cell" [style.width]="'5%'">
                <a href="../../../../../assets/docs/general/Decision_CM_05092005_du_16-09-2005.pdf" target="_blank">
                    <img src="../../../../../assets/img/download-pdf.png" alt="">
                </a>
            </td>
            <td role="cell" [style.width]="'80%'">
                Décision N° CM 05/09/2005 portant modification de l'article 136 du Règlement Général relatif à
                l’organisation, au fonctionnement et au Contrôle du Marché Financier de l’UMOA
            </td>
            <td role="cell" [style.width]="'15%'" class="border-left">16 sept. 2005</td>
        </tr>
        </tbody>
    </table>
</div>
