<!-- Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'Acceuil.motPresident.title' | translate}}</h3>
            <ul>
                <li><a routerLink="/">{{'MenuBar.accueil' | translate}}</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li><a>{{'Acceuil.motPresident.title' | translate}}</a></li>
            </ul>
        </div>
    </div>
</div>
<div class="mt-45 container guidelines-area mb-20">
    <div class="row">
        <div class="col-lg-8 col-md-12">
            <div class="guidelines-content">
                <h2>{{'Acceuil.motPresident.title' | translate}}</h2>
                <p>
                    <span [innerHtml]="'Acceuil.motPresident.texte' | translate"></span>
                </p>

                <div class="signature">
                    <img src="assets/img/signature.jpeg" alt="Images" width="120" height="48">
                </div>

                <div class="content">
                    <h3>Badanam PATOKI</h3>
                    <span>{{'Acceuil.motPresident.signature' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-12  guidelines-bg"></div>
    </div>
</div>
