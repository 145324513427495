import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ETableName, Pagination } from '../../../common/utils/utils';

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent {
    @Input() dataList: Array<any>;
    @Input() cols: Array<any> = [];
    @Input() detCols: any;
    @Input() addDetails = true;
    @Input() totalRecords: number;
    @Input() rows: number;
    @Output() paginate = new EventEmitter<Pagination>();
    @Input() indexName: ETableName;

    selectedEntity: any;
    display = false;


    displayDialog(entity?: any) {
        if (this.display) {
            this.display = false;
            this.selectedEntity = null;
        } else {
            this.selectedEntity = entity;
            this.display = true;
        }
    }

    pageChanged(pagination: Pagination) {
        this.paginate.emit(pagination);
    }
}
