<p-table [value]="dataList" [columns]="cols" responsiveLayout="scroll"
         [paginator]="false" [rowHover]="true" dataKey="id">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
                <th [style.width]="col.width" [pSortableColumn]="col.field"
                    *ngIf="col.sort">
                    <span>{{col.header}}</span>
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th [style.width]="col.width" *ngIf="!col.sort" style="text-align: right" class="pr-2">
                    <button type="button" pButton icon="pi pi-refresh"
                            class="btn-style" pRipple tooltipPosition="left"
                            (click)="displayDialog('LOAD_ALL')" pTooltip="Charger la liste">
                    </button>
                    &nbsp;
                    <button type="button" pButton icon="pi pi-upload" class="btn-style"
                            pRipple tooltipPosition="left"
                            (click)="displayDialog('UPLOAD_FORM')" pTooltip="Charger un formulaire">
                    </button>
                </th>
            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-entity let-columns="columns">
        <tr>
            <ng-container *ngFor="let col of columns">
                <td [style.width]="col.width" *ngIf="col?.type !== 'anyDoc'">
                    <span class="p-column-title">{{col.header}}</span>
                    <span>{{entity[col.field]}}</span>
                </td>
                <td [style.width]="col.width" *ngIf="col?.type === 'anyDoc'"
                    style="text-align: center" class="pr-2">
                    <span class="p-column-title">{{col.header}}</span>
                    <span class="img-card download-cursor p-button-link" (click)="downloadPdfFile(entity)">
                        <ng-container *ngFor="let fileType of typeFormsList">
                            <img *ngIf="fileType.value.includes(entity.extension)" [src]="fileType.img" alt="" width="32" height="32">
                        </ng-container>
                    </span>
                </td>
            </ng-container>

        </tr>
    </ng-template>
</p-table>
<app-pagination [rows]="rows" [totalRecords]="totalRecords" (paginate)="pageChanged($event)"></app-pagination>
<p-dialog #dd [(visible)]="display" [style]="{width: '45vw'}"
          header="Acteur Formulaires" [modal]="true" [closable]="false">
    <form #uploadForm="ngForm">
        <div class="field">
            <label class="text-required mb-2">Code Secret</label>
            <input type="text" pInputText id="field_prenom" required
                   [(ngModel)]="codeSecret" [style]="{width: '100%'}"
                   name="codeSecret"/>
        </div>
        <ng-container *ngIf="currentAction === 'UPLOAD_FORM'">
            &nbsp;
            <div class="field">
                <label for="field_file_type">Type Formulaire</label>
                <p-dropdown id="field_file_type" [options]="typeFormsList"
                            [filter]="true" [showClear]="true"
                            [autoDisplayFirst]="false"
                            [(ngModel)]="docType" name="docType"
                            [style]="{width: '100%'}" appendTo="body">
                </p-dropdown>
            </div>
            &nbsp;
            <div class="field">
                <p-fileUpload #fileUpload [customUpload]="true" [accept]="docType" [auto]="true"
                              [multiple]="false" (uploadHandler)="onFileUpload($event)"
                              [maxFileSize]="2000000" chooseLabel="Selectionner un ficher"
                              [invalidFileSizeMessageSummary]="'{0}: Taille de fichier invalide,'"
                              [invalidFileSizeMessageDetail]="'la taille de téléchargement maximale est {0}'"
                              (onRemove)="removeImage(fileUpload)" [disabled]="!docType || !codeSecret">
                </p-fileUpload>
            </div>
        </ng-container>
        <p-footer>
            <div class="mt-20 pb-5">
                <div style="float:right;">
                    <button type="button" pButton icon="pi pi-times" pRipple
                            label="Annuler" (click)="displayDialog()" class="p-button-danger">
                    </button>
                    &nbsp;
                    <button type="button" pButton [icon]="actionData?.icon"
                            pRipple [disabled]="uploadForm.form.invalid"
                            [label]="actionData?.label" (click)="doAction()">
                    </button>
                </div>
            </div>
        </p-footer>
    </form>
</p-dialog>
