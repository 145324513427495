<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-20">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Comment investir sur un marché financier ?</h3>
            <p>
                Le marché financier et le marché monétaire constituent les deux facettes du marché des capitaux. Tandis
                que le marché des capitaux constitue le circuit des transactions de capitaux à long terme, le marché
                monétaire est réservé aux opérations de crédit et d’avance à court terme.
                <br><br>
                Le marché financier comprend les deux paliers suivants : le marché primaire et le marché secondaire.
                Le marché primaire est celui réservé aux nouvelles émissions.
                <br><br>
                Quant au marché secondaire ou marché boursier à proprement parler, il se présente comme le lieu de
                rencontre des demandeurs et des vendeurs de titres déjà émis et acquis. Ceci justifie son appellation de
                "marché du vieux" ou" marché de l'occasion" par opposition au "marché du neuf" que constitue le marché
                primaire.
            </p>
        </div>

        <div class="single-content">
            <p-accordion>
                <p-accordionTab
                        header="Quelle procédure doit suivre l’épargnant qui désire intervenir sur le marché financier ?"
                        [selected]="true">
                    <div class="single-content text-justify">
                        <p>
                            L’intervention des épargnants sur le marché financier s’inscrit essentiellement dans le
                            cadre d’un projet d’acquisition ou de cession de titres. Que ce soit sur le marché primaire
                            ou sur le marché secondaire, cette intervention requiert la présence obligatoire d’un
                            intermédiaire financier notamment celle d’une Société de Gestion et d’Intermédiation (SGI).
                            <br><br>
                            Conformément aux dispositions de l’article 37 du Règlement Général, les SGI sont autorisées
                            à exercer les activités de négociateur-compensateur de valeurs mobilières cotées. Dans ce
                            cadre, elles reçoivent les ordres des clients en vue de leur exécution en bourse. Selon les
                            cas, l’ordre de l’épargnant doit préciser s’il s’agit d’une vente ou d’un achat, les titres
                            visés, le cours et la quantité souhaitée. Par ailleurs, l’ordre d’achat n’est recevable que
                            si celui qui l’a émis a un compte-espèce préalablement ouvert et suffisamment alimenté dans
                            les livres de la SGI. Quant à l’ordre de vente, il n’est recevable que si le donneur d’ordre
                            a un compte-espèces et un compte-titres préalablement ouverts et le compte-titres dûment
                            reconnu des valeurs à céder. Lorsque ces conditions sont réunies, l’ordre est transmis à la
                            BRVM par le canal disponible (connexion informatique, télex, fax …). A l’issue de la
                            transaction, l’épargnant est informé de l’exécution de son ordre par un avis d’opéré que lui
                            adresse la SGI qu’il a mandatée.
                            <br><br>
                            A l’occasion des opérations sur le marché primaire, les épargnants expriment leur ordre
                            d’acquisition de titres sur les bulletins de souscription qui sont centralisés au niveau des
                            SGI membres du syndicat de placement.
                            <br><br>
                            Cependant, à défaut de s’adresser personnellement et directement à une Société de Gestion et
                            d’Intermédiation (SGI) pour réaliser son projet d’investissement en valeur mobilière,
                            l’épargnant peut solliciter les services d’un Apporteur d’Affaires ou d’un Démarcheur pour
                            obtenir l’exécution de son ordre par la SGI.
                        </p>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Comment les titres acquis sur le marché financier peuvent-ils être gérés ?">
                    <div class="single-content text-justify">
                        L’épargnant peut directement et personnellement assurer lui-même la gestion de son portefeuille
                        de titres. Il peut également s’en remettre à un intermédiaire financier dans le cadre d’une
                        gestion privée ou collective conformément aux dispositions du Règlement Général du Conseil
                        Régional.
                        <h3 class="mt-20">A - La gestion directe</h3>
                        <p>Dans ce type de gestion, l’épargnant prend les initiatives de gestion qu’il communique à
                            l’intermédiaire conservateur de ses titres. L’avantage de cette option est la visibilité et
                            le suivi de l’évolution du portefeuille. Mais pour l’épargnant n’ayant pas d’expérience en
                            matière boursière, il y a un risque de non compréhension des mécanismes du marché, et de
                            prise de décisions inopportunes.
                        </p>

                        <h3 class="mt-20">B - La gestion privée</h3>
                        <p>
                            Au terme de l’article 57 du Règlement Général, sont réputés exercer, à titre principal, une
                            activité de gestion de titres sous mandat, à caractère privé, les personnes morales qui, par
                            le biais de placement et négociation en bourse effectués par les SGI auxquelles elles
                            transmettent les ordres correspondants, interviennent discrétionnairement dans la gestion
                            des titres qui leur sont confiés sur la base d’un mandat de gestion établi avec leurs
                            clients.
                            <br><br>
                            Ces Sociétés de Gestion pour compte de tiers, ne doivent pas détenir les titres et/ou les
                            fonds de leurs clients. Il s’agit principalement des Sociétés de Gestion de Patrimoine.
                            <br><br>
                            Les SGP, par le biais de placements et négociations effectués par les SGI, interviennent
                            discrétionnairement dans la gestion des titres qui leur sont confiés sur la base d’un mandat
                            de gestion établi avec leurs clients.
                            <br><br>
                            Ces sociétés ne doivent pas détenir les titres et ou les fonds de leurs clients.
                        </p>
                        <h5 class="mt-20">Avantages :</h5>
                        <ul class="mt-20">
                            <li>l’investisseur s’appuie sur le savoir-faire d’un professionnel,</li>
                            <li>le choix entre plusieurs formules de délégation, selon l’offre des prestataires (comptes
                                conseillés, comptes gérés…),
                            </li>
                            <li>
                                la stratégie de gestion est discutée et devient un mandat de gestion plus personnalisé.
                            </li>
                        </ul>
                        <h5 class="mt-20">Inconvénients :</h5>
                        <ul class="mt-20">
                            <li>le risque de blocage lié aux clauses du mandat</li>
                            <li>le coût élevé de l’intermédiation qui est plus adaptée aux patrimoines ayant une
                                certaine importance.
                            </li>
                        </ul>

                        <h3 class="mt-20">C - La gestion collective</h3>
                        <p>C’est la formule d’investissement indirecte, où l’investisseur utilise le système de gestion
                            collective pratiqué par les OPCVM. La gestion collective s’effectue, au terme de l’article
                            71 du Règlement Général, au sein des Organismes de Placement Collectif en Valeurs Mobilières
                            (OPCVM) regroupés en :
                        </p>
                        <ul class="mt-20">
                            <li>Société d’Investissement à Capital Variable (SICAV)</li>
                            <li>Fonds Communs de Placement (FCP),</li>
                            <li>ou toute autre forme de placement collectif agréées par le Conseil Régional.</li>
                        </ul>
                        <h5 class="mt-20">Avantages :</h5>
                        <ul class="mt-20">
                            <li>Clarté et simplification pour les investisseurs non spécialisés,</li>
                            <li>Répartition du risque boursier,</li>
                            <li>Choix des investissements par la diversité du fonds,</li>
                            <li>Liquidité du produit,</li>
                            <li>Coût inférieur à un placement en direct,</li>
                            <li>Possibilité d’un investissement modeste,</li>
                            <li>Sécurité du cadre légal réglementaire,</li>
                        </ul>
                        <h5 class="mt-20">Inconvénients :</h5>
                        <ul class="mt-20">
                            <li>Perte de contrôle sur le contenu du portefeuille, et en particulier sur l’écart entre la
                                gestion souhaitée et la gestion réelle,
                            </li>
                            <li>Impossibilité de profiter d’une situation spéciale,</li>
                            <li>‘’Enfermement’’ dans un style de gestion,</li>
                            <li>Tarification élevée,</li>
                            <li>Manque de souplesse : liquidité certes totale mais parfois avec des contraintes de
                                calendrier, selon la fréquence de cotation du fonds,
                            </li>
                            <li>Limitation aux produits maison dans de nombreux cas.</li>
                        </ul>
                    </div>
                </p-accordionTab>
                <p-accordionTab
                        header="Quelques précautions à prendre avant d’investir sur le marché financier régional ?">
                    <div class="single-content text-justify">

                        <p>
                            Il importe que les investisseurs prennent certaines précautions avant de s’engager dans une
                            opération sur le marché financier notamment :
                        </p>
                        <ul class="mt-20">
                            <li>Vérifier que l'intermédiaire auprès duquel on veut passer des ordres ou à qui on veut
                                déléguer la gestion d’un portefeuille, est agréé par l'Autorité des Marchés Financiers de l’UMOA (AMF-UMOA),
                            </li>
                            <li>Ne s’engager qu’après avoir lu et bien compris les documents mis à votre disposition par
                                l’intermédiaire (note d'information, bulletin de souscription, formulaire d’ordre, …),
                            </li>
                            <li>Lire les contrats qui vous sont proposés avant de les signer (ouverture de comptes
                                titres, mandat de gestion),
                            </li>
                            <li>Demander, lire les prospectus des OPCVM et avoir les explications souhaitées avant de
                                souscrire,
                            </li>
                            <li>Demander un temps de réflexion avant de s’engager.</li>
                        </ul>
                        <p>Après l’opération, il importe d’adopter certaines attitudes afin de préserver son
                            investissement. A cet effet, l’investisseur doit :
                        </p>
                        <ul class="mt-20">
                            <li>suivre la vie des sociétés dans lesquelles il a investi,</li>
                            <li>
                                diversifier ses sources d'information auprès :
                                <ul>
                                    <li>de la société dont il est actionnaire,</li>
                                    <li>de la presse financière,</li>
                                    <li>des sites Internet, parmi ceux-ci, n'oubliez pas : www.brvm.org www.amf-umoa.org
                                    </li>
                                </ul>
                            </li>
                            <li>lire régulièrement les avis d'opérés, les relevés de situation et comptes rendus de
                                gestion,
                            </li>
                            <li>réagir rapidement auprès de votre prestataire en cas d'incompréhension, de doute ou de
                                désaccord,
                            </li>
                            <li>saisir l’AMF-UMOA en cas de besoin.</li>
                        </ul>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
