<!-- Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{title | translate}}</h3>
            <ul>
                <li><a routerLink="/">{{'MenuBar.accueil' | translate}}</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li><a>{{title | translate}}</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-45">
    <div class="container">
        <div class="single-content text-justify">
            <h3>{{title | translate}}</h3>
        </div>
        <div class="pb-70 mt-20">
            <div class="single-content">
                <h3>Instructions</h3>
                &nbsp;
                <app-jp-table [dataList]="instructionList"
                              [cols]="cols" [rows]="sizeInstruction"
                              [totalRecords]="totalRecords"
                              [indexName]="indexName"
                              (paginate)="instructionPageChange($event)">
                </app-jp-table>
            </div>
            <div class="single-content">
                <h3>Décisions</h3>
                &nbsp;
                <app-jp-table [dataList]="decisionsList"
                              [cols]="cols" [rows]="sizeDecisions"
                              [totalRecords]="totalRecords"
                              [indexName]="indexName"
                              (paginate)="decisionPageChange($event)">
                </app-jp-table>
            </div>
            <div class="single-content">
                <h3>Circulaires</h3>
                &nbsp;
                <app-jp-table [dataList]="circulairesList"
                              [cols]="cols" [rows]="sizeCirculaire"
                              [totalRecords]="totalRecords"
                              [indexName]="indexName"
                              (paginate)="circulairePageChange($event)">
                </app-jp-table>
            </div>
            <div class="single-content">
                <h3>Autres actes</h3>
                &nbsp;
                <app-jp-table [dataList]="reglementList"
                              [cols]="colsReglement" [rows]="sizeReglement"
                              [totalRecords]="totalRecords"
                              [indexName]="indexName"
                              (paginate)="reglementPageChange($event)">
                </app-jp-table>
            </div>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->


