import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-organisation',
    templateUrl: './organisation.component.html',
    styleUrls: ['./organisation.component.scss']
})
export class OrganisationComponent implements OnInit {
    data: TreeNode[];

    constructor() {
    }

    ngOnInit(): void {

        this.data = [
            {
                label: 'PRESIDENT',
                type: 'person',
                styleClass: 'p-person',
                expanded: true,
                children: [
                    {
                        label: 'SECRETARIAT GENERAL',
                        type: 'person',
                        styleClass: 'p-person',
                        expanded: true,
                        children: [
                            {
                                label: 'Direction du Contrôle Interne et de la Conformité',
                                type: 'person',
                                styleClass: 'p-person'
                            },
                            {
                                label: 'Direction des Acteurs',
                                type: 'person',
                                styleClass: 'p-person'
                            },
                            {
                                label: 'Direction de l’Administration, des Finances et des Moyens Généraux',
                                type: 'person',
                                styleClass: 'p-person'
                            },
                            {
                                label: 'Direction des Emetteurs',
                                type: 'person',
                                styleClass: 'p-person'
                            },
                            {
                                label: 'Direction Du Contrôle ',
                                type: 'person',
                                styleClass: 'p-person'
                            },
                            {
                                label: 'Service de la Comptabilité et de la Trésorerie',
                                type: 'person',
                                styleClass: 'p-person'
                            },
                            {
                                label: 'Service des Systèmes d’Information',
                                type: 'person',
                                styleClass: 'p-person'
                            },

                        ]
                    }
                ]
            }
        ];

    }
}
