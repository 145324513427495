import { Component, Input } from '@angular/core';
import { FileHandlingService } from '../../../../store/services/file-handling-service';
import { JupiterPortailService } from '../../../../store/services/jupiter-portail.service';
import { ETableName, showToast } from '../../utils/utils';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-table-td',
    templateUrl: './table-td.component.html'
})
export class TableTdComponent {
    @Input() col: any;
    @Input() entity: any;
    @Input() indexName: ETableName;
    loader: Map<number, boolean> = new Map();


    constructor(private fileService: FileHandlingService,
                private messageService: MessageService,
                private jupiterPortailService: JupiterPortailService) {
    }

    downloadPdfFile() {
        if (this.col?.bytesField) {
            this.fileService.downloadBinaryFile('application/pdf', this.entity[this.col?.bytesField!],
                this.entity[this.col?.titelField!]);
        } else {
            this.loader.set(this.entity['id'], true);
            this.jupiterPortailService.remoteDownloadPdf(this.entity[this.col?.fileField!], this.indexName, this.entity.id, this.col.fileField)
                .subscribe({
                    next: (resp) => {
                        if (resp.size > 0) {
                            this.fileService.downloadFile('application/pdf', resp,
                                this.entity[this.col?.fileName!] ? this.entity[this.col?.fileName!] : this.col?.field!);
                        } else {
                            showToast('warn', 'TELECHARGEMENT', 'Aucun fichier trouvé, merci de vérifier qu\'il existe.', this.messageService);
                        }
                        this.loader.delete(this.entity['id']);
                    },
                    error: () => {
                        showToast('warn', 'TELECHARGEMENT', 'Aucun fichier trouvé, merci de vérifier qu\'il existe.', this.messageService)
                        this.loader.delete(this.entity['id']);
                    }
                });
        }
    }
}
