import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-validate-input',
    templateUrl: './validate-input.component.html'
})
export class ValidateInputComponent {
    @Input() field: AbstractControl;
}
