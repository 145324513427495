<!-- Banner Area -->
<div class="banner-area-three banner-bg">
    <div id="carouselExampleCaptions" class="banner-content banner-content-three carousel slide"
         data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item" [ngClass]="{'active': i===0}"
                 *ngFor="let actualite of actualityList; let i=index">
                <img [src]="jupiterPortailService.buildUrl('photoHomePage', elasticIndexName.actualite, actualite.id, 'photoHomePage')"
                     class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                    <p>{{actualite.titre}}</p>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev carousel" type="button" data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next carousel" type="button" data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</div>
<!-- Banner Area End -->

<!-- Status Area -->
<div class="status-area">
    <div class="container status-bg">
        <div class="row">
            <div class="col-lg-4 col-md-6 pt-1" routerLink="/accueil/epargnant">
                <div class="status-card" id="start_item">
                    <h3>{{'Acceuil.cartes.epargnants' | translate}}</h3>
                    <a routerLink="/accueil/epargnant">
                        <img src="../../../../assets/img/user-group.png" class="rounded"
                             alt="">
                    </a>
                </div>
                <div class="status-bottom"></div>
            </div>

            <div class="col-lg-4 col-md-6 pt-1" routerLink="/accueil/emetteur">
                <div class="status-card" id="middle_item_one">
                    <h3>{{'Acceuil.cartes.emetteurs' | translate}}</h3>
                    <a routerLink="/accueil/emetteur"><img src="../../../../assets/img/investors.png" class="rounded"
                                                           alt=""></a>
                </div>
                <div class="status-bottom"></div>
            </div>

            <div class="col-lg-4 col-md-6 pt-1" routerLink="/accueil/intervenant">
                <div class="status-card" id="last">
                    <h3>{{'Acceuil.cartes.intervenants' | translate}}</h3>
                    <a routerLink="/accueil/intervenant"><img src="../../../../assets/img/intervenant-agree.png"
                                                              class="rounded" alt=""></a>
                </div>
                <div class="status-bottom"></div>
            </div>
        </div>
    </div>
</div>
<!-- Status Area End -->

<!-- Services Area -->
<div class="mt-45">
    <app-actuality></app-actuality>
    <app-pratiques></app-pratiques>
</div>
<!-- Services Area End -->

&nbsp;

