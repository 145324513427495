import { Component, EventEmitter, Input, Output } from '@angular/core';
import {ETableName, Pagination} from '../../utils/utils';

@Component({
    selector: 'app-jp-table',
    templateUrl: './jp-table.component.html',
    styleUrls: ['./jp-table.component.scss']
})
export class JpTableComponent {
    @Input() dataList: Array<any>;
    @Input() cols: Array<any> = [];
    @Input() totalRecords: number;
    @Input() rows: number;
    @Output() paginate = new EventEmitter<Pagination>();
    @Input() indexName: ETableName;
    @Input() defaultPagination = false;

    pageChanged(pagination: Pagination) {
        this.paginate.emit(pagination);
    }
}
