import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Actualite } from '../../../store/entity/entity.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { FetchActualityList, SetActualityList } from '../../../store/actions/jupiter-portail.actions';
import { CategorieActualite, ETableName, Pagination } from '../../common/utils/utils';
import { selectActualityList } from '../../../store/selectors/jupiter-portail.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { JupiterPortailService } from '../../../store/services/jupiter-portail.service';

@Component({
    selector: 'app-actualites',
    templateUrl: './actualites.component.html',
    styleUrls: ['./actualites.component.scss']
})
export class ActualitesComponent implements OnInit, OnDestroy {
    destroy$ = new Subject<boolean>();
    actualityList: Array<Actualite>;
    elasticIndexName = ETableName;
    totalRecords = 0;
    page = 0;
    size = 10;
    query: string;
    categorie: CategorieActualite;

    constructor(private store: Store<AppState>,
                public jupiterPortailService: JupiterPortailService,
                private router: Router, private activatedRoute: ActivatedRoute) {
        this.categorie = this.activatedRoute.snapshot.queryParams.categorie;
        this.query = this.activatedRoute.snapshot.queryParams.query;
    }

    ngOnInit(): void {
        this.store.pipe(select(selectActualityList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.actualityList = [...resultSet.data];
                        this.totalRecords = resultSet.totalRecord;
                    }
                }
            });

        this.loadData();
    }

    loadData() {
        this.store.dispatch(SetActualityList({data: [], totalRecord: 0}));

        if (this.categorie) {
            this.store.dispatch(FetchActualityList({
                page: this.page,
                size: this.size,
                categorie: this.categorie
            }));
        } else {
            this.store.dispatch(FetchActualityList({
                page: this.page,
                size: this.size,
                query: this.query
            }));
        }
    }

    pageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;

            this.loadData();
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
