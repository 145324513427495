import { Component, Input } from '@angular/core';
import { Actualite } from '../../../../store/entity/entity.model';

@Component({
    selector: 'app-actua-social-link',
    templateUrl: './actua-social-link.component.html',
    styleUrls: ['./actua-social-link.component.scss']
})
export class ActuaSocialLinkComponent {
    @Input() actualite: Actualite;
}
