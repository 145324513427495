<div class="side-bar-wrap">
    <div class="search-widget">
        <form #searchForm="ngForm" novalidate class="search-form" (ngSubmit)="reloadData()">
            <input type="search" class="form-control" minlength="3" name="query"
                   placeholder="Rechercher..." [(ngModel)]="query" required (input)="relaodAll()">
            <button type="submit" [disabled]="searchForm.form.invalid">
                <i class="bx bx-search"></i>
            </button>
        </form>
    </div>

    <div class="side-bar-widget">
        <h3 class="title">Catégories</h3>

        <div class="side-bar-categories">
            <ul>
                <li *ngFor="let item of byCategorieList">
                    <a href="javascript:void(0)" (click)="reloadData(item.categorie)">
                        {{categorieLabels[item.categorie]}}
                    </a>
                    <span>{{item.valeur}}</span>
                </li>
            </ul>
        </div>
    </div>
</div>
