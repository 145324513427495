<p-table #dt [value]="dataList" [columns]="cols" responsiveLayout="scroll"
         [paginator]="defaultPagination" [rows]="rows" [rowHover]="true" dataKey="id">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
                <th [style.width]="col.width" [pSortableColumn]="col.field"
                    *ngIf="col.sort">
                    <span>{{col.header}}</span>
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th [style.width]="col.width" *ngIf="!col.sort">
                    <span>{{col.header}}</span>
                </th>
            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-entity let-columns="columns">
        <tr>
            <ng-container *ngFor="let col of columns">
                <td [style.width]="col.width">
                    <span class="p-column-title">{{col.header}}</span>
                    <app-table-td [col]="col" [entity]="entity" [indexName]="indexName"></app-table-td>
                </td>
            </ng-container>
        </tr>
    </ng-template>
</p-table>
<app-pagination [rows]="rows" *ngIf="!defaultPagination"
                [totalRecords]="totalRecords"
                (paginate)="pageChanged($event)">
</app-pagination>
