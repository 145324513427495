import { AppState } from '../app.state';

export const selectAppelOffreList = (state: AppState) => state.jupiterPortailState.appelOffreResultSet;
export const selectActualityList = (state: AppState) => state.jupiterPortailState.actualiteResultSet;
export const selectActeurList = (state: AppState) => state.jupiterPortailState.acteurResultSet;
export const selectDetenteurList = (state: AppState) => state.jupiterPortailState.detenteurResultSet;
export const selectFormJuridiqueList = (state: AppState) => state.jupiterPortailState.formJuridiqueResultSet;
export const selectSectActiviteList = (state: AppState) => state.jupiterPortailState.sectActiviteResultSet;
export const selectEmetteurList = (state: AppState) => state.jupiterPortailState.emetteurResultSet;
export const selectNatureOperationList = (state: AppState) => state.jupiterPortailState.natureOperationResultSet;
export const selectOperationList = (state: AppState) => state.jupiterPortailState.operationResultSet;
export const selectTypeActeurList = (state: AppState) => state.jupiterPortailState.typeActeurResultSet;
export const selectPaysList = (state: AppState) => state.jupiterPortailState.paysResultSet;
export const selectTypeFcpList = (state: AppState) => state.jupiterPortailState.typeFcpResultSet;
export const selectFcpList = (state: AppState) => state.jupiterPortailState.fcpResultSet;
export const selectCacList = (state: AppState) => state.jupiterPortailState.cacResultSet;
export const selectTypeInstructionList = (state: AppState) => state.jupiterPortailState.typeInstructionResultSet;
export const selectActualityAlaUneList = (state: AppState) => state.jupiterPortailState.actualiteAlaUneResultSet;
export const selectActualityByCategorieList = (state: AppState) => state.jupiterPortailState.typeActualiteByCategResultSet;
export const selectEmpruntObligatoireList = (state: AppState) => state.jupiterPortailState.empruntObligatoireResultSet;
export const selectEpargneCollectiveList = (state: AppState) => state.jupiterPortailState.epargneCollectiveResultSet;
export const selectIndiceBoursierList = (state: AppState) => state.jupiterPortailState.indiceBoursierResultSet;
export const selectNombreActeurList = (state: AppState) => state.jupiterPortailState.nombreActeurResultSet;
export const selectOperationTitreList = (state: AppState) => state.jupiterPortailState.operationTitreResultSet;
export const selectSocieteCoteList = (state: AppState) => state.jupiterPortailState.societeCoteResultSet;
export const selectOperationBoursiereList = (state: AppState) => state.jupiterPortailState.operationBoursiereResultSet;
export const selectActeurFormulaireList = (state: AppState) => state.jupiterPortailState.formulaireResultSet;
export const selectDecisionList = (state: AppState) => state.jupiterPortailState.decisionsResultSet;
export const selectInstructionList = (state: AppState) => state.jupiterPortailState.instructionResultSet;
export const selectReglementList = (state: AppState) => state.jupiterPortailState.reglementResultSet;
export const selectCirculaireList = (state: AppState) => state.jupiterPortailState.circulairesResultSet;
