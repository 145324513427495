import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actualite } from '../../../store/entity/entity.model';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { JupiterPortailService } from '../../../store/services/jupiter-portail.service';
import { ETableName } from '../../common/utils/utils';
import {FileHandlingService} from "../../../store/services/file-handling-service";

@Component({
    selector: 'app-blog-details',
    templateUrl: './actuality-details.component.html',
    styleUrls: ['./actuality-details.component.scss']
})
export class ActualityDetailsComponent implements OnInit, OnDestroy {
    actuality: Actualite;
    destroy$ = new Subject<boolean>();
    elasticIndexName = ETableName;

    constructor(private activatedRoute: ActivatedRoute,
                public jupiterPortailService: JupiterPortailService,
                private fileService: FileHandlingService,
                private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(({actualite}) => {
            if (actualite) {
                this.actuality = actualite;
            }
        });
    }

    sanitize(data: string) {
        return this.sanitizer.bypassSecurityTrustUrl(data) as string;
    }

    downloadPdfFile() {
        this.fileService.downloadBinaryFile('application/pdf', this.actuality.doc,
            this.actuality.titre);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
