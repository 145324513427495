<!-- Top Header -->
<header #lankey class="top-header">
    <img src="assets/img/banner/Banner_29052023_V02.jpg" alt="">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="header-left">
                    <div class="header-left-card">
                        <ul>
                            <li pTooltip="Accueil" tooltipPosition="bottom">
                                <a class="navbar-brand" routerLink="/">
                                    <img src="assets/img/logo-crepmf.png" width="270" alt="Logo">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="header-right">
                    <div class="top-social-link">
                        <ul>
                            <li>
                                <a href="#" class="country-flag" pTooltip="Bénin" tooltipPosition="bottom">
                                    <img src="assets/img/flags/Benin_flag.png" alt="Bénin">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="country-flag" pTooltip="Burkina Faso" tooltipPosition="bottom">
                                    <img src="assets/img/flags/Burkina_flag.png" alt="Burkina Faso">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="country-flag" pTooltip="Côte d'Ivoire" tooltipPosition="bottom">
                                    <img src="assets/img/flags/Cote_Ivoire_flag.png" alt="Côte d'Ivoire">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="country-flag" pTooltip="Guinée Bissau" tooltipPosition="bottom">
                                    <img src="assets/img/flags/Guinea_Bissau_flag.png" alt="Guinée Bissau">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="country-flag" pTooltip="Mali" tooltipPosition="bottom">
                                    <img src="assets/img/flags/Mali_flag.png" alt="Mali">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="country-flag" pTooltip="Niger" tooltipPosition="bottom">
                                    <img src="assets/img/flags/Niger-flag.png" alt="Niger">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="country-flag" pTooltip="Sénégal" tooltipPosition="bottom">
                                    <img src="assets/img/flags/Senegal_flag.png" alt="Sénégal">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="country-flag" pTooltip="Togo" tooltipPosition="bottom">
                                    <img src="assets/img/flags/Togo_flag.png" alt="Togo">
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="language">
                        <p-dropdown [options]="langues" [(ngModel)]="lanKey" optionLabel="name"
                                    filterBy="name" [appendTo]="lankey" (ngModelChange)="translate.use(lanKey.code)">
                            <ng-template pTemplate="selectedItem">
                                <div class="lang-item lang-item-value" style="font-weight: 600" *ngIf="lanKey">
                                    <span>{{lanKey.name}}</span>
                                </div>
                            </ng-template>
                            <ng-template let-lang pTemplate="item">
                                <div class="lang-item">
                                    <span>{{lang.name}}</span>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Top Header End -->

<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <a class="navbar-brand hideLogo" routerLink="/">
                    <img src="assets/img/logo-crepmf.png" alt="Logo" height="64">
                </a>
                <ul class="navbar-nav m-auto">
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link dropdown-toggle text-uppercase">
                            AMF-UMOA
                            <i class="bx bx-chevron-down"></i>
                            <div class="nav-link-border"></div>
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/mot-president" class="nav-link" routerLinkActive="active"
                                   [routerLinkActiveOptions]="{exact: true}">
                                    {{'MenuBar.AmfUmuo.motPresident' | translate}}
                                </a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li class="nav-item">
                                <a routerLink="/historique" class="nav-link" routerLinkActive="active"
                                   [routerLinkActiveOptions]="{exact: true}">
                                    {{'MenuBar.AmfUmuo.historique' | translate}}
                                </a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li class="nav-item"><a routerLink="/organisation" class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}"
                            >{{'MenuBar.AmfUmuo.organisation' | translate}}</a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li class="nav-item"><a routerLink="/mission" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}"
                            >{{'MenuBar.AmfUmuo.missionCompet' | translate}}</a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li class="nav-item"><a routerLink="/valeurs" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}"
                            >{{'MenuBar.AmfUmuo.nosValeurs' | translate}}</a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li class="nav-item"><a routerLink="/relation" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}"
                            >{{'MenuBar.AmfUmuo.relationInstitut' | translate}}</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link dropdown-toggle text-uppercase">
                            {{'MenuBar.reglementation.title' | translate}}
                            <i class="bx bx-chevron-down"></i>
                            <div class="nav-link-border"></div>
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/reglementation/organisation" class="nav-link"
                                   routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{'MenuBar.reglementation.cadreLegal' | translate}}
                                </a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li class="nav-item">
                                <a routerLink="/reglementation/convention" class="nav-link"
                                   routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{'MenuBar.reglementation.textBase' | translate}}
                                </a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li class="nav-item">
                                <a routerLink="/reglementation/autre-reglement" class="nav-link"
                                   routerLinkActive="active"
                                   [routerLinkActiveOptions]="{exact: true}">
                                    {{'MenuBar.reglementation.autreActe' | translate}}
                                </a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link dropdown-toggle">
                                    {{'MenuBar.reglementation.textApplication.title' | translate}}
                                    <i class="bx bx-chevron-down"></i>
                                    <div class="nav-link-border"></div>
                                </a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a routerLink="/reglementation/decision" class="nav-link" routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">
                                            {{'MenuBar.reglementation.textApplication.decision' | translate}}
                                        </a>
                                    </li>
                                    <div class="dropdown-divider"></div>
                                    <li class="nav-item">
                                        <a routerLink="/reglementation/instruction" class="nav-link" routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">
                                            {{'MenuBar.reglementation.textApplication.instructions' | translate}}
                                        </a>
                                    </li>
                                    <div class="dropdown-divider"></div>
                                    <li class="nav-item">
                                        <a routerLink="/reglementation/circulaire" class="nav-link" routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">
                                            {{'MenuBar.reglementation.textApplication.circulaire' | translate}}
                                        </a>
                                    </li>
                                    <div class="dropdown-divider"></div>
                                    <li class="nav-item">
                                        <a routerLink="/reglementation/texte-abroge" class="nav-link" routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">
                                            {{'MenuBar.reglementation.textApplication.textAbroges' | translate}}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link dropdown-toggle text-uppercase">
                            {{'MenuBar.Publication.title' | translate}}
                            <i class="bx bx-chevron-down"></i>
                            <div class="nav-link-border"></div>
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link dropdown-toggle">
                                    {{'MenuBar.Publication.Documentation.title' | translate}} <i
                                        class="bx bx-chevron-down"></i>
                                </a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a routerLink="/publication/rapport" class="nav-link" routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">
                                            {{'MenuBar.Publication.Documentation.rapportAnnuel' | translate}}
                                        </a>
                                    </li>
                                    <div class="dropdown-divider"></div>
                                    <li class="nav-item">
                                        <a routerLink="/publication/etude" class="nav-link" routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">
                                            {{'MenuBar.Publication.Documentation.etudeAnalyse' | translate}}
                                        </a>
                                    </li>
                                    <div class="dropdown-divider"></div>
                                    <li class="nav-item">
                                        <a routerLink="/publication/communique" class="nav-link" routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">
                                            {{'MenuBar.Publication.Documentation.communique' | translate}}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link dropdown-toggle">
                                    {{'MenuBar.Publication.StatsMarche.title' | translate}} <i
                                        class="bx bx-chevron-down"></i>
                                </a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a routerLink="/publication/stats/primaire" class="nav-link"
                                           routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">
                                            {{'MenuBar.Publication.StatsMarche.marchePrimaire.title' | translate}}
                                        </a>
                                    </li>
                                    <div class="dropdown-divider"></div>
                                    <li class="nav-item">
                                        <a routerLink="/publication/stats/acteur" class="nav-link"
                                           routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">
                                            {{'MenuBar.Publication.StatsMarche.nombreActeur.title' | translate}}
                                        </a>
                                    </li>
                                    <div class="dropdown-divider"></div>
                                    <li class="nav-item">
                                        <a routerLink="/publication/stats/secondaire" class="nav-link"
                                           routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">
                                            {{'MenuBar.Publication.StatsMarche.marcheSecondaire.title' | translate}}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="others-options d-flex align-items-center">
                    <div class="option-item">
                        <i class="search-btn bx bx-search"></i>
                        <i class="close-btn bx bx-x"></i>
                        <div class="search-overlay search-popup">
                            <div class="search-box">
                                <form #searchForm="ngForm" class="search-form" (ngSubmit)="reloadData()">
                                    <input type="search" class="search-input" minlength="3" name="query"
                                           placeholder="Rechercher..." [(ngModel)]="query" required
                                           (input)="relaodAll()">
                                    <button class="search-button" type="submit" [disabled]="searchForm.form.invalid">
                                        <i class="bx bx-search"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="navbar-nav for-responsive">
                    <app-navbar-item (classApplied)="toggleClass()"></app-navbar-item>
                </ul>
            </div>
        </nav>

    </div>
</div>
<!-- End Navbar Area -->
