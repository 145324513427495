<p-panel>
    <ng-template pTemplate="header">
        {{title | translate}} ({{debutAnnee + ' - ' + finAnnee}})
    </ng-template>
    <div id="table-data">
        <app-table-stats [cols]="cols" [data]="data"></app-table-stats>
        <app-pagination [rows]="size" [totalRecords]="totalRecords" (paginate)="pageChange($event)"></app-pagination>
    </div>
    &nbsp;
    <div id="chart-data">
        <h6 class="text-center">{{chartTitle | translate}} ({{debutAnnee + ' - ' + finAnnee}})</h6>
        <app-chart-stats [type]="chartType" [chartCols]="chartCols" [data]="data"></app-chart-stats>
    </div>
</p-panel>

