import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { Subject, takeUntil } from 'rxjs';
import { Actualite } from '../../../../store/entity/entity.model';
import { CategorieActualite, ETableName, Pagination } from '../../../common/utils/utils';
import {
    selectCirculaireList,
    selectDecisionList,
    selectInstructionList,
    selectReglementList
} from '../../../../store/selectors/jupiter-portail.selectors';
import { FetchActualityList, SetActualityList } from '../../../../store/actions/jupiter-portail.actions';

@Component({
    selector: 'app-regle-text-abroge',
    templateUrl: './regle-text-abroge.component.html',
    styleUrls: ['./regle-text-abroge.component.scss']
})
export class RegleTextAbrogeComponent {
    title = 'MenuBar.reglementation.textApplication.textAbroges';

    destroy$ = new Subject<boolean>();
    decisionsList: Array<Actualite>;
    instructionList: Array<Actualite>;
    circulairesList: Array<Actualite>;
    reglementList: Array<Actualite>;
    totalRecords = 0;
    pageDecision = 0;
    pageInstruction = 0;
    pageReglement = 0;
    pageCircurlaire = 0;
    sizeDecisions = 20;
    sizeInstruction = 20;
    sizeReglement = 20;
    sizeCirculaire = 20;
    indexName = ETableName.actualite;
    cols: Array<any>;
    colsReglement: Array<any>;

    constructor(private store: Store<AppState>) {
        this.cols = [
            {field: 'titre', header: 'Ref.', sort: true, type: 'string', width: '20%'},
            {field: 'resume', header: 'Intitulé', sort: true, type: 'string', width: '60%'},
            {field: 'createdDate', header: 'Date', sort: true, type: 'date', width: '15%'},
            {field: 'titre', header: '', type: 'pdf', width: '5%', fileField: 'doc', fileName: 'titre'}
        ];

        this.colsReglement = [
            {field: 'titre', header: 'Dénomination', sort: true, type: 'string', width: '80%'},
            {field: 'createdDate', header: 'Date', sort: true, type: 'date', width: '15%'},
            {field: 'titre', header: '', type: 'pdf', width: '5%', fileField: 'doc', fileName: 'titre'}
        ];
    }

    ngOnInit() {
        this.store.pipe(select(selectDecisionList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.decisionsList = [...resultSet.data];
                        this.totalRecords = resultSet.totalRecord;
                    } else {
                        this.loadDecisions();
                    }
                }
            });

        this.store.pipe(select(selectInstructionList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.instructionList = [...resultSet.data];
                        this.totalRecords = resultSet.totalRecord;
                    } else {
                        this.loadInstructions();
                    }
                }
            });

        this.store.pipe(select(selectReglementList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.reglementList = [...resultSet.data];
                        this.totalRecords = resultSet.totalRecord;
                    } else {
                        this.loadReglements();
                    }
                }
            });

        this.store.pipe(select(selectCirculaireList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.circulairesList = [...resultSet.data];
                        this.totalRecords = resultSet.totalRecord;
                    } else {
                        this.loadCirculaires();
                    }
                }
            });
    }

    loadDecisions() {
        this.store.dispatch(SetActualityList({data: [], totalRecord: 0, abroge: true, categorie: CategorieActualite.Decision}));
        this.store.dispatch(FetchActualityList({
            size: this.sizeDecisions,
            page: this.pageDecision,
            categorie: CategorieActualite.Decision,
            abroge: true
        }));
    }

    loadInstructions() {
        this.store.dispatch(SetActualityList({data: [], totalRecord: 0, abroge: true, categorie: CategorieActualite.Instruction}));
        this.store.dispatch(FetchActualityList({
            size: this.sizeInstruction,
            page: this.pageInstruction,
            categorie: CategorieActualite.Instruction,
            abroge: true
        }));
    }

    loadReglements() {
        this.store.dispatch(SetActualityList({data: [], totalRecord: 0, abroge: true, categorie: CategorieActualite.Reglement}));
        this.store.dispatch(FetchActualityList({
            size: this.sizeReglement,
            page: this.pageReglement,
            categorie: CategorieActualite.Reglement,
            abroge: true
        }));
    }

    loadCirculaires() {
        this.store.dispatch(SetActualityList({data: [], totalRecord: 0, abroge: true, categorie: CategorieActualite.Circulaire}));
        this.store.dispatch(FetchActualityList({
            size: this.sizeCirculaire,
            page: this.pageCircurlaire,
            categorie: CategorieActualite.Circulaire,
            abroge: true
        }));
    }

    decisionPageChange(pagination: Pagination) {
        if (pagination) {
            this.pageDecision = pagination.page;
            this.sizeDecisions = pagination.rows;

            this.loadDecisions();
        }
    }

    instructionPageChange(pagination: Pagination) {
        if (pagination) {
            this.pageInstruction = pagination.page;
            this.sizeInstruction = pagination.rows;

            this.loadInstructions();
        }
    }

    reglementPageChange(pagination: Pagination) {
        if (pagination) {
            this.pageReglement = pagination.page;
            this.sizeReglement = pagination.rows;

            this.loadReglements();
        }
    }

    circulairePageChange(pagination: Pagination) {
        if (pagination) {
            this.pageCircurlaire = pagination.page;
            this.sizeCirculaire = pagination.rows;

            this.loadCirculaires();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
