<!-- Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{title | translate}}</h3>
            <ul>
                <li><a routerLink="/">{{'MenuBar.accueil' | translate}}</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li><a>{{title | translate}}</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->
<div class="terms-conditions-area pt-45">
    <div class="container">
        <div class="single-content text-justify">
            <h3>{{title| translate}}</h3>
        </div>
        <p-panel>
            <form #searchForm="ngForm">
                <div class="row" *ngIf="type === 'AGREMENT'">
                    <div class="form-group col-sm-6">
                        <label for="field_denomination">Dénomination</label>
                        <input type="text" pInputText id="field_denomination" name="denomination"
                               class="form-control" [(ngModel)]="acteurCriteria.denomination">

                    </div>
                    <div class="form-group col-sm-6">
                        <label for="field_numeroAgrement">N° Agrement</label>
                        <input type="text" pInputText id="field_numeroAgrement" name="numeroAgrement"
                               class="form-control" [(ngModel)]="acteurCriteria.numeroAgrement">

                    </div>
                </div>
                <div *ngIf="type === 'CARTEPRO'">
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <label for="field_nom">Nom</label>
                            <input type="text" pInputText id="field_nom" name="nom"
                                   class="form-control" [(ngModel)]="carteCriteria.nom">

                        </div>
                        <div class="form-group col-sm-6">
                            <label for="field_prenom">Prénom</label>
                            <input type="text" pInputText id="field_prenom" name="prenom"
                                   class="form-control" [(ngModel)]="carteCriteria.prenom">

                        </div>
                    </div>
                    &nbsp;
                    <div class="form-group col-sm-12">
                        <label for="field_prenom">Numéro</label>
                        <input type="text" pInputText id="field_numero" name="numero"
                               class="form-control" [(ngModel)]="carteCriteria.numero">

                    </div>
                </div>
                <div class="mt-20 pb-5">
                    <div style="float:right;">
                        <button type="button" label="Vérifier" pRipple id="btn-verif" [disabled]="!canDoSearch"
                                icon="pi pi-search" class="btn-style" pButton (click)="searchData()">
                        </button>
                    </div>
                </div>
            </form>
        </p-panel>
        &nbsp;
        <p-table #dt [value]="searchedEntityList" [columns]="cols" responsiveLayout="scroll"
                 [rows]="10" [paginator]="true" [rowHover]="true" dataKey="id">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <ng-container *ngFor="let col of columns">
                        <th [style.width]="col.width" [pSortableColumn]="col.field"
                            *ngIf="col.sort">
                            <span>{{col.header}}</span>
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th [style.width]="col.width" *ngIf="!col.sort">
                            <span>{{col.header}}</span>
                        </th>
                    </ng-container>
                    <th [style.width]="'5%'"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-entity let-columns="columns">
                <tr>
                    <ng-container *ngFor="let col of columns">
                        <td [style.width]="col.width">
                            <span class="p-column-title">{{col.header}}</span>
                            <app-table-td [col]="col" [entity]="entity"></app-table-td>
                        </td>
                    </ng-container>
                    <td [style.width]="'5%'">
                        <div class="flex justify-content-center">
                            <button pButton pRipple icon="pi pi-eye" title="Détails"
                                    class="p-button-rounded btn-style"
                                    (click)="displayDialog(entity)">
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="alert alert-danger" *ngIf="!searchedEntity && requestEnded">
            {{noDataTitle | translate}}
        </div>
    </div>

    <p-dialog #dd [(visible)]="display" [style]="{width: '45vw'}"
              header="Détails" [modal]="true" [closable]="false">
        <form>
            <app-verif-details [title]="title"
                               [defaultPagination]="true"
                               [tab0Cols]="detCols"
                               [searchedEntity]="searchedEntity">
            </app-verif-details>
            <p-footer>
                <div class="mt-20 pb-5">
                    <div style="float:right;">
                        <button type="button" pButton icon="pi pi-times" class="btn-style" pRipple
                                label="Quitter" (click)="displayDialog()" id="btn-leave">
                        </button>
                    </div>
                </div>
            </p-footer>
        </form>
    </p-dialog>
</div>
