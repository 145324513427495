import { Component, OnInit } from '@angular/core';
import { ELangue } from '../utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FetchActualityList, SetActualityList } from '../../../store/actions/jupiter-portail.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    langues: any;
    classApplied = false;
    lanKey: { name: 'FR', code: ELangue.fr };
    query: string;
    isActualityUrl = false;

    constructor(public translate: TranslateService,
                private store: Store<AppState>,
                private router: Router) {
    }

    ngOnInit(): void {
        this.langues = [
            {name: 'FR', code: ELangue.fr},
            {name: 'EN', code: ELangue.en},
            {name: 'PT', code: ELangue.pt}
        ];
    }

    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    reloadData() {
        if (this.query && this.query.length > 0) {
            if (this.isActualityUrl) {
                this.loadAll();
            } else {
                this.router.navigate(['actualities'], {
                    queryParams: {query: this.query}
                }).then(() => this.isActualityUrl = true);
            }
        }
    }

    relaodAll() {
        if (!this.query || this.query.length === 0) {
            this.loadAll();
        }
    }

    loadAll() {
        this.store.dispatch(SetActualityList({data: [], totalRecord: 0}));
        this.store.dispatch(FetchActualityList({query: this.query, size: 10, page: 0}));
    }
}
