import { Component } from '@angular/core';

@Component({
    selector: 'app-emetteur-doc',
    templateUrl: './emetteur-doc.component.html',
    styleUrls: ['./emetteur-doc.component.scss']
})
export class EmetteurDocComponent {

}
