<!-- Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'MenuBar.reglementation.cadreLegal' | translate}}</h3>
            <ul>
                <li><a routerLink="/">{{'MenuBar.accueil' | translate}}</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li><a>{{'MenuBar.reglementation.cadreLegal' | translate}}</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-45">
    <div class="container">
        <div class="single-content text-justify">
            <h3>{{'MenuBar.reglementation.cadreLegal' | translate}}</h3>
            <p class="mb-2">
                L’Autorité des Marchés Financiers de l’UMOA (AMF-UMOA), anciennement appelée le
                Conseil Régional de l’Epargne Publique et des Marchés Financiers (CREPMF),
                est l’Organe de régulation du Marché Financier Régional de l’UEMOA, créé le 03 juillet 1996,
                par une Convention signée entre les sept (07) Etats de l’Union Monétaire Ouest Africaine (UMOA).
            </p>
            <p class="mb-2">
                Cette convention est complétée par un avenant du 3 juillet 1997 intervenu à la suite de l’adhésion de
                la Guinée-Bissau à la zone franc  de l’Union. Sa mission principale est d’organiser et de contrôler
                l’appel public à l’Epargne d’une part, et d’habiliter et de contrôler les intervenants sur
                le Marché Financier Régional d’autre part.
            </p>
            <p class="mb-2">
                Il convient de rappeler que l’Organe est consacré dans le  Traité UMOA, instrument
                juridique suprême duquel découle la Convention.
                <br>
                Au plan institutionnel, l’Annexe, qui en fait partie intégrante de la Convention, régit la composition,
                l’organisation, le fonctionnement ainsi que  les  attributions de l’Organe.
            </p>
            <p>
                Au plan opérationnel, le Règlement Général régit l’Organisation, le fonctionnement et le  contrôle du Marché Financier.
                <br>
                Par ailleurs, certains textes postérieurs trouvent leur application dans la régulation des activités du Marché Financier, tels que :
            </p>
        </div>

        <div class="single-content">
            <ul class="mt-10">
                <li>le Règlement relatif aux obligations sécurisées dans l’UEMOA ;</li>
                <li>le Règlement relatif aux fonds communs de titrisation et aux opérations de titrisation ;</li>
                <li>le Règlement relatif aux titres islamiques, aux sociétés d’émission de SUKUK autogérées et
                    aux Fonds d’émission de SUKUK;</li>
                <li>
                    la Décision du Conseil des Ministres relative aux dispositifs des sanctions pécuniaires applicables
                    sur le Marché Financier Régional ;
                </li>
                <li>la Décision relative à l’application des normes IFRS et aux entités faisant appel public
                    à l’épargne dans l’UMOA;</li>
                <li>•	la Décision portant adoption de la loi uniforme relative aux infractions boursières;</li>
                <li>les Instructions et décisions prises par L’AMF-UMOA;</li>
                <li>les Circulaires prises par le Secrétaire Général.</li>
            </ul>
            <p class="mb-2">
                Enfin, on peut citer certains textes qui régissent le fonctionnement interne
                de l’AMF-UMOA à l’instar du Règlement Financier.
            </p>
            Toutefois, la présente présentation concerne essentiellement les textes suivants :
            <ul class="mt-20">
                <li>la Convention et son Annexe ;</li>
                <li>le Règlement Général ;</li>
                <li>la Décision portant dispositif des sanctions pécuniaires ;</li>
                <li>le règlement Financier ;</li>
                <li>les perspectives d’évolution du cadre légal et réglementaire du Marché Financier Régional.</li>
            </ul>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
