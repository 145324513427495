<!-- Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'Acceuil.cartes.epargnants' | translate}}</h3>
            <ul>
                <li><a routerLink="/">{{'MenuBar.accueil' | translate}}</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li><a>{{'Acceuil.cartes.epargnants' | translate}}</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->
<div class="blog-area-two pt-20 pb-20">
    <div class=" card container p-2">
        <p-tabView styleClass="tabview-custom">
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-sun">&nbsp;</i>
                    <span>Guide de l'investisseur</span>
                </ng-template>
                <app-epargnant-guide></app-epargnant-guide>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-copy">&nbsp;</i>
                    <span>Glossaire / Lexique</span>
                </ng-template>
                <app-epargnant-glossaire></app-epargnant-glossaire>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-money-bill">&nbsp;</i>
                    <span>Pratiques</span>
                </ng-template>
                <app-epargnant-pratiques></app-epargnant-pratiques>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
