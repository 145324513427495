import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { codeNatureList, ETableName, Pagination } from '../../../common/utils/utils';
import { Subject, takeUntil } from 'rxjs';
import { Operation } from '../../../../store/entity/entity.model';
import { SelectItem } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { selectNatureOperationList, selectOperationList } from '../../../../store/selectors/jupiter-portail.selectors';
import { OperationCriteria } from '../../../../store/services/request-params';
import {
    FetchNatureOperationList,
    FetchOperationList,
    SetOperationList
} from '../../../../store/actions/jupiter-portail.actions';

@Component({
    selector: 'app-emetteur-operation',
    templateUrl: './emetteur-operation.component.html',
    styleUrls: ['./emetteur-operation.component.scss']
})
export class EmetteurOperationComponent implements OnInit, OnDestroy {
    natureOperationList: Array<SelectItem>;
    anneeList = [];
    codeNatureList = codeNatureList;
    groupedData: Array<any> = [];
    destroy$ = new Subject<boolean>();

    operationList: Array<Operation>;
    cols: Array<any>;
    nowYear = new Date().getFullYear();
    criteria: OperationCriteria = {};
    indexName = ETableName.operation;
    totalRecords = 0;
    page = 0;
    size = 50;

    constructor(private store: Store<AppState>) {
        this.store.dispatch(FetchNatureOperationList());
        this.cols = [
            {field: 'emetteurDesignation', header: 'Emetteur', type: 'string', width: '15%', sort: true},
            {
                field: 'noteInfo', header: 'Note Info', type: 'pdf', width: '13%',
                sort: true, fileField: 'noteInfo', showText: true
            },
            {
                field: 'visa', header: 'Visa', type: 'pdf', width: '13%',
                sort: true, fileField: 'decision', showText: true
            },
            {field: 'nomination', header: 'Dénomination', type: 'string', width: '20%', sort: true},
            {field: 'dateVisa', header: 'Date Visa', type: 'date', sort: true, width: '11%'},
            {field: 'mtDemande', header: 'Mt dem.', type: 'number', sort: true, width: '13%'},
            {field: 'mtSouscrit', header: 'Mt sousc.', type: 'number', sort: true, width: '15%'}
        ];
    }

    ngOnInit(): void {
        for (let i = 1997; i <= this.nowYear; i++) {
            this.anneeList.push({value: i, label: i});
        }

        this.store.pipe(select(selectNatureOperationList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.natureOperationList = [];
                        resultSet.map(item => this.natureOperationList.push({label: item.lib, value: item.id}));
                    }
                }
            });

        this.store.pipe(select(selectOperationList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    this.operationList = [];
                    this.groupedData = [];

                    if (resultSet) {
                        this.operationList = [...resultSet.data];
                        this.totalRecords = resultSet.totalRecord;
                        this.buildViewList();
                    }
                }
            });

    }

    searchData() {
        this.store.dispatch(SetOperationList({data: [], totalRecord: 0}));

        if (this.criteria.natureId || this.criteria.annee) {
            this.store.dispatch(FetchOperationList({
                ...this.criteria,
                size: this.size,
                page: this.page
            }));
        } else {
            this.store.dispatch(FetchOperationList({
                ...this.criteria,
                size: this.size,
                page: this.page
            }));
        }
    }

    buildViewList() {
        const data = _.groupBy(this.operationList, 'natureCode');
        this.codeNatureList.forEach(item => {
            this.groupedData.push({codeVisa: item.code, title: item.title, desc: item.desc, isGroup: true});
            const items = data[item.code];
            if (items) {
                this.groupedData = this.groupedData.concat(items);
            }
        });
    }

    pageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;

            this.searchData();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
