import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-epargnant-pratiques',
  templateUrl: './epargnant-pratiques.component.html',
  styleUrls: ['./epargnant-pratiques.component.scss']
})
export class EpargnantPratiquesComponent implements OnInit {

    apiLoaded = false;
    constructor() { }

    ngOnInit(): void {
        if (!this.apiLoaded) {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            document.body.appendChild(tag);
            this.apiLoaded = true;
        }
    }

}
