<div class="table-responsive">
    <table role="table" class="d-table table table-hover">
        <thead>
        <tr role="row">
            <th role="cell">Rubrique</th>
            <th role="cell" *ngFor="let row of data">{{row.annee}}</th>
        </tr>
        </thead>
        <tbody>
        <tr role="row" *ngFor="let col of cols">
            <td role="cell">{{col.header}}</td>
            <td role="cell" *ngFor="let row of data">{{row[col.field]}}</td>
        </tr>
        <tr role="row">
            <td role="cell"><b>TOTAL</b></td>
            <td role="cell" *ngFor="let value of totalData"><b>{{value}}</b></td>
        </tr>
        </tbody>
    </table>
</div>
