import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AcceptFileTypeList, Pagination, showToast } from '../../utils/utils';
import { ActeurFormulaire } from '../../../../store/entity/entity.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { Subject, takeUntil } from 'rxjs';
import { selectActeurFormulaireList } from '../../../../store/selectors/jupiter-portail.selectors';
import { MessageService } from 'primeng/api';
import { ActeurFormsCreteria } from '../../../../store/services/request-params';
import {
    CreateActeurFormulaire,
    FetchActeurFormulaireList,
    SetActeurFormulaireList
} from '../../../../store/actions/jupiter-portail.actions';
import { FileUpload } from 'primeng/fileupload';
import { JupiterPortailService } from '../../../../store/services/jupiter-portail.service';
import { FileHandlingService } from '../../../../store/services/file-handling-service';

@Component({
    selector: 'app-acteur-forms',
    templateUrl: './acteur-forms.component.html',
    styleUrls: ['./acteur-forms.component.scss']
})
export class ActeurFormsComponent implements OnInit, OnDestroy {
    @Input() entity: any;
    @Input() typeEntity: 'idActeur' | 'idEmetteur' = 'idActeur';
    dataList: Array<ActeurFormulaire>;
    cols: Array<any> = [];
    totalRecords: number;
    rows = 10;
    page = 0;
    display = false;
    destroy$ = new Subject<boolean>();
    currentAction: 'LOAD_ALL' | 'UPLOAD_FORM';
    codeSecret: string;
    formSaved: ActeurFormulaire = {};
    criteria: ActeurFormsCreteria;
    docType: string;
    typeFormsList = AcceptFileTypeList;

    constructor(private store: Store<AppState>,
                private fileService: FileHandlingService,
                private jupiterPortailService: JupiterPortailService,
                private messageService: MessageService) {
        this.store.dispatch(SetActeurFormulaireList({data: [], totalRecord: 0}));
        this.cols = [
            {field: 'fileName', header: 'Formulaire', type: 'string', sort: true, width: '90%'},
            {field: 'doc', header: '', type: 'anyDoc', sort: false, width: '10%'}
        ];
    }

    ngOnInit(): void {
        this.store.pipe(select(selectActeurFormulaireList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    this.dataList = [];
                    if (resultSet) {
                        this.totalRecords = resultSet.totalRecord;
                        this.dataList = resultSet.data;
                    }
                }
            });
    }

    displayDialog(action?: any) {
        if (this.display) {
            this.display = false;
            this.currentAction = undefined;
        } else {
            this.currentAction = action;
            this.display = true;
        }
    }

    doAction() {
        this.buildCriteria();

        if (this.currentAction === 'LOAD_ALL') {
            this.store.dispatch(SetActeurFormulaireList({data: [], totalRecord: 0}));
            this.store.dispatch(FetchActeurFormulaireList(this.criteria));

        } else if (this.currentAction === 'UPLOAD_FORM') {

            this.formSaved.codeSecret = this.codeSecret;
            this.formSaved[this.typeEntity] = this.entity.id;
            this.store.dispatch(CreateActeurFormulaire({entity: this.formSaved, criteria: this.criteria}));
        }
        this.displayDialog();
    }

    removeImage(uploader: FileUpload) {
        uploader.clear();
        this.formSaved = {...this.formSaved, doc: null, fileName: null, extension: null};
    }

    onFileUpload(fEvent: any) {
        const file: File = fEvent.files[0];
        this.formSaved = {...this.formSaved, doc: null, fileName: file.name, extension: file.type};

        const reader = new FileReader();
        reader.onload = () => {
            const result: any = reader.result;
            this.formSaved.doc = result.split(',')[1];
        };
        reader.readAsDataURL(file);
    }

    buildCriteria() {
        this.criteria = {
            page: this.page,
            size: this.rows,
            codeSecret: this.codeSecret
        };
        this.criteria[this.typeEntity] = this.entity.id;

    }

    pageChanged(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.rows = pagination.rows;

            this.currentAction = 'LOAD_ALL';
            this.doAction();
        }
    }

    get actionData(): any {
        switch (this.currentAction) {
            case 'LOAD_ALL':
                return {
                    label: 'Charger',
                    icon: 'pi pi-refresh'
                };
            case 'UPLOAD_FORM':
                return {
                    label: 'Sauvegarder',
                    icon: 'pi pi-check'
                };
        }
    }

    downloadPdfFile(entity: ActeurFormulaire) {
        if (entity.doc) {
            this.fileService.downloadBinaryFile(entity.extension, entity.doc, entity.fileName);
        } else {
            showToast('warn', 'TELECHARGEMENT', 'Aucun fichier trouvé, merci de vérifier qu\'il existe.', this.messageService);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
