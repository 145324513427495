import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-table-stats',
    templateUrl: './table-stats.component.html',
    styleUrls: ['./table-stats.component.scss']
})
export class TableStatsComponent implements OnInit {
    @Input() data: Array<any>;
    @Input() cols: Array<any> = [];
    totalData: Array<number>;

    constructor() {
    }

    ngOnInit(): void {
        this.buildTotalData();
    }

    buildTotalData() {
        const group = _.groupBy(this.data, 'annee');
        this.totalData = [];

        this.data.map(item => item.annee).forEach(year => {
            const row = group[year][0];
            let val = 0;
            this.cols.forEach(col => {
                val += row[col.field];
            });
            this.totalData.push(Number(val.toFixed(2)));
        });
    }

}
