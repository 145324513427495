import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { Actualite } from '../../../../store/entity/entity.model';
import { selectActualityList } from '../../../../store/selectors/jupiter-portail.selectors';
import { Subject, takeUntil } from 'rxjs';
import { ETableName, sendFluxRss, showToast, StatusEnum } from '../../utils/utils';
import { MessageService } from 'primeng/api';
import { FetchActualityList } from '../../../../store/actions/jupiter-portail.actions';
import { JupiterPortailService } from '../../../../store/services/jupiter-portail.service';

@Component({
    selector: 'app-actuality',
    templateUrl: './actuality.component.html',
    styleUrls: ['./actuality.component.scss']
})
export class ActualityComponent implements OnInit, OnDestroy {
    actualityList: Array<Actualite>;
    destroy$ = new Subject<boolean>();
    showRssInput = false;
    elasticIndexName = ETableName;
    isDisabled = true;

    constructor(private store: Store<AppState>,
                public jupiterPortailService: JupiterPortailService,
                private messageService: MessageService) {
        this.store.dispatch(FetchActualityList({page: 0, size: 6}));
    }

    ngOnInit(): void {
        this.isDisabled = true;
        this.store.pipe(select(selectActualityList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.actualityList = [...resultSet.data];
                    }
                }
            });
    }

    showResponse(event) {
        //call to a backend to verify against recaptcha with private key
        if (event?.response) {
            this.isDisabled = false;
        }
    }

    saveEmail(email: HTMLInputElement) {
        if (email.validity.valid) {
            sendFluxRss(email.value, this.store);
            this.showRssInput = false;
        } else {
            showToast(StatusEnum.warning, 'Avertissement', 'Vous devez renseinger un email valide.', this.messageService);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
