import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-verif-details',
    templateUrl: './verif-details.component.html',
    styleUrls: ['./verif-details.component.scss']
})
export class VerifDetailsComponent {
    @Input() title: string;
    @Input() tab0Cols: Array<any>;
    @Input() tab1Cols: Array<any>;
    @Input() tab2Cols: Array<any>;

    @Input() field1: string;
    @Input() title1: string;

    @Input() field2: string;
    @Input() title2: string;

    @Input() title3: string;

    @Input() searchedEntity: any;
    @Input() addTabView: boolean;
    @Input() defaultPagination = false;

    @Input() typeEntity: 'idActeur' | 'idEmetteur' = 'idActeur';
    @Input() justForms = false;
}
