import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { CategorieActualite, ETableName, Pagination } from '../../../common/utils/utils';
import { Subject, takeUntil } from 'rxjs';
import { Actualite } from '../../../../store/entity/entity.model';
import { selectActualityList } from '../../../../store/selectors/jupiter-portail.selectors';
import { FetchActualityList, SetActualityList } from '../../../../store/actions/jupiter-portail.actions';

@Component({
    selector: 'app-communique',
    templateUrl: './pub-communique.component.html',
    styleUrls: ['./pub-communique.component.scss']
})
export class PubCommuniqueComponent implements OnInit, OnDestroy {
    title = 'MenuBar.Publication.Documentation.communique';

    categorie = CategorieActualite.Communique;
    destroy$ = new Subject<boolean>();
    actualities: Array<Actualite>;
    cols: Array<any>;
    totalRecords = 0;
    page = 0;
    size = 10;
    indexName = ETableName.actualite;

    constructor(private store: Store<AppState>) {
        this.loadData();
        this.cols = [
            {field: 'titre', header: 'Ref.', sort: true, type: 'string', width: '20%'},
            {field: 'resume', header: 'Intitulé', sort: true, type: 'string', width: '60%'},
            {field: 'createdDate', header: 'Date', sort: true, type: 'date', width: '15%'},
            {field: 'titre', header: '', type: 'pdf', width: '5%', fileField: 'doc', fileName: 'titre'}
        ];
    }

    ngOnInit() {
        this.store.pipe(select(selectActualityList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.actualities = [...resultSet.data];
                        this.totalRecords = resultSet.totalRecord;
                    }
                }
            });
    }

    loadData() {
        this.store.dispatch(SetActualityList({data: [], totalRecord: 0}));
        this.store.dispatch(FetchActualityList({
            size: this.size,
            page: this.page,
            categorie: this.categorie
        }));
    }

    pageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;

            this.loadData();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
