<div *ngIf="searchedEntity" class="list-panel pr-3">
    <p-tabView styleClass="tabview-custom" *ngIf="addTabView">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <span>Fiche</span>
            </ng-template>
            <ng-container *ngFor="let col of tab0Cols">
                <div class="row mb-2">
                    <label class="col-sm-3 row-header">{{col.header}}</label>
                    <label class="col-sm-8 row-content">
                        <span *ngIf="col.type === 'date'">{{searchedEntity[col.field] | date: 'dd-MM-YYYY'}}</span>
                        <span *ngIf="col.type !== 'date'">{{searchedEntity[col.field]}}</span>
                    </label>
                </div>
            </ng-container>
        </p-tabPanel>
        <ng-container *ngIf="!justForms">
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <span>{{title1}}</span>
                </ng-template>
                <app-jp-table [dataList]="searchedEntity[field1]" [cols]="tab1Cols"
                              [defaultPagination]="defaultPagination"
                              [rows]="10" [totalRecords]="searchedEntity[field1]?.length">
                </app-jp-table>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <span>{{title2}}</span>
                </ng-template>
                <app-jp-table [dataList]="searchedEntity[field2]"
                              [cols]="tab2Cols" [defaultPagination]="defaultPagination"
                              [rows]="10" [totalRecords]="searchedEntity[field2]?.length">
                </app-jp-table>
            </p-tabPanel>
        </ng-container>
        <p-tabPanel *ngIf="title3">
            <ng-template pTemplate="header">
                <span>{{title3}}</span>
            </ng-template>
            <app-acteur-forms [entity]="searchedEntity" [typeEntity]="typeEntity"></app-acteur-forms>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <span>Tarif</span>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
    <ng-container *ngIf="!addTabView">
        <ng-container *ngFor="let col of tab0Cols">
            <div class="row mb-2">
                <label class="col-sm-3 row-header">{{col.header}}</label>
                <label class="col-sm-8 row-content">
                    <span *ngIf="col.type === 'date'">{{searchedEntity[col.field] | date: 'dd-MM-YYYY'}}</span>
                    <span *ngIf="col.type !== 'date'">{{searchedEntity[col.field]}}</span>
                </label>
            </div>
        </ng-container>
    </ng-container>
</div>
