<!-- Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{title | translate}}</h3>
            <ul>
                <li><a routerLink="/">{{'MenuBar.accueil' | translate}}</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li><a>{{title | translate}}</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->
<div class="terms-conditions-area pt-45">
    <div class="container">
        <div class="single-content text-justify">
            <h3>{{title| translate}}</h3>
        </div>

        <div class="single-content text-justify">
            <h6 class="pb-20">
                <i class="pi pi-chevron-right"></i>
                {{subTitle | translate}}
            </h6>

            <ng-container *ngFor="let data of empruntObligatoireList">
                <app-stats-panel [id]="data.id" [chartType]="'line'"
                                 [cols]="data.cols" [debutAnnee]="data.debutAnnee"
                                 [data]="data.data" [finAnnee]="data.finAnnee"
                                 [title]="empruntObligatoire"
                                 [chartTitle]="empruntObligatoire"
                                 [chartCols]="empruntChartCols"
                                 [totalRecords]="empruntTotalRecords" [size]="size"
                                 (paginate)="empruntPageChange($event)">
                </app-stats-panel>
                &nbsp;
            </ng-container>
            <ng-container *ngFor="let data of titreCapitalList">
                <app-stats-panel [id]="data.id" [title]="titreCapital" [chartType]="'line'"
                                 [cols]="data.cols" [debutAnnee]="data.debutAnnee"
                                 [data]="data.data" [finAnnee]="data.finAnnee"
                                 [chartTitle]="titreCapital" [size]="size"
                                 [totalRecords]="operationTotalRecords"
                                 [chartCols]="titreCapitalCols"
                                 (paginate)="titrePageChange($event)">
                </app-stats-panel>
                &nbsp;
            </ng-container>
            <ng-container *ngFor="let data of epargneCollectiveList">
                <app-stats-panel [id]="data.id" [cols]="data.cols" [chartType]="'line'"
                                 [debutAnnee]="data.debutAnnee"
                                 [data]="data.data" [finAnnee]="data.finAnnee"
                                 [title]="epargneCollective"
                                 [chartTitle]="epargneCollective"
                                 [totalRecords]="epargneTotalRecords" [size]="size"
                                 [chartCols]="epargneCollectiveCols"
                                 (paginate)="epargnePageChange($event)">
                </app-stats-panel>
                &nbsp;
            </ng-container>
        </div>
    </div>
</div>
