<!-- Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'Acceuil.cartes.emetteurs' | translate}}</h3>
            <ul>
                <li><a routerLink="/">{{'MenuBar.accueil' | translate}}</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li><a>{{'Acceuil.cartes.emetteurs' | translate}}</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->
<div class="blog-area-two pt-20 pb-20">
    <div class=" card container p-2">
        <p-tabView styleClass="tabview-custom" (activeIndexChange)="onTabChange($event)">
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-users">&nbsp;</i>
                    <span>Emetteurs</span>
                </ng-template>
                <app-emetteur-list></app-emetteur-list>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-dollar">&nbsp;</i>
                    <span>Opérations soumises au visa de l'AMF-UMOA</span>
                </ng-template>
                <app-emetteur-operation></app-emetteur-operation>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-sun">&nbsp;</i>
                    <span>Guide de l'Emetteur</span>
                </ng-template>
                <app-emetteur-guide></app-emetteur-guide>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
