<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-20">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Comment lever des ressources sur le marché financier régional ?</h3>
            <p>
                La levée de ressources sur un marché financier s’opère sur le marché primaire à travers les émissions
                d’obligations ou d’actions.
            </p>
        </div>

        <div class="single-content">
            <p-accordion>
                <p-accordionTab
                        header="Qui peut émettre sur le marché financier de l’UMOA ?"
                        [selected]="true">
                    <div class="single-content text-justify">
                        <p>Conformément aux textes en vigueur, peuvent émettre sur le marché financier régional : </p>

                        <ul>
                            <li>les Etats, les collectivités locales et les services nationaux en charge des opérations
                                de privatisation,
                            </li>
                            <li>les sociétés établies sur le territoire de l’UMOA,</li>
                            <li>les autres entités.</li>
                        </ul>
                        <p>S’agissant des sociétés émettrices, l’article 780 de l’Acte Uniforme relatif au droit des
                            sociétés commerciales et du groupement d’intérêt économique précise que ‘‘l’émission
                            d’obligations n’est permise qu’aux sociétés anonymes et aux groupements d’intérêt économique
                            constitués de sociétés anonymes...’’. </p>

                        <p>Par ailleurs, les entités non-résidentes peuvent, au terme de l’article 176 du Règlement
                            Général, solliciter le public de l’UMOA en vue de proposer l’acquisition de produits de
                            placement. L’opération initiée est soumise à l’autorisation préalable du Conseil Régional,
                            sous réserve de l’avis conforme de l’autorité en charge du contrôle des changes de chacun
                            des pays concernés.</p>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Quels sont les modes de financement ?">
                    <div class="single-content text-justify">
                        <p>
                            Deux principaux modes de financement sont utilisés sur le marché financier régional. Les
                            émetteurs peuvent en effet, intervenir sur le marché financier par l’émission d’actions ou
                            d’obligations par appel public à l’épargne ou par l’émission d’obligation par placement
                            privé.
                        </p>
                        <h3 class="mt-20">A - L’appel public à l’épargne</h3>
                        <p>Selon l’article 19 de l’Annexe à la Convention portant création du Conseil Régional, sont
                            réputé faire appel public à l’épargne, les Etats ou toute autre entité :
                        </p>
                        <ul>
                            <li>dont les titres sont disséminés au travers d’un cercle de cent personnes au moins,
                                n’ayant aucun lien juridique entre elles,
                            </li>
                            <li>qui, pour offrir au public de l’UMOA des produits de placement, ont recours à des
                                procédés quelconques de sollicitation du public, au titre desquels figurent notamment la
                                publicité et le démarchage,
                            </li>
                            <li>dont les titres sont inscrits à la cote de la Bourse Régionale.</li>
                        </ul>

                        <h3 class="mt-20">B - L’émission obligataire par placement privé</h3>
                        <p>
                            Constitue un emprunt obligataire par placement, conformément à l’article 1 de l’Instruction
                            n° 30/2001 relative aux conditions d’enregistrement des emprunts obligataires par placement
                            privé :
                        </p>
                        <ul>
                            <li>l’émission d’obligations réalisée sans publicité et dont les titres ne feront pas
                                l’objet d’inscription à la cote de la BRVM,
                            </li>
                            <li>l’émission d’obligations placées au travers d’un cercle d’au plus cent investisseurs
                                qualifiés agissant pour leur propre compte. Un investisseur qualifié est une personne
                                morale à même d’appréhender les mérites et les risques inhérents aux opérations sur
                                instruments financiers,
                            </li>
                            <li>l’émission d’obligations dont le nominal minimum est de 50 millions FCFA.</li>
                        </ul>
                    </div>
                </p-accordionTab>
                <p-accordionTab
                        header="Quelles sont les conditions de réalisation d’une opération financière sur le marché financier de l’UMOA ?">
                    <div class="single-content text-justify">
                        <p>Toute levée de fonds sur le marché financier de l’UMOA est soumise à l’autorisation préalable
                            du Conseil Régional exprimée par un visa pour les opérations réalisées par appel public à
                            l’épargne ou par l’attribution d’un numéro d’enregistrement pour les emprunts obligataires
                            par placement privé.</p>
                        <p>Cependant, au terme de l’article 136 du Règlement Général, la formalité de visa préalable du
                            Conseil Régional n’est pas exigée pour les emprunts émis ou garantis par un Etat ou un
                            groupe d’Etats de l’Union.</p>
                        <p>L’obtention de l’autorisation préalable du Conseil Régional suppose que l’émetteur a
                            satisfait aux exigences réglementaires en vigueur notamment :</p>
                        <ul>
                            <li>la désignation d’une SGI chargée de l’exécution de l’opération,</li>
                            <li>l’établissement d’une note d’information,</li>
                            <li>la présentation d’un dossier comprenant des documents et renseignements concernant l
                                ‘émetteur et l’opération.
                            </li>
                        </ul>
                    </div>
                </p-accordionTab>
                <p-accordionTab
                        header="Quels sont les frais liés à une levée de fonds sur le marché financier de l’UMOA ?">
                    <div class="single-content text-justify">
                        <p>
                            L’émetteur supportera trois types de frais dans le cadre d’une opération financière réalisée
                            sur le marché financier.
                        </p>
                        <h3 class="mt-20">Pour le Conseil Régional :</h3>
                        <p>
                            3 ‰ pour les émissions comprises entre 1 et 10 milliards de FCFA,
                            <br><br>
                            2,5 ‰ pour les émissions comprises entre 10 et 20 milliards de FCFA,
                            <br><br>
                            2 ‰ pour les émissions supérieures à 20 milliards de FCFA.
                            <br><br>
                            Pour les émissions inférieures ou égales à 1 milliard de FCFA, le prélèvement est de 1 ‰ du
                            montant de l’émission.
                            <br><br>
                            Les documents publicitaires produits dans le cadre de la réalisation des opérations d’appel
                            public à l’épargne, sont soumis au visa du Conseil Régional et facturés à 100 000 FCFA par
                            document.
                        </p>

                        <h3 class="mt-20">Pour les SGI :</h3>
                        <p>
                            les frais concernent les commissions de chef de file et de syndicat de placement
                            <br><br>
                            Les SGI fixent librement leurs tarifs qui doivent préalablement à leur application, être
                            homologués par le Conseil Régional. Les commissions prélevées dans le cadre d’une levée de
                            fonds sont assises sur le montant mobilisé. Les taux varie d’une SGI à une autre. Les frais
                            dus au syndicat de placement résultent généralement d’une convention entre l’émetteur et ce
                            syndicat.
                        </p>
                        <h3 class="mt-20">Pour les garants :</h3>
                        <p>
                            Les emprunts obligataires émis sur le marché financier régional, notamment ceux réalisés par
                            appel public à l’épargne doivent être garantis à 100 %. Cette garantie donne lieu à des
                            frais à la charge de l’émetteur.
                        </p>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
