<!-- Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{title | translate}}</h3>
            <ul>
                <li><a routerLink="/">{{'MenuBar.accueil' | translate}}</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li><a>{{title | translate}}</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-45">
    <div class="container">
        <div class="single-content text-justify">
            <h6>{{headerTitle | translate}}</h6>
        </div>
        <div class="mb-20 mt-20 card container">
            <form [formGroup]="editForm!" (ngSubmit)="saveEntity()">
                <div class="mt-20 mb-20">
                    <div class="field">
                        <label class="text-required mb-2">{{'Shortcuts.procedure.nom' | translate}}</label>
                        <input type="text" pInputText id="field_nom"
                               formControlName="nom" [style]="{width: '100%'}"/>
                        <app-validate-input [field]="editForm.get('nom')"></app-validate-input>
                    </div>
                    &nbsp;
                    <div class="field">
                        <label class="text-required mb-2">{{'Shortcuts.procedure.tel' | translate}}</label>
                        <input type="text" pInputText id="field_prenom"
                               formControlName="telephone" [style]="{width: '100%'}"/>
                        <app-validate-input [field]="editForm.get('telephone')"></app-validate-input>
                    </div>
                    &nbsp;
                    <div class="field">
                        <label class="text-required mb-2">{{'Shortcuts.procedure.email' | translate}}</label>
                        <input type="email" pInputText id="field_email"
                               formControlName="email" [style]="{width: '100%'}"/>
                        <app-validate-input [field]="editForm.get('email')"></app-validate-input>
                    </div>
                    <ng-container *ngIf="typeProcedure === 'demande'">
                        &nbsp;
                        <div class="field">
                            <label class="text-required mb-2">{{'Shortcuts.procedure.domaine' | translate}}</label>
                            <p-dropdown id="field" [options]="dropdownData"
                                        [filter]="true" [showClear]="true"
                                        [autoDisplayFirst]="false"
                                        formControlName="domaine"
                                        [style]="{width: '100%'}" appendTo="body">
                            </p-dropdown>
                            <app-validate-input [field]="editForm.get('domaine')"></app-validate-input>
                        </div>
                    </ng-container>
                    &nbsp;
                    <div class="field">
                        <label class="text-required mb-2">{{'Shortcuts.procedure.objet' | translate}}</label>
                        <input type="text" pInputText id="field_objet"
                               formControlName="objet" [style]="{width: '100%'}"/>
                        <app-validate-input [field]="editForm.get('objet')"></app-validate-input>
                    </div>

                    &nbsp;
                    <div class="field">
                        <label class="text-required mb-2">{{'Shortcuts.procedure.message' | translate}}</label>
                        <textarea class="p-2" id="texte" rows="8"
                                  formControlName="message"  [style]="{width: '100%'}">
                        </textarea>
                        <app-validate-input [field]="editForm.get('message')"></app-validate-input>
                    </div>
                </div>

                <p-footer>
                    <div class="row mb-20">
                        <div class="col-lg-9 col-md-12">
                            <p-captcha siteKey="6LfBB2smAAAAAAZpGRGO6vIYjXMEBBDCsXEgxhND"
                                       (onResponse)="showResponse($event)" initCallback="onloadCallback"
                                       [language]="'MenuBar.langue' | translate"></p-captcha>
                        </div>
                        <div class="col-lg-3 col-md-12" style="text-align: right">
                            <button type="submit" pButton pRipple label="{{'Shortcuts.procedure.btn_envoyer' | translate}}" icon="pi pi-send"
                                    [disabled]="editForm?.invalid || isDisabled" id="btn-send">
                            </button>
                        </div>
                    </div>
                </p-footer>
            </form>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
