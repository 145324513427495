<!-- Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{title | translate}}</h3>
            <ul>
                <li><a routerLink="/">{{'MenuBar.accueil' | translate}}</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li><a>{{title | translate}}</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-45">
    <div class="container">
        <div class="single-content text-justify">
            <h3>{{title | translate}}</h3>
        </div>
        <div class="pb-70 mt-20">
            <app-jp-table [dataList]="actualities"
                          [cols]="cols" [rows]="size"
                          [totalRecords]="totalRecords"
                          [indexName]="indexName"
                          (paginate)="pageChange($event)">
            </app-jp-table>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->

