import { createAction, props } from '@ngrx/store';
import {
    ActeurCriteria,
    ActeurFormsCreteria,
    ActualiteCriteria,
    AppelOffreCriteria,
    CacCreteria,
    DetCarteCriteria,
    EmetteurCriteria,
    FcpCreteria,
    OperationCriteria
} from '../services/request-params';
import {
    Acteur,
    ActeurFormulaire,
    Actualite,
    ActualiteByCategorie,
    AppelOffre,
    Cac,
    DepotDemande,
    DetenteurCarte,
    Emetteur,
    EmpruntObligataire,
    EpargneCollective,
    Fcp,
    FormeJuridique,
    IndiceBoursier,
    NatureOperation,
    NombreActeur,
    Operation,
    OperationBoursiere,
    OperationTitre,
    Pays,
    SecteurActivite,
    SocieteCote,
    TypeActeur,
    TypeFcp,
    TypeInstruction
} from '../entity/entity.model';
import { Status } from '../../components/common/utils/utils';
import { EntityResultSetList } from '../app.state';

export const SetStatus = createAction('[Status] Set Status',
    props<Status>());

export const FetchActualityList = createAction('[Actuality Component] Fetch Actualities list',
    props<ActualiteCriteria>());

export const SetActualityList = createAction('[ActualityListComponent Component] Set ActualityList',
    props<EntityResultSetList<Actualite>>());

export const FetchActualityAlaUneList = createAction('[Actuality A la Une Component] Fetch Actualities list',
    props<ActualiteCriteria>());

export const SetActualityAlaUneList = createAction('[ActualityListComponent  A la Une Component] Set ActualityList',
    props<{ actualiteAlaUneResultSet: Array<Actualite> }>());

export const FetchAppelOffreList = createAction('[AppelOffre Component] Fetch Actualities list',
    props<AppelOffreCriteria>());

export const SetAppelOffreList = createAction('[AppelOffreListComponent Component] Set AppelOffreList',
    props<EntityResultSetList<AppelOffre>>());

export const CreateProcedure = createAction('[Procedure Component] Create Procedure',
    props<DepotDemande>());

export const FetchActeurList = createAction('[Acteur Component] Fetch acteurs list',
    props<ActeurCriteria>());

export const SetActeurList = createAction('[ActeurListComponent Component] Set ActeurList',
    props<EntityResultSetList<Acteur>>());

export const FetchDetenteurCarteList = createAction('[DetenteurCarte Component] Fetch acteurs list',
    props<DetCarteCriteria>());

export const SetDetenteurCarteList = createAction('[DetenteurCarteListComponent Component] Set DetenteurCarteList',
    props<{ detenteurResultSet: Array<DetenteurCarte> }>());

export const FetchFormeJuridiqueList = createAction('[FormeJuridique Component] Fetch formeJuridique list');

export const SetFormeJuridiqueList = createAction('[FormeJuridiqueListComponent Component] Set FormeJuridiqueList',
    props<{ formJuridiqueResultSet: Array<FormeJuridique> }>());

export const FetchSecteurActiviteList = createAction('[SecteurActivite Component] Fetch secteurActivite list');

export const SetSecteurActiviteList = createAction('[SecteurActiviteListComponent Component] Set SecteurActiviteList',
    props<{ sectActiviteResultSet: Array<SecteurActivite> }>());

export const FetchEmetteurList = createAction('[Emetteur Component] Fetch emetteur list',
    props<EmetteurCriteria>());

export const SetEmetteurList = createAction('[EmetteurListComponent Component] Set EmetteurList',
    props<EntityResultSetList<Emetteur>>());

export const FetchNatureOperationList = createAction('[NatureOperation Component] Fetch natureOperation list');

export const SetNatureOperationList = createAction('[NatureOperationListComponent Component] Set NatureOperationList',
    props<{ natureOperationResultSet: Array<NatureOperation> }>());

export const FetchOperationList = createAction('[Operation Component] Fetch operation list',
    props<OperationCriteria>());

export const SetOperationList = createAction('[OperationListComponent Component] Set OperationList',
    props<EntityResultSetList<Operation>>());

export const FetchTypeActeurList = createAction('[TypeActeur Component] Fetch typeActeur list');

export const SetTypeActeurList = createAction('[TypeActeurListComponent Component] Set TypeActeurList',
    props<{ typeActeurResultSet: Array<TypeActeur> }>());

export const FetchPaysList = createAction('[Pays Component] Fetch Pays list');

export const SetPaysList = createAction('[PaysListComponent Component] Set PaysList',
    props<{ paysResultSet: Array<Pays> }>());

export const FetchTypeFcpList = createAction('[TypeFcp Component] Fetch TypeFcp list');

export const SetTypeFcpList = createAction('[TypeFcpListComponent Component] Set TypeFcpList',
    props<{ typeFcpResultSet: Array<TypeFcp> }>());

export const FetchFcpList = createAction('[Fcp Component] Fetch Fcp list',
    props<FcpCreteria>());

export const SetFcpList = createAction('[FcpListComponent Component] Set FcpList',
    props<EntityResultSetList<Fcp>>());

export const FetchCacList = createAction('[Cac Component] Fetch Cac list',
    props<CacCreteria>());

export const SetCacList = createAction('[CacListComponent Component] Set CacList',
    props<EntityResultSetList<Cac>>());

export const FetchTypeInstructionList = createAction('[TypeInstruction Component] Fetch TypeInstruction list');

export const SetTypeInstructionList = createAction('[TypeInstructionListComponent Component] Set TypeInstructionList',
    props<{ typeInstructionResultSet: Array<TypeInstruction> }>());

export const SendFluxRss = createAction('[SendFluxRss Component] Send email',
    props<{ email: string }>());

export const FetchActualiteByCategorieList = createAction('[ActualiteByCategorie Component] Fetch ActualiteByCategorie list');

export const SetActualiteByCategorieList = createAction('[ActualiteByCategorieListComponent Component] Set ActualiteByCategorieList',
    props<{ typeActualiteByCategResultSet: Array<ActualiteByCategorie> }>());


export const FetchEmpruntObligataireList = createAction('[EmpruntObligataire Component] Fetch EmpruntObligataire list',
    props<{ size: number, page: number }>());

export const SetEmpruntObligataireList = createAction('[EmpruntObligataireListComponent Component] Set EmpruntObligataireList',
    props<EntityResultSetList<EmpruntObligataire>>());

export const FetchEpargneCollectiveList = createAction('[EpargneCollective Component] Fetch EpargneCollective list',
    props<{ size: number, page: number }>());

export const SetEpargneCollectiveList = createAction('[EpargneCollectiveListComponent Component] Set EpargneCollectiveList',
    props<EntityResultSetList<EpargneCollective>>());

export const FetchIndiceBoursierList = createAction('[IndiceBoursier Component] Fetch IndiceBoursier list',
    props<{ size: number, page: number }>());

export const SetIndiceBoursierList = createAction('[IndiceBoursierListComponent Component] Set IndiceBoursierList',
    props<EntityResultSetList<IndiceBoursier>>());

export const FetchNombreActeurList = createAction('[NombreActeur Component] Fetch NombreActeur list',
    props<{ size: number, page: number }>());

export const SetNombreActeurList = createAction('[NombreActeurListComponent Component] Set NombreActeurList',
    props<EntityResultSetList<NombreActeur>>());

export const FetchOperationTitreList = createAction('[OperationTitre Component] Fetch OperationTitre list',
    props<{ size: number, page: number }>());

export const SetOperationTitreList = createAction('[OperationTitreListComponent Component] Set OperationTitreList',
    props<EntityResultSetList<OperationTitre>>());

export const FetchSocieteCoteList = createAction('[SocieteCote Component] Fetch SocieteCote list',
    props<{ size: number, page: number }>());

export const SetSocieteCoteList = createAction('[SocieteCoteListComponent Component] Set SocieteCoteList',
    props<EntityResultSetList<SocieteCote>>());

export const FetchOperationBoursiereList = createAction('[OperationBoursiere Component] Fetch OperationBoursiere list',
    props<{ size: number, page: number }>());

export const SetOperationBoursiereList = createAction('[OperationBoursiereListComponent Component] Set OperationBoursiereList',
    props<EntityResultSetList<OperationBoursiere>>());


export const FetchActeurFormulaireList = createAction('[ActeurFormulaire Component] Fetch ActeurFormulaire list',
    props<ActeurFormsCreteria>());

export const SetActeurFormulaireList = createAction('[ActeurFormulaireListComponent Component] Set ActeurFormulaireList',
    props<EntityResultSetList<ActeurFormulaire>>());

export const CreateActeurFormulaire = createAction('[ActeurFormulaire Component] Create ActeurFormulaire',
    props<{ entity: ActeurFormulaire, criteria: ActeurFormsCreteria }>());
