import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Pagination } from '../../utils/utils';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
    @Input() totalRecords: number;
    @Input() rows: number;
    @Output() paginate = new EventEmitter<Pagination>();

    pageChanged($event: any) {
        const pagination: Pagination = {
            first: $event.first,
            rows: $event.rows,
            page: $event.page,
            pageCount: $event.pageCount
        };
        this.paginate.emit(pagination);
    }
}
