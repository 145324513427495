import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { ActualitesComponent } from './components/pages/actualites/actualites.component';
import { ActualityDetailsComponent } from './components/pages/actuality-details/actuality-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ActualityComponent } from './components/common/templates/actuality/actuality.component';
import { PratiquesComponent } from './components/common/templates/pratiques/pratiques.component';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { HistoriqueComponent } from './components/pages/amf-umoa/historique/historique.component';
import { OrganisationComponent } from './components/pages/amf-umoa/organisation/organisation.component';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissionComponent } from './components/pages/amf-umoa/mission/mission.component';
import { RelationInstitutComponent } from './components/pages/amf-umoa/relation-institut/relation-institut.component';
import {
    RegleOrganisationComponent
} from './components/pages/reglementation/regle-organisation/regle-organisation.component';
import {
    RegleConventionComponent
} from './components/pages/reglementation/regle-convention/regle-convention.component';
import { RegleGeneraleComponent } from './components/pages/reglementation/regle-generale/regle-generale.component';
import { RegleAutreComponent } from './components/pages/reglementation/regle-autre/regle-autre.component';
import { ButtonModule } from 'primeng/button';
import { JpTableComponent } from './components/common/templates/jp-table/jp-table.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { InstructionComponent } from './components/pages/reglementation/regle-instruction/instruction.component';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TableTdComponent } from './components/common/templates/table-td/table-td.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RegleDecisionComponent } from './components/pages/reglementation/regle-decision/regle-decision.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
    RegleCirculaireComponent
} from './components/pages/reglementation/regle-circulaire/regle-circulaire.component';
import { TableDataComponent } from './components/common/templates/table-data/table-data.component';
import {
    PubRapportAnnuelComponent
} from './components/pages/publication/pub-rapport-annuel/pub-rapport-annuel.component';
import { TableStatsComponent } from './components/common/templates/table-stats/table-stats.component';
import {
    StatsMarchePrimaireComponent
} from './components/pages/publication/stats-marche-primaire/stats-marche-primaire.component';
import { StatsPanelComponent } from './components/common/templates/stats-panel/stats-panel.component';
import { PanelModule } from 'primeng/panel';
import {
    StatsNombreActeurComponent
} from './components/pages/publication/stats-nombre-acteur/stats-nombre-acteur.component';
import {
    StatsMarcheSecondaireComponent
} from './components/pages/publication/stats-marche-secondaire/stats-marche-secondaire.component';
import { ChartStatsComponent } from './components/common/templates/chart-stats/chart-stats.component';
import { ChartModule } from 'primeng/chart';
import { NgChartsModule } from 'ng2-charts';
import { NavbarItemComponent } from './components/common/navbar-item/navbar-item.component';
import { AppelOffreComponent } from './components/pages/appel-offre/appel-offre.component';
import { OffreTableComponent } from './components/common/templates/offre-table/offre-table.component';
import { ProceduresComponent } from './components/pages/procedures/procedures.component';
import { ValidateInputComponent } from './components/common/templates/validate-input/validate-input.component';
import { SearchFormComponent } from './components/common/templates/search-form/search-form.component';
import { VerificationsComponent } from './components/pages/verifications/verifications.component';
import { VerifDetailsComponent } from './components/common/templates/verif-details/verif-details.component';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { EpargnantsComponent } from './components/pages/epargnants/epargnants.component';
import { EpargnantGuideComponent } from './components/pages/epargnants/epargnant-guide/epargnant-guide.component';
import { AccordionModule } from 'primeng/accordion';
import {
    EpargnantGlossaireComponent
} from './components/pages/epargnants/epargnant-glossaire/epargnant-glossaire.component';
import { EmetteursComponent } from './components/pages/emetteurs/emetteurs.component';
import { EmetteurListComponent } from './components/pages/emetteurs/emetteur-list/emetteur-list.component';
import {
    EmetteurOperationComponent
} from './components/pages/emetteurs/emetteur-operation/emetteur-operation.component';
import { EmetteurDocComponent } from './components/pages/emetteurs/emetteur-doc/emetteur-doc.component';
import { EmetteurGuideComponent } from './components/pages/emetteurs/emetteur-guide/emetteur-guide.component';
import { IntervenantsComponent } from './components/pages/intervenants/intervenants.component';
import { PresentationComponent } from './components/pages/intervenants/presentation/presentation.component';
import {
    IntervenantAgreesComponent
} from './components/pages/intervenants/intervenant-agrees/intervenant-agrees.component';
import { IntervenantOpcComponent } from './components/pages/intervenants/intervenant-opc/intervenant-opc.component';
import { ActiviteSoumiseComponent } from './components/pages/intervenants/activite-soumise/activite-soumise.component';
import {
    IntervenantGuideComponent
} from './components/pages/intervenants/intervenant-guide/intervenant-guide.component';
import {
    IntervenantAutreComponent
} from './components/pages/intervenants/intervenant-autre/intervenant-autre.component';
import { DataTableComponent } from './components/pages/intervenants/data-table/data-table.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './store/reducers';
import { MessageService } from 'primeng/api';
import { JupiterPortailEffects } from './store/effects/jupiter-portail.effects';
import { ToastModule } from 'primeng/toast';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ActuaSocialLinkComponent } from './components/common/templates/actua-social-link/actua-social-link.component';
import {
    SideBarCategoriesComponent
} from './components/common/templates/side-bar-categories/side-bar-categories.component';
import { CaptchaModule } from 'primeng/captcha';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { PaginationComponent } from './components/common/templates/pagination/pagination.component';
import { PaginatorModule } from 'primeng/paginator';
import { YouTubePlayerModule } from '@angular/youtube-player';
import {
    EpargnantPratiquesComponent
} from './components/pages/epargnants/epargnant-pratiques/epargnant-pratiques.component';
import { TooltipModule } from 'primeng/tooltip';
import { MotPresidentComponent } from './components/pages/mot-president/mot-president.component';
import { ActeurFormsComponent } from './components/common/templates/acteur-forms/acteur-forms.component';
import { FileUploadModule } from 'primeng/fileupload';
import { CarouselModule } from 'primeng/carousel';
import { ValeursComponent } from './components/pages/amf-umoa/valeurs/valeurs.component';
import {
    RegleTextAbrogeComponent
} from './components/pages/reglementation/regle-text-abroge/regle-text-abroge.component';
import { PubEtudeAnalyseComponent } from './components/pages/publication/pub-etude-analyse/pub-etude-analyse.component';
import { PubCommuniqueComponent } from './components/pages/publication/pub-communique/pub-communique.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {ProgressSpinnerModule} from "primeng/progressspinner";

registerLocaleData(localeFr);

export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        {prefix: './assets/i18n/', suffix: '/menubar.json'},
        {prefix: './assets/i18n/', suffix: '/shortcuts.json'},
        {prefix: './assets/i18n/', suffix: '/acceuil.json'}
    ]);
}

const maskConfig: Partial<IConfig> = {
    validation: false
};

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        PreloaderComponent,
        FooterComponent,
        NavbarComponent,
        ActualitesComponent,
        ActualityDetailsComponent,
        ErrorComponent,
        ContactComponent,
        ActualityComponent,
        PratiquesComponent,
        SidebarComponent,
        HistoriqueComponent,
        OrganisationComponent,
        MissionComponent,
        RelationInstitutComponent,
        RegleOrganisationComponent,
        RegleConventionComponent,
        RegleGeneraleComponent,
        RegleAutreComponent,
        JpTableComponent,
        InstructionComponent,
        TableTdComponent,
        RegleDecisionComponent,
        RegleCirculaireComponent,
        TableDataComponent,
        PubRapportAnnuelComponent,
        TableStatsComponent,
        StatsMarchePrimaireComponent,
        StatsPanelComponent,
        StatsNombreActeurComponent,
        StatsMarcheSecondaireComponent,
        ChartStatsComponent,
        NavbarItemComponent,
        AppelOffreComponent,
        OffreTableComponent,
        ProceduresComponent,
        ValidateInputComponent,
        SearchFormComponent,
        VerificationsComponent,
        VerifDetailsComponent,
        EpargnantsComponent,
        EpargnantGuideComponent,
        EpargnantGlossaireComponent,
        EmetteursComponent,
        EmetteurListComponent,
        EmetteurOperationComponent,
        EmetteurDocComponent,
        EmetteurGuideComponent,
        IntervenantsComponent,
        PresentationComponent,
        IntervenantAgreesComponent,
        IntervenantOpcComponent,
        ActiviteSoumiseComponent,
        IntervenantGuideComponent,
        IntervenantAutreComponent,
        DataTableComponent,
        ActuaSocialLinkComponent,
        SideBarCategoriesComponent,
        PaginationComponent,
        EpargnantPratiquesComponent,
        MotPresidentComponent,
        ActeurFormsComponent,
        ValeursComponent,
        RegleTextAbrogeComponent,
        PubEtudeAnalyseComponent,
        PubCommuniqueComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        OrganizationChartModule,
        ButtonModule,
        HttpClientModule,
        DropdownModule,
        RippleModule,
        InputTextModule,
        TableModule,
        CheckboxModule,
        FormsModule,
        OverlayPanelModule,
        PanelModule,
        ChartModule,
        NgChartsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'fr',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        DialogModule,
        TabViewModule,
        AccordionModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true
            }
        }),
        EffectsModule.forRoot([JupiterPortailEffects]),
        ToastModule,
        NgxMaskModule.forRoot(maskConfig),
        CaptchaModule,
        PaginatorModule,
        YouTubePlayerModule,
        TooltipModule,
        FileUploadModule,
        CarouselModule,
        ProgressSpinnerModule
    ],
    providers: [MessageService, {provide: LOCALE_ID, useValue: 'fr-FR'}],
    bootstrap: [AppComponent]
})
export class AppModule {
}
