<p-table [value]="dataList" [columns]="cols" responsiveLayout="scroll"
         [paginator]="false" [rowHover]="true" dataKey="id">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
                <th [style.width]="col.width" [pSortableColumn]="col.field"
                    *ngIf="col.sort">
                    <span>{{col.header}}</span>
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th [style.width]="col.width" *ngIf="!col.sort">
                    <span>{{col.header}}</span>
                </th>
            </ng-container>
            <th [style.width]="'5%'" *ngIf="addDetails"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-entity let-columns="columns">
        <tr>
            <ng-container *ngFor="let col of columns">
                <td [style.width]="col.width">
                    <span class="p-column-title">{{col.header}}</span>
                    <app-table-td [col]="col" [entity]="entity" [indexName]="indexName"></app-table-td>
                </td>
            </ng-container>
            <td [style.width]="'5%'" *ngIf="addDetails">
                <div class="flex justify-content-center">
                    <button pButton pRipple icon="pi pi-eye" title="Détails"
                            class="p-button-rounded btn-style"
                            (click)="displayDialog(entity)">
                    </button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
<app-pagination [rows]="rows" [totalRecords]="totalRecords" (paginate)="pageChanged($event)"></app-pagination>
<p-dialog #dd [(visible)]="display" [style]="{width: '45vw'}"
          header="Détails Intervenant" [modal]="true" [closable]="false">
    <form>
        <app-verif-details title="Détails Intervenant"
                           [tab0Cols]="detCols"
                           [defaultPagination]="true"
                           [searchedEntity]="selectedEntity">
        </app-verif-details>
        <p-footer>
            <div class="mt-20 pb-5">
                <div style="float:right;">
                    <button type="button" pButton icon="pi pi-times" class="btn-style" pRipple
                            label="Quitter" (click)="displayDialog()" id="btn-leave">
                    </button>
                </div>
            </div>
        </p-footer>
    </form>
</p-dialog>
