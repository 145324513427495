<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'MenuBar.Actuality.title' | translate}}</h3>
            <ul>
                <li><a routerLink="/">{{'MenuBar.accueil' | translate}}</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li>{{'MenuBar.Actuality.title' | translate}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Blog Area Two -->
<div class="blog-area-two pt-45">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-12" *ngFor="let actualite of actualityList">
                        <div class="blog-card">
                            <a routerLink="/actuality-details" [queryParams]="{actualiteId: actualite.id}">
                                <img [src]="jupiterPortailService.buildUrl('photoActualiteHomePage', elasticIndexName.actualite, actualite.id, 'photoActualiteHomePage')"
                                     alt="">
                            </a>
                            <div class="content">
                                <ul class="list-tag">
                                    <li *ngFor="let tag of actualite.tags"><a class="tag">{{tag}}</a></li>
                                </ul>
                                <p>{{actualite.date | date}}</p>
                                <p>{{actualite.resume}}</p>
                                <app-actua-social-link [actualite]="actualite"></app-actua-social-link>
                                <a routerLink="/actuality-details" [queryParams]="{actualiteId: actualite.id}"
                                   class="blog-more">
                                    <i class="flaticon-double-right-arrows-angles"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <app-side-bar-categorie [destroy$]="destroy$"></app-side-bar-categorie>
            </div>
        </div>
        <app-pagination [rows]="size" [totalRecords]="totalRecords" (paginate)="pageChange($event)"></app-pagination>
    </div>
</div>
<!-- Blog Area Two End -->
