<!-- Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Instructions</h3>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li><a>Instructions</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-45">
    <div class="container">
        <div class="single-content text-justify">
            <h3>Instructions</h3>
            <p class="mb-2">
                Figurant parmi les textes d’application de l’AMF-UMOA, les Instructions de l’AMF-UMOA et les Circulaires
                qui viennent préciser les dispositions du Règlement Général.
            </p>
            <p>
                Mettant en œuvre ce pouvoir normatif, L’AMF-UMOA a adopté soixante-dix (70) Instructions dont sept (07)
                ont été abrogées. Quarante-et-une (41) Instructions sont actuellement en vigueur et précisent (i)
                les conditions d’agrément des Acteurs du Marché Financier et (ii) les modalités d’exercice de leur
                métier sur le marché financier, (iii) les conditions d’obtention du visa des opérations financières,
                (iv) les modalités de reporting des informations financières et la procédure de prise de sanctions
                par l’AMF-UMOA.
            </p>
        </div>
        <form id="contactForm">
            <div class="row">
                <div class="dropdown-select"></div>
                <div class="dropdown-select">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">Domaine Concerné</span>
                        <p-dropdown id="field" [options]="typeInstructionList" [filter]="true"
                                    [showClear]="true" [autoDisplayFirst]="false"
                                    [(ngModel)]="selectedTypeId" [style]="{width: '100%'}"
                                    appendTo="body" (onChange)="loadData()" name="typeInstruction">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
        <div class="pb-70 mt-20">
            <app-jp-table [dataList]="actualities"
                          [cols]="cols" [rows]="size"
                          [totalRecords]="totalRecords"
                          [indexName]="indexName"
                          (paginate)="pageChange($event)">
            </app-jp-table>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->
