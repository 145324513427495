import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { Subject, takeUntil } from 'rxjs';
import { CategorieActualite, ETableName } from '../../common/utils/utils';
import { Actualite } from '../../../store/entity/entity.model';
import { FetchActualityAlaUneList } from '../../../store/actions/jupiter-portail.actions';
import { selectActualityAlaUneList } from '../../../store/selectors/jupiter-portail.selectors';
import { JupiterPortailService } from '../../../store/services/jupiter-portail.service';

@Component({
    selector: 'app-home',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {
    destroy$ = new Subject<boolean>();
    actualityList: Array<Actualite>;
    elasticIndexName = ETableName;

    constructor(private store: Store<AppState>,
                public jupiterPortailService: JupiterPortailService) {
        this.store.dispatch(FetchActualityAlaUneList({
            categorie: CategorieActualite.Actualite,
            alaUne: true
        }));
    }

    ngOnInit(): void {
        this.store.pipe(select(selectActualityAlaUneList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.actualityList = resultSet.filter(item => item.alaUne);
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
