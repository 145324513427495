import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Pagination } from '../../utils/utils';

@Component({
    selector: 'app-stats-panel',
    templateUrl: './stats-panel.component.html',
    styleUrls: ['./stats-panel.component.scss']
})
export class StatsPanelComponent {
    @Input() title: string;
    @Input() data: Array<any>;
    @Input() cols: Array<any> = [];
    @Input() debutAnnee: number;
    @Input() finAnnee: number;
    @Input() chartType: string;
    @Input() chartCols: Array<any>;
    @Input() chartTitle: string;
    @Input() size: number;
    @Input() totalRecords: number;
    @Output() paginate = new EventEmitter<Pagination>();

    pageChange(pagination: Pagination) {
        this.paginate.emit(pagination);
    }
}
