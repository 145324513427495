import { Component, OnDestroy, OnInit } from '@angular/core';
import { Emetteur } from '../../../../store/entity/entity.model';
import { select, Store } from '@ngrx/store';
import {
    selectEmetteurList,
    selectFormJuridiqueList,
    selectSectActiviteList
} from '../../../../store/selectors/jupiter-portail.selectors';
import { Subject, takeUntil } from 'rxjs';
import { Pagination } from '../../../common/utils/utils';
import { AppState } from '../../../../store/app.state';
import { SelectItem } from 'primeng/api';
import {
    FetchEmetteurList,
    FetchFormeJuridiqueList,
    FetchSecteurActiviteList,
    SetEmetteurList
} from '../../../../store/actions/jupiter-portail.actions';
import { EmetteurCriteria } from '../../../../store/services/request-params';
import { JupiterPortailService } from '../../../../store/services/jupiter-portail.service';

@Component({
    selector: 'app-emetteur-list',
    templateUrl: './emetteur-list.component.html',
    styleUrls: ['./emetteur-list.component.scss']
})
export class EmetteurListComponent implements OnInit, OnDestroy {
    formJuridiqueList: Array<SelectItem>;
    secteurActiviteList: Array<SelectItem>;
    emetteurList: Array<Emetteur>;
    cols: Array<any>;
    display = false;
    selectedEmetteur: Emetteur;
    detCols: Array<any>;
    det1Cols: Array<any>;
    det2Cols: Array<any>;
    destroy$ = new Subject<boolean>();
    criteria: EmetteurCriteria = {};
    totalRecords = 0;
    page = 0;
    size = 10;

    constructor(private store: Store<AppState>,
                private jupiterPortailService: JupiterPortailService) {
        this.store.dispatch(FetchFormeJuridiqueList());
        this.store.dispatch(FetchSecteurActiviteList());

        this.cols = [
            {field: 'designation', header: 'Dénomination', type: 'string', width: '20%', sort: true},
            {field: 'secteurActiviteLib', header: 'Secteur Activité', type: 'string', width: '15%', sort: true},
            {field: 'tel', header: 'Téléphone', type: 'string', sort: true},
            {field: 'fax', header: 'Fax', type: 'string', sort: true},
            {field: 'mail', header: 'Email', type: 'string', sort: true}
        ];

        this.detCols = [
            {field: 'code', header: 'Code', type: 'string'},
            {field: 'paysLib', header: 'Pays', type: 'string'},
            {field: 'fonctionJuridiqueLib', header: 'Forme Juridique', type: 'string'},
            {field: 'secteurActiviteLib', header: 'Secteur Activité', type: 'string'},
            {field: 'designation', header: 'Emetteur', type: 'string'},
            {field: 'siegeSocial', header: 'Siège', type: 'string'},
            {field: 'rccm', header: 'RCCM', type: 'string'},
            {field: 'dateCreation', header: 'Date Création', type: 'date'},
            {field: 'dateEntreMarche', header: 'Date Entrée Marché', type: 'date'},
            {field: 'boitePostal', header: 'Boite Postale', type: 'string'},
            {field: 'tel', header: 'Téléphone', type: 'string'},
            {field: 'fax', header: 'Fax', type: 'string'},
            {field: 'mail', header: 'Email', type: 'string'},
            {field: 'siteWeb', header: 'Site Web', type: 'string'}
        ];

        this.det2Cols = [
            {field: 'typeInstrumentLib', header: 'Instrument', type: 'string', width: '20%', sort: true},
            {field: 'nomComplet', header: 'Nom Complet', type: 'string', width: '35%', sort: true},
            {field: 'code', header: 'Code/Brvm', type: 'string', width: '15%', sort: true},
            {field: 'codeIsin', header: 'Code ISIN', type: 'string', width: '15%', sort: true},
            {field: 'nomCourt', header: 'Emetteur', type: 'string', width: '15%', sort: true}
        ];

        this.det1Cols = [
            {field: 'natureLib', header: 'Nature Opération', type: 'string', width: '20%', sort: true},
            {field: 'visa', header: 'Visa', type: 'string', width: '20%', sort: true},
            {field: 'nomination', header: 'Dénomination', type: 'string', width: '30%', sort: true},
            {field: 'dateJouissance', header: 'Date Autorisation', type: 'date', width: '15%', sort: true},
            {field: 'mtSouscrit', header: 'Montant Réalisé', type: 'number', width: '15%', sort: true}
        ];

    }

    ngOnInit(): void {
        this.store.pipe(select(selectFormJuridiqueList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.formJuridiqueList = [];
                        resultSet.map(item => this.formJuridiqueList.push({label: item.lib, value: item.id}));
                    }
                }
            });

        this.store.pipe(select(selectSectActiviteList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    if (resultSet) {
                        this.secteurActiviteList = [];
                        resultSet.map(item => this.secteurActiviteList.push({label: item.lib, value: item.id}));
                    }
                }
            });

        this.store.pipe(select(selectEmetteurList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    this.emetteurList = [];
                    if (resultSet) {
                        this.emetteurList = [...resultSet.data];
                        this.totalRecords = resultSet.totalRecord;
                    } else {
                        this.searchData();
                    }
                }
            });
    }

    searchData() {
        this.store.dispatch(SetEmetteurList({data: [], totalRecord: 0}));
        if (this.criteria.formeJuridiqueId || this.criteria.secteurActiviteId) {
            this.store.dispatch(FetchEmetteurList({
                ...this.criteria,
                size: this.size,
                page: this.page
            }));
        } else {
            this.store.dispatch(FetchEmetteurList({
                size: this.size,
                page: this.page
            }));
        }
    }

    displayDialog(hide: boolean, entity?: Emetteur) {
        if (this.display || hide) {
            this.display = false;
            this.selectedEmetteur = null;
        } else {
            this.jupiterPortailService.fetchEmetteurs({id: entity.id!})
                .subscribe({
                    next: resultSet => {
                        if (resultSet.body.length > 0) {
                            this.selectedEmetteur = resultSet.body[0];
                            this.display = true;
                        }
                    }
                });
        }
    }

    pageChange(pagination: Pagination) {
        if (pagination) {
            this.page = pagination.page;
            this.size = pagination.rows;

            this.searchData();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
