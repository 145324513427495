import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-relation-institut',
    templateUrl: './relation-institut.component.html',
    styleUrls: ['./relation-institut.component.scss']
})
export class RelationInstitutComponent implements OnInit {
    data: TreeNode[];

    constructor() {
    }

    ngOnInit(): void {
    }
}
