import { Component, OnDestroy, OnInit } from '@angular/core';
import {ETypeVerification, showToast, StatusEnum} from '../../common/utils/utils';
import { ActivatedRoute } from '@angular/router';
import { ActeurCriteria, DetCarteCriteria } from '../../../store/services/request-params';
import { Subject, takeUntil } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { selectActeurList, selectDetenteurList } from '../../../store/selectors/jupiter-portail.selectors';
import {
    FetchActeurList,
    FetchDetenteurCarteList,
    SetActeurList,
    SetDetenteurCarteList
} from '../../../store/actions/jupiter-portail.actions';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-verification',
    templateUrl: './verifications.component.html',
    styleUrls: ['./verifications.component.scss']
})
export class VerificationsComponent implements OnInit, OnDestroy {
    title: string;
    type: ETypeVerification;
    subTitle: string;
    searchTitle: string;
    detCols: Array<any>;
    cols: Array<any>;
    searchedEntityList: any;
    searchedEntity: any;
    noDataTitle: string;
    requestEnded = false;
    display = false;
    acteurCriteria: ActeurCriteria = {};
    carteCriteria: DetCarteCriteria = {};
    destroy$ = new Subject<boolean>();
    searching = false;

    constructor(private activatedRoute: ActivatedRoute,
                private messageService: MessageService,
                private store: Store<AppState>) {
        this.title = this.activatedRoute.snapshot.data.title;
        this.type = this.activatedRoute.snapshot.data.type;
        this.subTitle = this.activatedRoute.snapshot.data.subTitle;
        this.searchTitle = this.activatedRoute.snapshot.data.searchTitle;
        this.noDataTitle = this.activatedRoute.snapshot.data.noDataTitle;

        if (this.type === ETypeVerification.AGREMENT) {
            this.buildAgrementCols();
        } else {
            this.buildCarteCols();
        }
    }

    ngOnInit(): void {
        this.store.dispatch(SetDetenteurCarteList({detenteurResultSet: []}));
        this.store.dispatch(SetActeurList({data: [], totalRecord: 0}));

        this.store.pipe(select(selectActeurList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    this.searchedEntityList = [];
                    if (resultSet) {
                        this.searchedEntityList = [...resultSet.data];
                        this.showNotFoundMessage();
                    }
                }
            });

        this.store.pipe(select(selectDetenteurList)).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: resultSet => {
                    this.searchedEntityList = [];
                    if (resultSet) {
                        this.searchedEntityList = [...resultSet];
                        this.showNotFoundMessage();
                    }
                }
            });
    }

    searchData() {
        if (this.type === ETypeVerification.AGREMENT) {
            this.store.dispatch(FetchActeurList(this.acteurCriteria));
            this.searching = true;
        } else {
            this.store.dispatch(FetchDetenteurCarteList(this.carteCriteria));
            this.searching = true;
        }
    }

    showNotFoundMessage() {
        if (this.searching && this.searchedEntityList.length === 0) {
            showToast(StatusEnum.warning, 'INFORMATION', 'Aucune donnée trouvée', this.messageService);
            this.searching = false;
        }
    }

    displayDialog(selectedEntity?: any) {
        if (this.display) {
            this.searchedEntity = null;
            this.display = false;
        } else {
            this.searchedEntity = selectedEntity;
            this.display = true;
        }
    }

    buildCarteCols() {
        this.detCols = [
            {field: 'ref', header: 'N°', type: 'string'},
            {field: 'nom', header: 'Nom', type: 'string'},
            {field: 'prenom', header: 'Prenom', type: 'string'},
            {field: 'fonctionReglementaireLib', header: 'Fonction', type: 'string'},
            {field: 'lieuNaissance', header: 'Lieu Naiss.', type: 'string'},
            {field: 'dateNaissance', header: 'Date Naiss.', type: 'date'}
        ];

        this.cols = [
            {field: 'ref', header: 'Code', type: 'string', width: '15%', sort: true},
            {field: 'nom', header: 'Nom', type: 'string', width: '25%', sort: true},
            {field: 'prenom', header: 'Prenom', type: 'string', width: '30%', sort: true},
            {field: 'fonctionReglementaireLib', header: 'Fonction', type: 'string', width: '25%', sort: true}
        ];
    }

    buildAgrementCols() {
        this.detCols = [
            {field: 'lib', header: 'Dénomination', type: 'string'},
            {field: 'siege', header: 'Siège social', type: 'string'},
            {field: 'rccm', header: 'RCCM', type: 'string'},
            {field: 'agrement', header: 'Agrément', type: 'string'},
            {field: 'dateAgrement', header: 'Date d\'Agrément', type: 'date'},
            {field: 'telephone', header: 'Téléphone', type: 'string'},
            {field: 'fax', header: 'Fax', type: 'string'},
            {field: 'mail', header: 'Email', type: 'string'},
            {field: 'siteWeb', header: 'Site Web', type: 'string'}
        ];

        this.cols = [
            {field: 'lib', header: 'Dénomination', type: 'string', width: '65%', sort: true},
            {field: 'agrement', header: 'Agrément', type: 'string', width: '15%', sort: true},
            {field: 'dateAgrement', header: 'Date d\'Agrément', type: 'date', width: '15%', sort: true}
        ];
    }

    get canDoSearch(): boolean {
        if (this.type === ETypeVerification.AGREMENT) {
            const agrement = this.acteurCriteria.numeroAgrement;
            const denomination = this.acteurCriteria.denomination;
            return !!((agrement && agrement.trim().length > 0) || (denomination && denomination.trim().length > 0));
        }

        const nom = this.carteCriteria.nom;
        const prenom = this.carteCriteria.prenom;
        const numero = this.carteCriteria.numero;

        return !!((nom && nom.trim().length > 0) || (prenom && prenom.trim().length > 0) || (numero && numero.trim().length > 0));
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
