import { Component, OnDestroy, OnInit } from '@angular/core';
import { ETypeProcedure, FilterItems, StatusEnum } from '../../common/utils/utils';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { CreateProcedure } from '../../../store/actions/jupiter-portail.actions';
import { DepotDemande } from '../../../store/entity/entity.model';
import { JupiterPortailService } from '../../../store/services/jupiter-portail.service';

@Component({
    selector: 'app-procedures',
    templateUrl: './procedures.component.html',
    styleUrls: ['./procedures.component.scss']
})
export class ProceduresComponent implements OnInit, OnDestroy {
    title = 'Shortcuts.procedure.plainte.title';
    headerTitle = 'Shortcuts.procedure.plainte.headerTitle';
    typeProcedure = ETypeProcedure.PLAINTE;
    dropdownData = Object.keys(FilterItems).map(key => ({label: FilterItems[key], value: FilterItems[key]}));
    destroy$ = new Subject<boolean>();
    editForm: FormGroup;
    isDisabled = true;

    constructor(private activatedRoute: ActivatedRoute,
                private fb: FormBuilder,
                private jupiterPortailService: JupiterPortailService,
                private store: Store<AppState>) {

        this.title = this.activatedRoute.snapshot.data.title;
        this.headerTitle = this.activatedRoute.snapshot.data.headerTitle;
        this.typeProcedure = this.activatedRoute.snapshot.data.type;

        this.editForm = this.fb.group({
            id: [],
            nom: ['', [Validators.required]],
            telephone: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            objet: ['', [Validators.required]],
            message: ['', [Validators.required]],
            typeDepot: [],
            domaine: ['']
        });

        if (this.typeProcedure === ETypeProcedure.REQUETE) {
            this.editForm.get('domaine').setValidators(Validators.required);
        } else {
            this.editForm.get('domaine').removeValidators(Validators.required);
        }
    }

    ngOnInit(): void {
        this.isDisabled = true;

        this.jupiterPortailService.getNewValue().pipe(takeUntil(this.destroy$))
            .subscribe(value => {
                if (value === StatusEnum.success) {
                    this.editForm.reset();
                }
            });
    }

    saveEntity() {
        const entity: DepotDemande = this.editForm.getRawValue();
        entity.typeDepot = this.typeProcedure;
        this.store.dispatch(CreateProcedure(entity));
    }

    showResponse(event) {
        //call to a backend to verify against recaptcha with private key
        if (event?.response) {
            this.isDisabled = false;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.subscribe();
    }
}
