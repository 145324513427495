import { CategorieActualite, ELangue, ETypeOffre, ETypeProcedure } from '../../components/common/utils/utils';

export class Acteur {
    id?: number;
    code?: string;
    ref?: string;
    lib?: string;
    siege?: string;
    dateCreation?: Date;
    dateEntreMarche?: Date;
    rccm?: string;
    agrement?: string;
    mail?: string;
    siteWeb?: string;
    telephone?: string;
    fax?: string;
    dateAgrement?: Date;
    decision?: string;
    numVisa?: string;
    bp?: string;
    validite?: boolean;
    isDeleted?: boolean;
    paysId?: number;
    paysCode?: string;
    paysLib?: string;
    typeId?: number;
    typeLib?: string;
    formeJuridiqueId?: number;
    formeJuridiqueLib?: string;
    decisionBytes?: ArrayBuffer;
}

export interface TypeFonction {
    id?: number;
    code?: string;
    lib?: string;
}

export interface TypeInstrument {
    id?: number;
    code?: string;
    lib?: string;
    actif?: boolean;
}

export interface TypeInstruction {
    id?: number;
    code?: string;
    lib?: string;
}

export interface TypeFcp {
    id?: number;
    code?: string;
    lib?: string;
}

export interface TypeDecision {
    id?: number;
    code?: string;
    lib?: string;
}

export interface TypeBureau {
    id?: number;
    code?: string;
    lib?: string;
    actif?: boolean;
}

export interface TypeAxe {
    id?: number;
    code?: string;
    lib?: string;
}

export interface TypeActeur {
    id?: number;
    code?: string;
    ref?: string;
    lib?: string;
    codification?: string;
    sup?: boolean;
    carteProuse?: boolean;
    validite?: boolean;
    fonctions?: Array<FonctionReglementaire>;
}

export interface TableauAmortissement {
    id?: number;
    code?: string;
    operationId?: number;
    operationLib?: string;
    dateEcheance?: Date;
    datePaiement?: Date;
    encoursDebut?: number;
    interet?: number;
    amortissement?: number;
    encoursFin?: number;
}

export interface Souscription {
    id?: number;
    operationId?: number;
    operationLib?: string;
    axeId?: number;
    axeLib?: string;
    debut?: Date;
    fin?: Date;
    nbreTitreSouscrit?: number;
    nbreTitreRetenu?: number;
    mtSouscrit?: number;
    mtRetenu?: number;
    validite?: boolean;
}

export interface SecteurActivite {
    id?: number;
    code?: string;
    lib?: string;
    validite?: boolean;
}

export interface Seance {
    id?: number;
    code?: string;
    date?: Date;
}

export interface Sanction {
    id?: number;
    code?: string;
    typeSanctionId?: number;
    typeSanctionLibelle?: string;
    societe?: string;
    lib?: string;
    date?: Date;
    lien?: string;
    actif?: boolean;
}

export interface RapportAnnuel {
    id?: number;
    code?: string;
    lib?: string;
    date?: Date;
    lien?: string;
    actif?: boolean;
}

export interface Pays {
    id?: number;
    code?: string;
    ref?: string;
    lib?: string;
    organisations?: Array<Organisation>;
}

export interface Organisation {
    id?: number;
    code?: string;
    lib?: string;
    pays?: Array<Pays>;
}

export interface Operation {
    id?: number;
    code?: string;
    emetteurId?: number;
    emetteurDesignation?: string;
    acteurId?: number;
    acteurLib?: string;
    natureId?: number;
    natureLib?: string;
    natureCode?: string;
    visa?: string;
    dateVisa?: Date;
    nomination?: string;
    nominal?: number;
    prixSous?: number;
    taux?: number;
    duree?: number;
    debut?: Date;
    fin?: Date;
    dateJouissance?: Date;
    mtDemande?: number;
    mtSouscrit?: number;
    nbTitre?: number;
    actif?: boolean;
    decision?: string;
    noteInfo?: string;
    amortAction?: string;
    axeAction?: string;
    noteInfoBytes?: ArrayBuffer;
    decisionBytes?: ArrayBuffer;
}

export interface OffresEmplois {
    id?: number;
    texte?: string;
    dateLimite?: Date;
    lien?: string;
}

export interface NatureOperation {
    id?: number;
    code?: string;
    lib?: string;
    description?: string;
}

export interface MandatCacEmetteur {
    id?: number;
    cacId?: number;
    cacLib?: string;
    emetteurId?: number;
    emetteurDesignation?: string;
    dateApprobation?: Date;
    dureeMandat?: number;
    debutMandat?: Date;
    finMandat?: Date;
    pvActe?: string;
    principal?: boolean;
    validite?: boolean;
    clore?: boolean;
    dateClosMandat?: Date;
    approuve?: boolean;
    observation?: string;
    pvActeBytes?: ArrayBuffer;
}

export interface MandatCacActeur {
    id?: number;
    cacId?: number;
    cacLib?: string;
    fcpId?: number;
    fcpLibelle?: string;
    acteurId?: number;
    acteurLib?: string;
    dateApprobation?: Date;
    dureeMandat?: number;
    debut?: Date;
    dateFin?: Date;
    pvActe?: string;
    principal?: boolean;
    validite?: boolean;
    clore?: boolean;
    dateClosMandat?: Date;
    approuve?: boolean;
    observation?: string;
}

export interface MajCartePro {
    id?: number;
    lib?: string;
    ref?: string;
    nom?: string;
    prenom?: string;
    frLib?: string;
    dcPhoto?: string;
    renew?: boolean;
    newField?: boolean;
    sup?: boolean;
}

export interface Logiciel {
    id?: number;
    code?: string;
    acteurId?: number;
    acteurLibelle?: string;
    lib?: string;
    version?: string;
    source?: string;
    typeLog?: string;
    prestataire?: string;
    reference?: string;
    debutUtilisation?: Date;
    finUtilisation?: Date;
    observation?: string;
    actif?: boolean;
}

export interface Instrument {
    id?: number;
    code?: string;
    codeBrvm?: string;
    emetteurId?: number;
    emetteurDesignation?: string;
    codeIsin?: string;
    nomCourt?: string;
    nomComplet?: string;
    dateIntroMarche?: Date;
    typeInstrumentId?: number;
    typeInstrumentLib?: string;
    nbrTitreEmis?: number;
    nbrFlotant?: number;
    actif?: boolean;
}

export interface Instruction {
    id?: number;
    code?: string;
    num?: string;
    typeInstructionId?: number;
    typeInstructionLibelle?: string;
    lib?: string;
    date?: Date;
    lien?: string;
    actif?: boolean;
}

export interface IndiceCotation {
    id?: number;
    indice?: number;
    seanceId?: number;
    seanceLibelle?: string;
    code?: string;
    valeur?: number;
}

export interface Indice {
    id?: number;
    code?: string;
    lib?: string;
    secteurActiviteId?: number;
    secteurActiviteLibelle?: string;
    actif?: boolean;
}

export interface FormeJuridique {
    id?: number;
    code?: string;
    lib?: string;
    typeForme?: string;
    validite?: boolean;
}

export interface FonctionReglementaire {
    id?: number;
    code?: string;
    ref?: string;
    lib?: string;
    importance?: number;
    observation?: string;
    sup?: boolean;
    validite?: boolean;
}

export interface FonctionDirigeant {
    id?: number;
    code?: string;
    lib?: string;
    categorie?: string;
    validite?: boolean;
}

export interface Fcp {
    id?: number;
    code?: string;
    acteurId?: number;
    acteurLib?: string;
    typeFcpId?: number;
    typeFcpLib?: string;
    lib?: string;
    agrement?: string;
    dateAgrement?: Date;
    noteInfo?: string;
    noteInfoBytes?: ArrayBuffer;
    decision?: string;
    decisionBytes?: ArrayBuffer;
    actif?: boolean;
    isDeleted?: boolean;
    prospectusUrl?: string;
    prospectusBytes?: ArrayBuffer;
}

export interface Exercice {
    id?: number;
    code?: string;
    lib?: string;
    debut?: Date;
    fin?: Date;
    enCours?: boolean;
    validite?: boolean;
}

export interface EmetteurDirigeant {
    id?: number;
    emetteurId?: number;
    emetteurDesignation?: string;
    dirigeantId?: number;
    dirigeantLib?: string;
    fonctionDirigeantId?: number;
    fonctionDirigeantLib?: string;
    debut?: Date;
    fin?: Date;
    pvNomination?: string;
    validite?: boolean;
}

export interface Emetteur {
    id?: number;
    fonctionJuridiqueId?: number;
    fonctionJuridiqueLib?: string;
    secteurActiviteId?: number;
    secteurActiviteLib?: string;
    paysId?: number;
    paysLib?: string;
    codeBrvm?: string;
    code?: string;
    designation?: string;
    siegeSocial?: string;
    dateCreation?: Date;
    dateEntreMarche?: Date;
    rccm?: string;
    boitePostal?: string;
    tel?: string;
    fax?: string;
    mail?: string;
    siteWeb?: string;
    validite?: boolean;
    dateAgrement?: Date;
    numAgrement?: string;
    isDeleted?: boolean;
}

export interface DocumentActeur {
    id?: number;
    code?: string;
    lib?: string;
    validite?: boolean;
}

export interface Dirigeant {
    id?: number;
    code?: string;
    nom?: string;
    prenom?: string;
    photo?: string;
    validite?: boolean;
}

export interface DetenteurCarte {
    id?: number;
    acteurId?: number;
    acteurLibelle?: string;
    fonctionReglementaireId?: number;
    fonctionReglementaireLibelle?: string;
    ref?: string;
    code?: string;
    dateCreation?: Date;
    nom?: string;
    prenom?: string;
    fonction?: string;
    dateNaissance?: Date;
    lieuNaissance?: string;
    photo?: ArrayBuffer;
    validite?: boolean;
    actif?: boolean;
}

export interface Decision {
    id?: number;
    code?: string;
    lib?: string;
    date?: Date;
    lien?: string;
    typeDecisionId?: number;
    typeDecisionLibelle?: string;
    actif?: boolean;
}

export interface Cotation {
    id?: number;
    instrumentId?: number;
    instrumentLibelle?: string;
    seanceId?: number;
    seanceLibelle?: string;
    code?: string;
    coursMoyen?: number;
    coursOuverture?: number;
    coursFermeture?: number;
    coursPlusHaut?: number;
    coursPlusBas?: number;
    coursMeilleurAchat?: number;
    coursMeilleurVente?: number;
    nbrTransaction?: number;
    volTransaction?: number;
    valTransigeTotal?: number;
    valTransigeNormal?: number;
}

export interface Communique {
    id?: number;
    titre?: string;
    contenu?: string;
    date?: Date;
    doc?: string;
    statut?: boolean;
}

export interface Circulaire {
    id?: number;
    code?: string;
    num?: string;
    lib?: string;
    date?: Date;
    lien?: string;
    actif?: boolean;
}

export interface CartePro {
    id?: number;
    detenteurId?: number;
    detenteurLibelle?: string;
    exerciceId?: number;
    exerciceLibelle?: string;
    dateCreation?: Date;
    statut?: boolean;
    validationDa?: boolean;
    dateValDa?: Date;
    validationCi?: boolean;
    dateValCi?: Date;
    impression?: boolean;
    dateImpression?: Date;
    retrait?: boolean;
    dateRetrait?: Date;
    validite?: boolean;
}

export interface Cac {
    id?: number;
    code?: string;
    lib?: string;
    dateApprobation?: Date;
    nationalite?: string;
    validite?: boolean;
    adresse?: string;
    catPers?: string;
    numInscriptionOrdre?: string;
    ordre?: string;
}

export interface Axe {
    id?: number;
    typeId?: number;
    typeLib?: string;
    code?: string;
    lib?: string;
    validite?: boolean;
}

export interface Actionnaire {
    id?: number;
    code?: string;
    nom?: string;
    prenom?: string;
    lib?: string;
    activite?: string;
    adresse?: string;
    tel?: string;
    validite?: boolean;
}

export interface ActionEmetteur {
    id?: number;
    emetteurId?: number;
    emetteurDesignation?: string;
    actionnaireId?: number;
    actionnaireLib?: string;
    partPourcentage?: number;
    partMnt?: number;
    dateDart?: Date;
    validite?: boolean;
}

export interface ActionActeur {
    id?: number;
    acteurId?: number;
    acteurLibelle?: string;
    actionnaireId?: number;
    actionnaireLibelle?: string;
    partPourcentage?: string;
    mntPart?: number;
    datePart?: Date;
    validite?: boolean;
}

export interface ActeurDirigeant {
    id?: number;
    acteurId?: number;
    acteurLibelle?: string;
    dirigeantId?: number;
    dirigeantLibelle?: string;
    fonctionId?: number;
    fonctionLibelle?: string;
    debut?: Date;
    fin?: Date;
    pvNomination?: string;
    validite?: boolean;
}

export interface ActeurBureau {
    id?: number;
    code?: string;
    acteurId?: number;
    acteurLibelle?: string;
    lib?: string;
    typeId?: number;
    typeLibelle?: string;
    paysId?: number;
    paysLibelle?: string;
    debut?: Date;
    fin?: Date;
    actif?: boolean;
}

export interface ActeurAdminitrateur {
    id?: number;
    acteurId?: number;
    acteurLibelle?: string;
    dirigeantId?: number;
    dirigeantLibelle?: string;
    fonctionId?: number;
    fonctionLibelle?: string;
    debut?: Date;
    fin?: Date;
    actif?: boolean;
}

export interface Agent {
    id: number;
    code?: string;
    nom: string;
    prenoms: string;
    fonction: string;
    validite?: boolean;
    idService: number;
    codeService?: string;
    libelleService?: string;
    imageToReceive?: string;
    imageToSend?: File;
    changes: any;
}

export interface Service {
    id: number;
    code?: string;
    libelle: string;
    validite?: boolean;
}

export interface UserInfos {
    code?: string;
    agent?: Agent;
    roles?: Array<string>;
}

export interface Actualite {
    id?: number;
    langue: ELangue;
    photoHomePage?: string;
    photoActualiteHomePage?: ArrayBuffer;
    photoActualite?: ArrayBuffer;
    doc?: ArrayBuffer;
    resume: string;
    titre: string;
    texte?: string;
    lienFacebook?: string;
    lienYoutube?: string;
    lienTwitter?: string;
    lienLinkedin?: string;
    dateFin?: Date;
    date?: Date;
    createdDate?: Date;
    valide?: boolean;
    alaUne?: boolean;
    categorie: CategorieActualite;
    photoGalleries?: Array<ArrayBuffer>;
    tags?: Array<string>;
}

export interface AppelOffre {
    id: number;
    type: ETypeOffre;
    dateLimite: Date;
    reference: string;
    texte: string;
    document: ArrayBuffer;
    validite?: boolean;
}

// statistiques
export interface EmpruntObligataire {
    id?: number;
    annee?: number;
    etats?: number;
    organisations?: number;
    secteurPrive?: number;
    entreprisesPubliques?: number;
    validite?: boolean;
}

export interface EpargneCollective {
    id?: number;
    annee?: number;
    fctcEtat?: number;
    fctcSecteurPrive?: number;
    validite?: boolean;
}

export interface IndiceBoursier {
    id?: number;
    annee?: number;
    indiceBrvm10?: number;
    indiceBrvmComposit?: number;
    validite?: boolean;
}

export interface NombreActeur {
    id?: number;
    annee?: number;
    central?: number;
    societeGestion?: number;
    societeGestionPatrimoine?: number;
    societeGestionOpcvm?: number;
    sicav?: number;
    fcp?: number;
    fctc?: number;
    bnqTeneurCompteConserv?: number;
    apporteurAffaires?: number;
    demarcheur?: number;
    agenceNotation?: number;
    garants?: number;
    bourse?: number;
    validite?: boolean;
}

export interface OperationBoursiere {
    id?: number;
    annee?: number;
    volumeTransaction?: number;
    valeurTransaction?: number;
    capitalisation?: number;
    marcheAction?: number;
    marcheObligatoire?: number;
    validite?: boolean;
}

export interface SocieteCote {
    id: number;
    annee: number;
    nombreSociete: number;
    nombreLigne: number;
    validite?: boolean;
}

export interface OperationTitre {
    id: number;
    annee: number;
    opv: number;
    opa: number;
    ope: number;
    pe: number;
    opr: number;
    validite?: boolean;
}

export interface EmpruntObligataire {
    id?: number;
    annee?: number;
    etats?: number;
    organisations?: number;
    regionales?: number;
    secteurPrive?: number;
    entreprisesPubliques?: number;
}

export interface EpargneCollective {
    id?: number;
    annee?: number;
    fctcEtat?: number;
    fctcSecteurPrive?: number;
}

export interface IndiceBoursier {
    id?: number;
    annee?: number;
    indiceBrvm10?: number;
    indiceBrvmComposit?: number;
}

export interface NombreActeur {
    id?: number;
    annee?: number;
    central?: number;
    societeGestion?: number;
    societeGestionPatrimoine?: number;
    societeGestionOpcvm?: number;
    sicav?: number;
    fcp?: number;
    fctc?: number;
    bnqTeneurCompteConserv?: number;
    apporteurAffaires?: number;
    demarcheur?: number;
    agenceNotation?: number;
    garants?: number;
    bourse?: number;
    fctcSecteurPrive?: number;
}

export interface OperationBoursiere {
    id?: number;
    annee?: number;
    volumeTransaction?: number;
    valeurTransaction?: number;
    capitalisation?: number;
    marcheAction?: number;
    marcheObligatoire?: number;
    opa?: number;
    ope?: number;
    pe?: number;
    opr?: number;
}

export interface OperationSurTitre {
    id?: number;
    annee?: number;
    opv?: number;
    opa?: number;
    ope?: number;
    pe?: number;
    opr?: number;
}

export interface SocieteTitreCote {
    id?: number;
    annee?: number;
    nombreSociete?: number;
    nombreLigne?: number;
}

export interface StatsDataList {
    id: string;
    cols: Array<any>;
    data: Array<any>;
    debutAnnee: number;
    finAnnee: number;
}

export interface DepotDemande {
    id?: string;
    nom?: string;
    telephone?: string;
    email?: string;
    objet?: string;
    message?: string;
    typeDepot?: ETypeProcedure;
    domaine?: string;
}

export interface ActualiteByCategorie {
    categorie: CategorieActualite;
    valeur: number;
}

export interface ActeurFormulaire {
    id?: number;
    codeSecret?: string;
    idActeur?: number;
    idEmetteur?: number;
    doc?: ArrayBuffer;
    fileName?: string;
    extension?: string;
}
