<!-- Inner Banner -->
<div class="inner-banner inner-bg7" xmlns="http://www.w3.org/1999/html">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'MenuBar.accueil' | translate}}</h3>
            <ul>
                <li><a routerLink="/">{{'MenuBar.AmfUmuo.nosValeurs' | translate}}</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li>{{'MenuBar.AmfUmuo.nosValeurs' | translate}}</li>
            </ul>
        </div>
    </div>
</div>

<!-- Inner Banner End -->
<div class="terms-conditions-area pt-45 pb-20">
    <div class="container">
        <!--<div class="single-content text-justify">
            <h3>{{'MenuBar.AmfUmuo.nosValeurs' | translate}}</h3>
        </div>-->
        <img src="assets/img/valeurs_amf.jpg" alt="Images">
    </div>
</div>
