<!-- Inner Banner -->
<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'MenuBar.Contacts.pageTitle' | translate}}</h3>
            <ul>
                <li><a routerLink="/">{{'MenuBar.accueil' | translate}}</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li>{{'MenuBar.Contacts.pageTitle' | translate}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Contact Section -->
<div class="contact-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="contact-card">
                    <i class="bx bxs-phone"></i>
                    <div class="content">
                        <h3>Tel</h3>
                        <p><a>(225) 27 20 21 57 42 / 27 20 31 56 20</a></p>
                        <span><a>(225) 27 20 33 23 04</a></span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="contact-card">
                    <i class="bx bx-map"></i>
                    <div class="content">
                        <h3>Address</h3>
                        <p>Avenue Joseph ANOMA</p>
                        <span>01 B.P. 1878 Abidjan 01/Côte d’Ivoire</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="border p-2">
            <div class="contact-card">
                <table role="table" class="d-table table table-hover">
                    <thead>
                    <tr role="row">
                        <th role="cell" [style.width]="'50%'">Objets</th>
                        <th role="cell" [style.width]="'50%'">Contacts</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr role="row">
                        <td role="cell" [style.width]="'50%'">
                            Agrément des acteurs, Contrôle et Surveillance du marché, Plaintes et enquêtes, cartes
                            professionnelles, etc...
                        </td>
                        <td role="cell" [style.width]="'50%'">
                            Direction des Acteurs :
                            <a href="mailto:da@amf-umoa.org" target="_top">da@amf-umoa.org</a>
                        </td>
                    </tr>
                    <tr role="row">
                        <td role="cell" [style.width]="'50%'">
                            Opérations financières, informations financières, commissariat aux comptes, etc ...
                        </td>
                        <td role="cell" [style.width]="'50%'">
                            Direction des Emetteurs :
                            <a href="mailto:de@amf-umoa.org" target="_top">de@amf-umoa.org</a>
                        </td>
                    </tr>
                    <tr role="row">
                        <td role="cell" [style.width]="'50%'">
                            Administration, ressources humaines, etc...
                        </td>
                        <td role="cell" [style.width]="'50%'">
                            Direction de l'Administration et la Comptabilité :
                            <a href="mailto:dac@amf-umoa.org" target="_top">dac@amf-umoa.org</a>
                        </td>
                    </tr>
                    <tr role="row">
                        <td role="cell" [style.width]="'50%'">Sites web</td>
                        <td role="cell" [style.width]="'50%'">
                            Service des Systèmes d'Information et de la Communication :
                            <a href="mailto:ssic@amf-umoa.org" target="_top">ssic@amf-umoa.org</a>
                        </td>
                    </tr>
                    <tr role="row">
                        <td role="cell" [style.width]="'50%'">Autres</td>
                        <td role="cell" [style.width]="'50%'">
                            Secrétariat Général :
                            <a href="mailto:sg@amf-umoa.org" target="_top">sg@amf-umoa.org</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<!-- Contact Section End -->

<!-- Leader Area -->
<div class="leader-area">
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-70">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Laissez-nous un message</h2>
                            <p></p>
                        </div>

                        <form id="contactForm" #msgForm="ngForm" novalidate (ngSubmit)="saveEntity()">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required
                                               placeholder="Email" [(ngModel)]="contact.email" [pattern]="emailPattern">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                               required placeholder="Objet" [(ngModel)]="contact.objet">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8"
                                                  required placeholder="Message" [(ngModel)]="contact.message">
                                        </textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn" [disabled]="msgForm.form.invalid">
                                        <i class="pi pi-send">&nbsp;</i>
                                        Envoyer
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->

<!-- Map Area -->
<div class="map-area">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7945.2512812062405!2d-4.016012!3d5.320947000000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc1ebb9aa5c736b%3A0xf55c9ba2434f1d01!2sAUTORITE%20DES%20MARCHES%20FINANCIERS%20DE%20L&#39;UNION%20MONETAIRE%20OUEST%20AFRICAINE%20(AMF-UMOA)!5e0!3m2!1sen!2sus!4v1683847478175!5m2!1sen!2sus"
            width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
<!-- Map Area End -->
